import ApiService from 'common/service/api';

export const SORT_NAME = 'name';
export const SORT_OBIS = 'profileObisCode';
export const SORT_REGISTERS = 'registers';
export const SORT_LATEST_READING = 'lastReading';

export default class MeteringProfileService extends ApiService {
  constructor(config) {
    super(config);

    this.baseURL = `${this.baseURL}/metering-profiles`;
  }

  getAll(props = {}) {
    return this.fetchCollection('', {
      ...props,
      limit: null,
      offset: null,
    });
  }
}
