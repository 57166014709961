import React from 'react';
import { connect } from 'react-redux';
import Main from 'common/mdc/layout';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ResourceWrapper } from 'common/mdc/wrappers';

import {
  selectors as groupSelectors,
} from '@sma/store/group/group';

export const messages = defineMessages({
  groupIsLoading: {
    id: 'group.group-loading',
    defaultMessage: 'Group is loading',
  },
  groupNotFound: {
    id: 'group.group-not-found',
    defaultMessage: 'Group not found',
  },
  groupNotExists: {
    id: 'group.group-not-exist',
    defaultMessage: 'Group {groupId} does not exist',
  },
});

const Group = ({
  groupContext,
  title,
  tools,
  loading,
  children,
  groupId,
}) => (
  <Main
    title={title}
    tools={tools}
  >
    <ResourceWrapper
      loading={loading}
      context={groupContext}
      messages={{
        empty: <FormattedMessage {...messages.groupNotFound} />,
        loading: <FormattedMessage {...messages.groupIsLoading} />,
        emptyReasons: [
          <FormattedMessage values={{ groupId }} {...messages.groupNotExists} />,
        ],
      }}
    >
      {children}
    </ResourceWrapper>
  </Main>
);

export default connect(
  state => ({
    groupContext: groupSelectors.getGroupContext(state),
  }),
)(Group);
