import S from 'common/service/sanctuary';
import * as R from 'ramda';
import { connect } from 'react-redux';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bool, arrayOf, shape } from 'prop-types';
import CollectionWrapper from 'common/mdc/wrappers/collection';
import {
  selectors,
} from '@sma/store/device/onDemandHistory';
import registerShape from './shape';
import OnDemandHistoryMenu from './onDemandHistory/menu';
import OnDemandHistoryTable from './onDemandHistory/table';

const messages = defineMessages({
  loading: {
    id: 'smas.devices.actions.on-demand-history.loading',
    defaultMessage: 'Loading samples',
  },
  noSamples: {
    id: 'smas.devices.actions.on-demand-history.noSamples',
    defaultMessage: 'No samples loaded',
  },
  notLoadedYet: {
    id: 'smas.devices.actions.on-demand-history.notLoadedYet',
    defaultMessage: 'Samples not loaded from meter yet',
  },
  noMeterSamples: {
    id: 'smas.devices.actions.on-demand-history.noMeterSamples',
    defaultMessage: 'Meter does not have samples for selected period',
  },
  summary: {
    id: 'smas.devices.actions.on-demand-history.summary',
    defaultMessage: '{count} {count, plural, one {sample} other {samples}} displayed',
  },
});

class HistorySamplesTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      registers: props.registers,
    };

    this.isRegisterSelected = this.isRegisterSelected.bind(this);
    this.onSelectRegister = this.onSelectRegister.bind(this);
  }

  componentWillReceiveProps(props) {
    if (this.props.registers !== props.registers) {
      this.setState({ registers: props.registers });
    }
  }

  onSelectRegister(register) {
    this.setState(state => ({
      registers: (
        R.contains(register, state.registers)
          ? R.reject(R.equals(register), state.registers)
          : R.append(register, state.registers)
      ),
    }));
  }

  isRegisterSelected(register) {
    return R.contains(register, this.state.registers);
  }

  render() {
    const {
      samplesContext,
      loading,
      registers,
    } = this.props;

    return S.fromMaybe(
      <span className="label-not-loaded-yet">
        <FormattedMessage {...messages.notLoadedYet} />
      </span>,
      S.map(
        R.always(
          <CollectionWrapper
            loading={loading}
            context={samplesContext}
            messages={{
              loading: <FormattedMessage {...messages.loading} />,
              empty: <FormattedMessage {...messages.noSamples} />,
              emptyReasons: [
                <FormattedMessage {...messages.noMeterSamples} />,
              ],
              summary: count => (
                <FormattedMessage
                  values={{ count }}
                  {...messages.summary}
                />
              ),
            }}
            tools={
              !R.isEmpty(registers) && (
                <OnDemandHistoryMenu
                  registers={registers}
                  isRegisterSelected={this.isRegisterSelected}
                  onSelectRegister={this.onSelectRegister}
                />
              )
            }
          >{samples => (
            <OnDemandHistoryTable
              registers={S.filter(this.isRegisterSelected, registers)}
              samples={samples}
            />
          )}
          </CollectionWrapper>,
        ),
        samplesContext,
      ),
    );
  }
}

HistorySamplesTable.propTypes = {
  registers: arrayOf(registerShape).isRequired,
  samplesContext: shape().isRequired,
  loading: bool.isRequired,
};

export default connect(
  state => ({
    loading: selectors.isLoading(state),
    samplesContext: selectors.getSamplesContext(state),
  }),
)(HistorySamplesTable);
