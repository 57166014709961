import React from 'react';
import Dialog from 'common/mdc/dialog';
import { arrayOf, node } from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  discardChanges: {
    id: 'group.edit.static.discard-changes',
    defaultMessage: 'You will lost all changes, continue?',
  },
  discardChangesTitle: {
    id: 'group.edit.static.cancel-title',
    defaultMessage: 'Cancel editing?',
  },
});

const CancelingDialog = ({ buttons, intl }) => (
  <Dialog
    title={intl.formatMessage(messages.discardChangesTitle)}
    buttons={buttons}
  >
    <FormattedMessage {...messages.discardChanges} />
  </Dialog>
);

CancelingDialog.propTypes = {
  buttons: arrayOf(node),
  intl: intlShape.isRequired,
};

CancelingDialog.defaultProps = {
  buttons: [],
};


export default injectIntl(CancelingDialog);
