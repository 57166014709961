import React from 'react';
import S from 'common/service/sanctuary';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Hbox } from 'common/mdc/layout';
import QuickSearch from 'common/components/search';
import Breadcrumbs from 'common/mdc/breadcrumbs';
import { intlShape, injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'found';
import { func, string } from 'prop-types';
import {
  actions as groupDevicesActions,
  selectors as groupDevicesSelectors,
} from '@sma/store/group/devices';
import Primary from '@sma/components/group/internal';
import DeviceSecondary from '@sma/components/device/secondary';
import { selectors as groupSelectors } from '@sma/store/group/group';
import Group from './group';

export const messages = defineMessages({
  group: {
    id: 'group.internal.details.group-title',
    defaultMessage: 'Groups',
  },
  search: {
    id: 'group.internal.details.search-devices',
    defaultMessage: 'Search for devices',
  },
});

const GroupPage = ({
  loading,
  search,
  query,
  intl,
  maybeGroup,
  activeDevice,
  clearSelected,
  params: { groupId },
}) => (
  <Group
    groupId={groupId}
    title={
      <Breadcrumbs>
        <Link to="/groups"><FormattedMessage {...messages.group} /></Link>
        {S.maybe(
          groupId,
          group => group.name,
          maybeGroup,
        )}
      </Breadcrumbs>}
    tools={
      <QuickSearch
        placeholder={intl.formatMessage(messages.search)}
        value={query}
        onChange={search}
      />}
    loading={loading}
  >
    {group => (
      <Hbox>
        <Primary group={group} />
        {S.maybeToNullable(S.map(
          device => <DeviceSecondary device={device} onClose={clearSelected} />,
          activeDevice,
        ))}
      </Hbox>)}
  </Group>
);

GroupPage.propTypes = {
  clearSelected: func.isRequired,
  search: func.isRequired,
  query: string,
  intl: intlShape.isRequired,
};

GroupPage.defaultProps = {
  query: '',
};

export default R.compose(
  connect(
    state => ({
      query: groupDevicesSelectors.getQuery(state),
      activeDevice: groupDevicesSelectors.getSelected(state),
      loading: groupSelectors.isLoading(state),
      maybeGroup: groupSelectors.getGroupMaybe(state),
    }),
    {
      search: ({ target: { value } }) => groupDevicesActions.search(value),
      clearSelected: groupDevicesActions.clearSelected,
    },
  ),
  injectIntl,
)(GroupPage);
