import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { resolver } from 'found';
import { IntlProvider, injectIntl, addLocaleData } from 'react-intl';
import configureStore from './store/configure';
import configureServices from './service/configure';
import createConfig from './service/config';
import Router from './router/router';

const LANG = navigator.language;

let messages = {};
try {
  messages = require('../locale/en.json');
} catch (e) {}

addLocaleData(
  require(`react-intl/locale-data/${LANG.split('-')[0] || 'en'}`),
);

(async () => {
  const smaConfig = await createConfig();
  const LocalizedProvider = injectIntl(({ intl }) => {
    const services = configureServices(intl, smaConfig);
    const store = configureStore(intl, services);

    return (
      <Provider store={store}>
        <Router
          matchContext={{
            intl,
            store,
            services,
          }}
          resolver={resolver}
        />
      </Provider>
    );
  });

  ReactDOM.render((
    <IntlProvider locale={LANG} defaultLocale={LANG} messages={messages}>
      <LocalizedProvider />
    </IntlProvider>
  ), document.body.querySelector('.react-root'));
})();
