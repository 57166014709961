import React from 'react';
import Dialog from 'common/mdc/dialog';
import { node, string } from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';

const messages = defineMessages({
  deleteChangesTitle: {
    id: 'group.edit.static.dialogs.delete-changes-title',
    defaultMessage: 'Delete device',
  },
  deletingFile: {
    id: 'files.dialogs.deletingFile',
    defaultMessage: '{deviceId} will be deleted',
  },
});

const DeletingDialog = ({ buttons, deviceId, intl }) => (
  <Dialog
    title={intl.formatMessage(messages.deleteChangesTitle)}
    buttons={buttons}
  >
    <FormattedMessage {...messages.deletingFile} values={{ deviceId }} />
  </Dialog>
);

DeletingDialog.propTypes = {
  buttons: node,
  deviceId: string,
  intl: intlShape.isRequired,
};

DeletingDialog.defaultProps = {
  buttons: undefined,
  deviceId: '',
};

export default injectIntl(DeletingDialog);
