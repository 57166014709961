import S from 'common/service/sanctuary';
import { createSelector } from 'reselect';
import {
  resource,
} from 'common/store/selectors';

const getScope = ({ group: { group } }) => group;

const {
  isLoading,
  getResourceContext,
  getResourceMaybe,
} = resource.factory(getScope);

export const getGroupId = createSelector(
  getResourceMaybe,
  S.map(S.prop('id')),
);

export {
  isLoading,
  getResourceContext as getGroupContext,
  getResourceMaybe as getGroupMaybe,
};

export const getEditError = createSelector(
  getScope,
  ({ editGroupError }) => editGroupError,
);
