import S from 'common/service/sanctuary';
import { combineEpics, ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';
import {
  LOAD,
  loadSuccess,
  loadError,
  SET_SELECTED,
} from './actions';

import {
  getDeviceId,
  getStoreParams,
} from './selectors';

import {
  actions,
} from '../meteringProfileDetails';

const loadAllEpic = (action, state, { deviceService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => deviceService
    .getMeteringProfiles(action$.deviceId, getStoreParams(state.value))
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

const loadDetailsOfSelected = (action, state) => action.pipe(
  ofType(SET_SELECTED),
  map(({ selected }) => actions.load(
    S.maybeToNullable(getDeviceId(state.value)),
    selected.id,
  )),
);

export default combineEpics(
  loadAllEpic,
  loadDetailsOfSelected,
);
