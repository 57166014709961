import React from 'react';
import { oneOf, arrayOf, bool } from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Icon from 'common/mdc/icon';
import List, { ListItem } from 'common/mdc/list';

import {
  ON_DEMAND,
  CONFIGURABLE_CAPTURE_PERIOD,
  ON_DEMAND_PROFILE,
} from '@sma/store/device/meteringProfile/features';

export const messages = defineMessages({
  onDemand: {
    id: 'meteringProfile.features.onDemand',
    defaultMessage: 'On demand sampling',
  },
  onDemandProfile: {
    id: 'meteringProfile.features.onDemandProfile',
    defaultMessage: 'On demand profile history',
  },
  capturePeriod: {
    id: 'meteringProfile.features.capturePeriod',
    defaultMessage: 'Capture period',
  },
});

const getMessage = type => ({
  [ON_DEMAND]: messages.onDemand,
  [CONFIGURABLE_CAPTURE_PERIOD]: messages.capturePeriod,
  [ON_DEMAND_PROFILE]: messages.onDemandProfile,
})[type];

const icons = {
  [ON_DEMAND]: 'insert_chart',
  [ON_DEMAND_PROFILE]: 'history',
  [CONFIGURABLE_CAPTURE_PERIOD]: 'alarm',
};

const Feature = ({ features, dense, intl }) => (
  <List dense={dense} className="metering-profile-features">
    {features && features.sort().map(feature => (
      <ListItem key={feature}>
        <Icon title={dense && getMessage(feature)
          ? intl.formatMessage(getMessage(feature))
          : null
        }
        >
          {icons[feature]}
        </Icon>
        {!dense &&
          <span className="description">
            {getMessage(feature) && (
              <FormattedMessage {...getMessage(feature)} />
            )}
          </span>}
      </ListItem>
    ))}
  </List>
);

Feature.propTypes = {
  features: arrayOf(oneOf([
    ON_DEMAND,
    ON_DEMAND_PROFILE,
    CONFIGURABLE_CAPTURE_PERIOD,
  ])).isRequired,
  dense: bool,
  intl: intlShape.isRequired,
};

Feature.defaultProps = {
  dense: false,
};

export default injectIntl(Feature);
