import ApiService from 'common/service/api';
import { PublicError } from 'common/service/error';
import { catchError, map } from 'rxjs/operators';

export default class GroupService extends ApiService {
  constructor(config) {
    super(config);

    this.baseURL = `${this.baseURL}/groups`;
  }

  getAll(params) {
    return this.fetchCollection('', params);
  }

  getById(groupId) {
    return this.fetchResource(`/${groupId}`);
  }

  getDevices(groupId, { limit, offset, sort, query }) {
    return this.fetchCollection(`/${groupId}/devices`, {
      query,
      limit,
      offset,
      sort,
    });
  }

  remove(groupId) {
    return this
      .fetchResource(`/${groupId}`, { method: 'DELETE' })
      .pipe(
        catchError((err) => {
          if (err.status === 400 && err.response) {
            throw new PublicError(err.response.message, err.response.message);
          }

          throw err;
        }),
      );
  }

  create(group) {
    return this
      .post('', group)
      .pipe(
        map(ApiService.resourceJson),
        catchError((err) => {
          if (err.status === 400 && err.response) {
            throw new PublicError(err.response.name?err.response.name:err.response.message);
          }

          throw err;
        }),
      );
  }

  save(group) {
    return this
      .put(`/${group.id}`, group)
      .pipe(
        map(ApiService.resourceJson),
        catchError((err) => {
          if (err.status === 400 && err.response) {
            throw new PublicError(err.response.message);
          }

          throw err;
        }),
      );
  }
}
