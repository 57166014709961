export const CREATE = 'GROUP/CREATE';
export const CREATE_SUCCESS = 'GROUP/CREATE_SUCCESS';
export const CREATE_ERROR = 'GROUP/CREATE_ERROR';

export const create = group => ({
  type: CREATE,
  group,
});

export const createSuccess = group => ({
  type: CREATE_SUCCESS,
  group,
});

export const createError = err => ({
  type: CREATE_ERROR,
  error: err,
});
