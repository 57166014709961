import { combineReducers } from 'redux';

import groups from './groups/reducer';
import group from './group/reducer';
import create from './create/reducer';
import devices from './devices/reducer';

export default combineReducers({
  groups,
  group,
  create,
  devices,
});
