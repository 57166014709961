import React from 'react';
import { func, bool } from 'prop-types';
import { Secondary } from 'common/mdc/layout';
import GroupDetails from './details';
import Devices from './devices';
import deviceShape from '../device/shape';
import groupShape from './groupShape';

const GroupSecondary = ({ group, devices, onClose, loading }) => (
  <Secondary
    title={group.name}
    onClose={onClose}
    loading={loading}
    className="group-secondary"
  >
    <GroupDetails group={group} />
    {devices && <Devices />}
  </Secondary>
);

GroupSecondary.propTypes = {
  onClose: func.isRequired,
  loading: bool,
  group: groupShape.isRequired,
};

GroupSecondary.defaultProps = {
  loading: false,
};

export default GroupSecondary;
