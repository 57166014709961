import { isEmpty } from 'ramda';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { string, func } from 'prop-types';
import Menu, { MenuItem } from 'common/mdc/menu';
import { selectors } from '@sma/store/device/onDemandReading';

const messages = defineMessages({
  onDemandReading: {
    id: 'meteringProfile.actions.onDemandReading',
    defaultMessage: 'On demand reading',
  },
});

const MeteringProfileActions = ({ deviceId, meteringProfile, ...props }, { onDemandReading }) => (
  selectors.isOnDemandSupported(meteringProfile) && (
    <Menu {...props}>
      <MenuItem
        key={messages.onDemandReading.id}
        onClick={() => onDemandReading({
          deviceId,
          meteringProfile,
        })}
        disabled={isEmpty(meteringProfile.registers)}
      >
        <FormattedMessage {...messages.onDemandReading} />
      </MenuItem>
    </Menu>
  )
);

MeteringProfileActions.propTypes = {
  deviceId: string.isRequired,
};

MeteringProfileActions.contextTypes = {
  onDemandReading: func.isRequired,
};


export default MeteringProfileActions;
