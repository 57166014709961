import React from 'react';
import { oneOf } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';

export const TYPE_DYNAMIC = 'DYNAMIC';
export const TYPE_STATIC = 'STATIC';
export const TYPE_STATIC_ANONYMOUS = 'STATIC_ANONYMOUS';

export const messages = defineMessages({
  [TYPE_DYNAMIC]: {
    id: 'group.type.mesh',
    defaultMessage: 'Dynamic',
  },
  [TYPE_STATIC]: {
    id: 'group.type.gateway',
    defaultMessage: 'Static',
  },
  [TYPE_STATIC_ANONYMOUS]: {
    id: 'group.type.staticAnonymous',
    defaultMessage: 'Static Anonymous',
  },
});

const GroupType = ({ type }) => (
  messages[type] ? (
    <span className="group-type">
      <FormattedMessage {...messages[type]} />
    </span>
  ) : null
);

GroupType.propTypes = {
  type: oneOf([TYPE_DYNAMIC, TYPE_STATIC, TYPE_STATIC_ANONYMOUS]).isRequired,
};

export default GroupType;
