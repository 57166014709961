import { shape, string, arrayOf, number } from 'prop-types';

export default shape({
  deviceId: string.isRequired,
  vendor: string,
  nodeId: string.isRequired,
  nodeLink: string,
  firmwareVersion: string,
  hardwareVersion: string,
  driver: string,
  addOns: arrayOf(string),
  alarms: arrayOf(shape({
    alarmType: string.isRequired,
    status: string.isRequired,
  })),
  meteringProfiles: number,
  registers: number,
});
