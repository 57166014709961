import { path } from 'ramda';
import * as search from 'common/store/search/advanced/selectors';

export const getScope = path(['device', 'search']);

const selectors = search.factory(getScope);

export const {
  isExpanded,
  hasCriteria,
  getCriteriaState,
  getCriteria,
  getCount,
  getDSL,
  hasConstraints,
} = selectors;
