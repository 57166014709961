import S from 'common/service/sanctuary';
import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, func } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Vbox } from 'common/mdc/layout';
import DateTime from 'common/components/dateTime';
import Pagination from 'common/mdc/table/pagination';
import { Link } from 'found';

import Table, {
  TableRow,
  TableCell,
  TableHead,
  TableActionCell,
} from 'common/mdc/table';

import {
  actions,
  selectors,
} from '@sma/store/group/groups';

import GroupType from './groupType';
import groupShape from './groupShape';
import GroupMenu from './menu';

export const messages = defineMessages({
  name: {
    id: 'table.header.groups.name',
    defaultMessage: 'Name',
  },
  createdAt: {
    id: 'table.header.groups.createdAt',
    defaultMessage: 'Created at',
  },
  type: {
    id: 'table.header.groups.type',
    defaultMessage: 'Type',
  },
  devices: {
    id: 'table.header.groups.devices',
    defaultMessage: 'Meters',
  },
  groupsPerPage: {
    id: 'table.groups.pagination.groupsPerPage',
    defaultMessage: 'Groups per page',
  },
  actions: {
    id: 'table.header.groups.actions',
    defaultMessage: 'Actions',
  },
});

const GroupTable = ({
  groups,
  paginator,
  isSorted,
  toggleSort,
  onPaginationChange,
  isSelected,
  select,
}) => (
  <Vbox>
    <Table>
      <TableHead>
        <TableCell
          sortable
          onClick={() => toggleSort('name')}
          sorted={isSorted('name')}
          style={{
            minWidth: '180px',
          }}
        ><FormattedMessage {...messages.name} />
        </TableCell>
        <TableCell
          sortable
          onClick={() => toggleSort('created')}
          sorted={isSorted('created')}
          alignRight
        ><FormattedMessage {...messages.createdAt} />
        </TableCell>
        <TableCell
          sortable
          onClick={() => toggleSort('type')}
          sorted={isSorted('type')}
        ><FormattedMessage {...messages.type} />
        </TableCell>
        <TableCell
          sortable
          onClick={() => toggleSort('count')}
          sorted={isSorted('count')}
          alignRight
        ><FormattedMessage {...messages.devices} />
        </TableCell>
        <TableCell
          actions
        ><FormattedMessage {...messages.actions} />
        </TableCell>
      </TableHead>
      {groups.map(group => (
        <TableRow
          key={group.id}
          selected={isSelected(group)}
          onClick={() => select(isSelected(group) ? null : group)}
        >
          <TableCell>
            <Link to={`/groups/${group.id}`}>{group.name}</Link>
          </TableCell>
          <TableCell>
            <DateTime value={group.created} />
          </TableCell>
          <TableCell><GroupType type={group.type} /></TableCell>
          <TableCell>{group.count || null}</TableCell>
          <TableActionCell>
            <GroupMenu group={group} />
          </TableActionCell>
        </TableRow>
      ))}
    </Table>
    {S.maybeToNullable(S.map(
      props => (
        <Pagination
          {...props}
          onChange={onPaginationChange}
          itemsPerPage={messages.groupsPerPage}
        />
      ),
      paginator,
    ))}
  </Vbox>
);

GroupTable.propTypes = {
  groups: arrayOf(groupShape).isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  onPaginationChange: func.isRequired,
  isSelected: func.isRequired,
  select: func.isRequired,
};

export default connect(
  state => ({
    isSorted: selectors.isSorted(state),
    paginator: selectors.getPaginator(state),
    isSelected: selectors.isSelected(state),
  }),
  {
    load: actions.loadGroups,
    toggleSort: actions.toggleSort,
    onPaginationChange: actions.paginationChange,
    select: actions.setSelected,
  },
)(GroupTable);
