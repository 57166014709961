import S from 'common/service/sanctuary';
import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import {
  collection,
} from 'common/store/selectors';

import {
  compareRegisters,
  getMeteringProfilesContext,
  getMeteringProfile as getMeteringProfileMaybe,
} from '../meteringProfile/selectors';

export const getScope = ({ device: { meteringProfileSamples } }) => meteringProfileSamples;

const collectionSelectors = collection.factory(getScope);

export const {
  isSorted,
  isLoading,
  getPaginator,
  getTotal,
  getStoreParams,
} = collectionSelectors;

export const getMeteringProfileId = createSelector(
  getScope,
  ({ meteringProfileId }) => S.toMaybe(meteringProfileId),
);

const lift2 = S.compose(S.map, S.map);

const profileHasRegisters = S.pipe([
  S.get(S.is(Array), 'registers'),
  isEmpty,
  S.not,
]);

export const getMeteringProfilesWithRegisters = createSelector(
  getMeteringProfilesContext,
  lift2(S.filter(profileHasRegisters)),
);

export const getMeteringProfile = createSelector(
  getMeteringProfileMaybe,
  getMeteringProfileId,
  (getProfile, maybeProfileId) => S.chain(getProfile, maybeProfileId),
);

export const getSamplesContext = collectionSelectors.getCollectionContext;
export const getSamplesMaybe = collectionSelectors.getCollectionMaybe;

export const getSelectedRegisters = createSelector(
  getScope,
  ({ registers }) => registers,
);

export const isRegisterSelected = createSelector(
  getSelectedRegisters,
  registers => register => !!registers.find(compareRegisters(register)),
);
