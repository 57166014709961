import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { MenuWrapper } from 'common/mdc/menu';
import Icon from 'common/mdc/icon';
import Button from 'common/mdc/button';
import DeviceMenu from './menu';
import deviceShape from './shape';

const messages = defineMessages({
  actions: {
    id: 'device.actions',
    defaultMessage: 'Actions',
  },
});

const DeviceActions = ({ device }) => (
  <MenuWrapper
    left={false}
    overlay={false}
    wrappedElement={
      <Button>
        <FormattedMessage {...messages.actions} />
        <Icon className="mdc-button__icon right">arrow_drop_down</Icon>
      </Button>}
  >
    <DeviceMenu device={device} />
  </MenuWrapper>
);

DeviceActions.propTypes = {
  device: deviceShape.isRequired,
};

export default DeviceActions;
