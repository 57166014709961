import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { string, bool } from 'prop-types';
import { Primary } from 'common/mdc/layout';
import MeteringProfileSamples from '@sma/components/samples';
import { ResourceWrapper, EmptyWrapper } from 'common/mdc/wrappers';

import {
  selectors,
} from '@sma/store/device/meteringProfileSamples';

import {
  selectors as meteringProfileSelectors,
} from '@sma/store/device/meteringProfile';

const messages = defineMessages({
  loading: {
    id: 'page.device.samples.loading',
    defaultMessage: 'Loading metering profiles',
  },
  empty: {
    id: 'page.device.samples.empty',
    defaultMessage: 'No data samples for meter {deviceId}',
  },
  noMeteringProfiles: {
    id: 'page.device.samples.noMeteringProfiles',
    defaultMessage: 'Meter has not reported any metering profiles',
  },
  noRegisters: {
    id: 'page.device.samples.noRegisters',
    defaultMessage: 'Meter\'s metering profiles contains no registers',
  },
});

const EmptyMeteringProfiles = ({ deviceId }) => (
  <EmptyWrapper
    title={<FormattedMessage values={{ deviceId }} {...messages.empty} />}
    reasons={[
      <FormattedMessage {...messages.noMeteringProfiles} />,
      <FormattedMessage {...messages.noRegisters} />,
    ]}
  />
);

EmptyMeteringProfiles.propTypes = {
  deviceId: string.isRequired,
};

const SamplesPage = ({
  loading,
  meteringProfilesContext,
  deviceId,
}) => (
  <Primary loading={loading} className="device-samples">
    <ResourceWrapper
      loading={loading}
      context={meteringProfilesContext}
      messages={{
        empty: <FormattedMessage values={{ deviceId }} {...messages.empty} />,
        emptyReasons: [
          <FormattedMessage {...messages.noMeteringProfiles} />,
          <FormattedMessage {...messages.noRegisters} />,
        ],
      }}
    >{meteringProfiles => (R.isEmpty(meteringProfiles)
        ? <EmptyMeteringProfiles deviceId={deviceId} />
        : (
          <MeteringProfileSamples
            deviceId={deviceId}
            meteringProfiles={meteringProfiles}
          />
        )
    )}
    </ResourceWrapper>
  </Primary>
);

SamplesPage.propTypes = {
  deviceId: string.isRequired,
  loading: bool.isRequired,
};

export default R.compose(
  connect(
    (state, { params: { deviceId } }) => ({
      deviceId,
      loading: selectors.isLoading(state)
        || meteringProfileSelectors.isLoading(state),
      meteringProfilesContext: selectors
        .getMeteringProfilesWithRegisters(state),
    }),
  ),
)(SamplesPage);
