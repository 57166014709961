import React from 'react';
import { isEmpty, isNil } from 'ramda';
import Grid, { GridRow, GridCell } from 'common/mdc/grid';
import { Widget } from 'common/mdc/card';
import Icon from 'common/mdc/icon';
import DetailsForm from '@sma/components/device/detailsForm';
import { defineMessages, injectIntl } from 'react-intl';
import AlarmsState from '@sma/components/device/alarmState';

const messages = defineMessages({
  alarmsWidget: {
    id: 'meter.dashboard.widget.alarms',
    defaultMessage: 'Alarms',
  },
  detailsWidget: {
    id: 'meter.dashboard.widget.details',
    defaultMessage: 'Details',
  },
  noDeviceAlarms: {
    id: 'meter.dashboard.widget.noDeviceAlarms',
    defaultMessage: 'No alarms on this meter',
  },
});

const DeviceDashboard = ({ device, intl }) => (
  <Grid>
    <GridRow>
      <GridCell>
        <Widget
          title={intl.formatMessage(messages.detailsWidget)}
        >
          <DetailsForm device={device} />
        </Widget>
      </GridCell>
      <GridCell>
        <Widget
          className="alarms-widget"
          title={intl.formatMessage(messages.alarmsWidget)}
        >
          {isNil(device.alarms) || isEmpty(device.alarms) ?
            <div className="device-alarms-pane empty-alarms">
              <Icon>check_circle</Icon>{intl.formatMessage(messages.noDeviceAlarms)}
            </div> :
            <AlarmsState alarms={device.alarms} />}
        </Widget>
      </GridCell>
    </GridRow>
  </Grid>
);

export default injectIntl(DeviceDashboard);
