import S from 'common/service/sanctuary';
import { compose } from 'ramda';
import React from 'react';
import { string, func, bool, arrayOf } from 'prop-types';
import { injectIntl, intlShape, FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import QuickSearch from 'common/components/search';
import Main from 'common/mdc/layout';
import GroupPrimary from '@sma/components/group/primary';
import Secondary from '@sma/components/group/secondary';
import deviceShape from '@sma/components/device/shape';

import {
  actions,
  selectors,
} from '@sma/store/group/groups';

import { selectors as devicesSelectors } from '@sma/store/group/devices';

import {
  selectors as groupSelectors,
} from '@sma/store/group/group';

const messages = defineMessages({
  title: {
    id: 'title.groups',
    defaultMessage: 'Groups',
  },
  search: {
    id: 'search.groups.placeholder',
    defaultMessage: 'Search for groups',
  },
});

const Groups = ({
  intl,
  query,
  search,
  selectedGroup,
  clearSelection,
  loading,
  devices,
}) => (
  <Main
    title={<FormattedMessage {...messages.title} />}
    tools={
      <QuickSearch
        placeholder={intl.formatMessage(messages.search)}
        value={query}
        onChange={search}
      />
    }
  >
    <GroupPrimary />
    {S.maybeToNullable(S.map(group => (
      <Secondary
        group={group}
        devices={devices}
        onClose={clearSelection}
        loading={loading}
      />
    ), selectedGroup))}
  </Main>
);

Groups.propTypes = {
  search: func.isRequired,
  query: string,
  intl: intlShape.isRequired,
  clearSelection: func.isRequired,
  loading: bool,
  devices: arrayOf(deviceShape),
};

Groups.defaultProps = {
  query: '',
  loading: false,
  devices: [],
};

export default compose(
  connect(
    state => ({
      query: selectors.getQuery(state),
      selectedGroup: selectors.getSelected(state),
      loading: groupSelectors.isLoading(state) || devicesSelectors.isLoading(state),
      devices: S.maybeToNullable(devicesSelectors.getDevicesMaybe(state)),
    }),
    ({
      clearSelection: () => actions.setSelected(null),
      search: evt => actions.search(evt.target.value),
    }),
  ),
  injectIntl,
)(Groups);
