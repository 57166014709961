import { combineEpics, ofType } from 'redux-observable';
import { map, catchError, switchMap, takeUntil } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';

import {
  LOAD,
  CLEAR,
  loadSuccess,
  loadError,
} from './actions';

const loadEpic = (action, state, { deviceService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => deviceService
    .getMeteringProfileById(action$.deviceId, action$.meteringProfileId)
    .pipe(
      map(loadSuccess),
      takeUntil(action.pipe(ofType(CLEAR))),
      catchError(createErrorStream(action$, loadError)),
    )),
);

export default combineEpics(
  loadEpic,
);
