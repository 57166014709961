import {
  resource,
  collection,
  selection,
} from 'common/store/reducer';

import { composeReducers } from 'common/store/util';

import {
  NAMESPACE,
  CLEAR,
} from './actions';

const initial = {
  ...collection.defaultSort('deviceId'),
};

const clear = state => ({
  ...resource.clear(state),
  offset: 0,
  limit: 10,
});

const groupDevicesReducer = (state = initial, action) => {
  const reducer = {
    [CLEAR]: clear,
  }[action.type];

  return reducer ? reducer(state, action) : state;
};

export default composeReducers(
  collection.factory(NAMESPACE),
  selection.factory(NAMESPACE),
  groupDevicesReducer,
);
