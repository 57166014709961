import React from 'react';
import { connect } from 'react-redux';
import { func, bool } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Tabs, { TabItem } from 'common/mdc/tabs';
import { Secondary } from 'common/mdc/layout';
import {
  selectors,
} from '@sma/store/device/meteringProfileDetails';
import Registers from '@sma/components/meteringProfile/registers';
import Details from './details';
import meteringProfileShape from './shape';

const TAB_DETAILS = Symbol('details');
const TAB_REGISTERS = Symbol('registers');

export const messages = defineMessages({
  details: {
    id: 'device.meteringProfileDetails.details',
    defaultMessage: 'Details',
  },
  registers: {
    id: 'device.meteringProfileDetails.registers',
    defaultMessage: 'Registers',
  },
});

export class MeteringProfileSecondary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.select = this.select.bind(this);
    this.state = {
      tab: TAB_DETAILS,
    };
  }

  select(tab) {
    this.setState({ tab });
  }

  render() {
    const {
      loading,
      onClose,
      meteringProfile,
    } = this.props;
    const { tab } = this.state;

    const getContent = selectedTab => ({
      [TAB_DETAILS]: <Details meteringProfile={meteringProfile} />,
      [TAB_REGISTERS]: <Registers registers={meteringProfile.registers} />,
    }[selectedTab]);

    return (
      <Secondary
        title={meteringProfile.name}
        onClose={onClose}
        loading={loading}
        tabsRow={
          <Tabs>
            <TabItem
              active={tab === TAB_DETAILS}
              onClick={() => this.select(TAB_DETAILS)}
            >
              <FormattedMessage {...messages.details} />
            </TabItem>
            {meteringProfile.registers &&
              <TabItem
                active={tab === TAB_REGISTERS}
                onClick={() => this.select(TAB_REGISTERS)}
              >
                <FormattedMessage {...messages.registers} />
              </TabItem>}
          </Tabs>
        }
      >
        {getContent(tab)}
      </Secondary>
    );
  }
}

MeteringProfileSecondary.propTypes = {
  onClose: func.isRequired,
  loading: bool.isRequired,
  meteringProfile: meteringProfileShape.isRequired,
};

export default connect(
  state => ({
    loading: selectors.isLoading(state),
  }),
)(MeteringProfileSecondary);
