import React from 'react';
import S from 'common/service/sanctuary';
import { without, compose } from 'ramda';
import { WithSelection } from 'common/mdc/table';
import { bool, func } from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Pagination from 'common/mdc/table/pagination';
import { CollectionWrapper } from 'common/mdc/wrappers';
import { Vbox } from 'common/mdc/layout';
import groupShape from '@sma/components/group/groupShape';
import { TYPE_STATIC } from '@sma/components/group/groupType';
import { withConfirmationDialog } from 'common/mdc/dialog';

import {
  COLUMN_ACTIONS,
  columns,
} from '@sma/components/device/table';

import {
  actions as devicesActions,
  selectors as devicesSelectors,
} from '@sma/store/device/devices';

import {
  selectors,
  actions as groupDevicesActions,
} from '@sma/store/group/devices';

import {
  selectors as groupSelectors,
  actions as groupActions,
} from '@sma/store/group/group';

import EditForm from './form';
import PrimaryActions from './actions';
import SelectionTable from './table';
import { SavingDialog } from './dialogs';
import DevicesCollection from '../devicesCollection';

const displayColumns = without([
  COLUMN_ACTIONS,
], columns);

const messages = defineMessages({
  label: {
    id: 'group.edit.label',
    defaultMessage: '{length} of {total} group {total, plural, one {device} other {devices}} displayed',
  },
  noDevices: {
    id: 'group.edit.noDevices',
    defaultMessage: 'No devices found',
  },
  devicesLoading: {
    id: 'group.edit.loading',
    defaultMessage: 'Loading devices',
  },
  noDevicesConfigured: {
    id: 'group.edit.noDevicesConfigured',
    defaultMessage: 'No devices has been configured in system yet',
  },
  noDevicesMatches: {
    id: 'group.edit.noDevicesMatches',
    defaultMessage: 'No devices due to filters applied',
  },
  displayGroupDevices: {
    id: 'group.edit.displayGroupDevices',
    defaultMessage: 'Display group devices',
  },
  selected: {
    id: 'group.edit.selected',
    defaultMessage: '{count} {count, plural, one {device} other {devices}} selected',
  },
  noSelectedDevices: {
    id: 'group.edit.noSelectedDevices',
    defaultMessage: 'Please, select at least one device',
  },
});

class GroupEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.saveStatic = this.saveStatic.bind(this);
    this.saveDynamic = this.saveDynamic.bind(this);
  }

  componentWillMount() {
    const { group, loadGroupDevices } = this.props;

    if (group.type === TYPE_STATIC) {
      loadGroupDevices(
        group.id,
        { limit: null, offset: null },
      );
    }
  }

  saveStatic() {
    const { intl, multiSelection, rejectEdit, savingDialog, save, onSave, groupDevices } = this.props;

    if (!multiSelection.hasSelection()) {
      return rejectEdit(intl.formatMessage(messages.noSelectedDevices));
    }

    const group = this.editForm
      .getWrappedInstance()
      .getUpdatedGroup();

    const devices = multiSelection.getSelection();
    const addedCount = devices.filter(device => S.maybe(false, S.complement(S.elem(device)), groupDevices)).length;
    const removedCount = S.maybe(0, devices$ => devices$.filter(device => !S.elem(device, devices)).length, groupDevices);

    return savingDialog({
      resolve: () => {
        onSave();

        save({
          ...group,
          devices: S.map(S.prop('deviceId'), devices),
        });
      },
      addedCount,
      removedCount,
    });
  }

  saveDynamic() {
    const { save, onSave } = this.props;

    const group = this.editForm
      .getWrappedInstance()
      .getUpdatedGroup();

    onSave();
    save(group);
  }

  render() {
    const {
      devicesContext,
      loading,
      isSorted,
      filterDevices,
      onSortChange,
      multiSelection,
      paginationChange,
      group,
      paginator,
      groupDevices,
      editError,
      clearError,
    } = this.props;

    const isStatic = group.type === TYPE_STATIC;
    const isDisplaySelected = multiSelection.isDisplaySelected();

    const pagination =
      !isDisplaySelected && S.maybeToNullable(S.map(
      paginatorProps => <Pagination {...paginatorProps} onChange={paginationChange} />,
      paginator,
      ));

    const filter = React.cloneElement(
      multiSelection.getFilter(),
      { label: <FormattedMessage {...messages.displayGroupDevices} /> },
    );

    const selection = multiSelection.getSelection();
    const context = isDisplaySelected
      ? S.Just(S.Right(filterDevices(selection)))
      : devicesContext;

    const count = selection.length;

    return (
      <Vbox>
        <EditForm
          error={editError}
          ref={(form) => { this.editForm = form; }}
          style={{
            flex: '1 0 auto',
          }}
          group={group}
        />
        {isStatic ? (
            <CollectionWrapper
              loading={loading}
              context={context}
              messages={{
                empty: <FormattedMessage {...messages.noDevices} />,
                loading: <FormattedMessage {...messages.devicesLoading} />,
                emptyReasons: [
                  <FormattedMessage {...messages.noDevicesConfigured} />,
                  <FormattedMessage {...messages.noDevicesMatches} />,
                ],
                summary: () => (
                  <FormattedMessage
                    {...messages.selected}
                    values={{
                      count,
                    }}
                  />
                ),
              }}
              tools={isStatic && filter}
              pagination={pagination}
            >{devices => (
              <SelectionTable
                devices={devices}
                allSelected={multiSelection.isAllSelected(devices)}
                onSelect={S.compose(
                  clearError,
                  multiSelection.handleItemSelect,
                )}
                onSelectionChange={S.compose(
                  clearError,
                  multiSelection.handleSelectAll(devices),
                )}
                selected={multiSelection.selected}
                isAdditionalItem={device => S.maybe(false, S.complement(S.elem(device)), groupDevices)}
                onSortChange={onSortChange}
                isSorted={isSorted}
              />
            )}
            </CollectionWrapper>
          )
          : <DevicesCollection displayColumns={displayColumns} />}
        <PrimaryActions
          group={group}
          onSave={isStatic ? this.saveStatic : this.saveDynamic}
        />
      </Vbox>
    );
  }
}

GroupEdit.propTypes = {
  filterDevices: func.isRequired,
  group: groupShape.isRequired,
  loading: bool.isRequired,
  isSorted: func.isRequired,
  onSortChange: func.isRequired,
  paginationChange: func.isRequired,
  clearError: func.isRequired,
  onSave: func,
};

GroupEdit.defaultProps = {
  onSave: () => {},
};

export default compose(
  connect(
    state => ({
      loading: devicesSelectors.isLoading(state),
      paginator: devicesSelectors.getPaginator(state),
      devicesContext: devicesSelectors.getDevicesContext(state),
      isSorted: devicesSelectors.isSorted(state),
      query: devicesSelectors.getQuery(state),
      groupDevices: selectors.getDevicesMaybe(state),
      filterDevices: devicesSelectors.getDevicesFilter(state),
      editError: groupSelectors.getEditError(state),
    }),
    {
      loadGroupDevices: groupDevicesActions.load,
      paginationChange: devicesActions.paginationChange,
      onSortChange: devicesActions.toggleSort,
      save: groupActions.save,
      clearError: groupActions.clearEditError,
      rejectEdit: groupActions.editError,
    },
  ),
  WithSelection(({ displaySelected, groupDevices }) => ({
    intitialDisplaySelected: displaySelected,
    initialSelection: S.maybeToNullable(groupDevices),
    mapItemToSelection: S.I,
  })),
  withConfirmationDialog(SavingDialog, 'savingDialog'),
  injectIntl,
)(GroupEdit);
