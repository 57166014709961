import React from 'react';
import { bool } from 'prop-types';
import { registerShape } from '@sma/components/meteringProfile/registers';

export const RegisterObis = ({ register }) => (
  <span className="register-obis">
    {register.obisCode}
    <span className="attribute-id">/{register.attributeId}</span>
  </span>
);

const registerTitle = register => `${register.name || ''} ${register.obisCode}/${register.attributeId} ic: ${register.ic}`;

const RegisterName = ({ register, title }) => (
  <span className="register-name" title={title ? registerTitle(register) : undefined}>
    {register.name || <RegisterObis register={register} />}
  </span>
);

RegisterName.propTypes = {
  register: registerShape.isRequired,
  title: bool,
};

RegisterName.defaultProps = {
  title: false,
};

export default RegisterName;
