import { prop, sort as sortBy, ascend, descend } from 'ramda';
import { createSelector } from 'reselect';
import S from 'common/service/sanctuary';

import {
  collection,
  selection,
} from 'common/store/selectors';
import { SORT_ASC, SORT_DESC } from 'common/store/collection/reducer';

const getScope = ({ device: { devices } }) => devices;

const collectionSelectors = collection.factory(getScope);
const selectionSelectors = selection.factory(getScope, prop('deviceId'));

export const {
  isSorted,
  getPaginator,
  getTotal,
  isLoading,
  getQuery,
  getSearch,
  getStoreParams,
  getSorter,
  localSearch,
} = collectionSelectors;

export const {
  isSelected,
  getSelected,
} = selectionSelectors;

export const getDevicesContext = collectionSelectors.getCollectionContext;
export const getDevicesMaybe = collectionSelectors.getCollectionMaybe;

export const getDevicesSorter = createSelector(
  getStoreParams,
  ({ sort }) => sortBy({
    [SORT_ASC]: ascend,
    [SORT_DESC]: descend,
  }[sort.direction](S.prop(sort.property))),
);

export const getDevicesFilter = createSelector(
  getDevicesSorter,
  localSearch,
  (sortDevices, search) => S.pipe([search, sortDevices]),
);

