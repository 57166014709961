import React from 'react';
import { Link } from 'found';
import { defineMessages, FormattedMessage } from 'react-intl';
import Main from 'common/mdc/layout';
import Breadcrumbs from 'common/mdc/breadcrumbs';
import GroupCreate from '@sma/components/group/create';

export const messages = defineMessages({
  title: {
    id: 'title.groups',
    defaultMessage: 'Groups',
  },
  create: {
    id: 'groups.title.create',
    defaultMessage: 'Create a job',
  },
  groupCreate: {
    id: 'groups.title.groupCreate',
    defaultMessage: 'Create a group',
  },
});

const GroupCreatePage = ({ location: { params = {} } }) => (
  <Main
    title={
      <Breadcrumbs>
        <Link to="/groups"><FormattedMessage {...messages.title} /></Link>
        <FormattedMessage {...messages.groupCreate} />
      </Breadcrumbs>}
  >
    <GroupCreate {...params} />
  </Main>
);

export default GroupCreatePage;
