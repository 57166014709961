export const UPDATE_ALL = 'DEVICE/METERING_PROFILE_SAMPLES/UPDATE/ALL';
export const LOAD_ALL = 'DEVICE/METERING_PROFILE_SAMPLES/LOAD/ALL';
export const CLEAR_ALL = 'DEVICE/METERING_PROFILE_SAMPLES/CLEAR/ALL';
export const LOAD_ALL_SUCCESS = 'DEVICE/METERING_PROFILE_SAMPLES/LOAD/ALL/SUCCESS';
export const LOAD_ALL_ERROR = 'DEVICE/METERING_PROFILE_SAMPLES/LOAD/ALL/ERROR';
export const TOGGLE_SORT = 'DEVICE/METERING_PROFILE_SAMPLES/TOGGLE_SORT';
export const PAGINATION_CHANGE = 'DEVICE/METERING_PROFILE_SAMPLES/PAGINATION_CHANGE';
export const SELECT_PROFILE = 'DEVICE/METERING_PROFILE_SAMPLES/SELECT_PROFILE';
export const SELECT_REGISTER = 'DEVICE/METERING_PROFILE_SAMPLES/SELECT_REGISTER';
export const SET_REGISTERS = 'DEVICE/METERING_PROFILE_SAMPLES/SET_REGISTERS';
export const DOWNLOAD_ALL = 'DEVICE/METERING_PROFILE_SAMPLES/DOWNLOAD/ALL';
export const DOWNLOAD_ALL_SUCCESS = 'DEVICE/METERING_PROFILE_SAMPLES/DOWNLOAD/ALL/SUCCESS';
export const DOWNLOAD_ALL_ERROR = 'DEVICE/METERING_PROFILE_SAMPLES/DOWNLOAD/ALL/ERROR';

//TODO: refactor action body
export const loadAll = (deviceId, meteringProfileId, { limit, offset, sort, query, from, to } = {}) => ({
  type: LOAD_ALL,
  clear: true,
  deviceId,
  meteringProfileId,
  limit,
  offset,
  sort,
  query,
  from,
  to,
});

export const clearAll = () => ({
  type: CLEAR_ALL,
});

export const toggleSort = property => ({
  type: TOGGLE_SORT,
  property,
});

export const loadAllSuccess = ({ data, total }) => ({
  type: LOAD_ALL_SUCCESS,
  items: data,
  total,
});

export const loadAllError = err => ({
  type: LOAD_ALL_ERROR,
  error: err,
});

export const downloadAll = ({ sort, query, from, to }) => ({
  type: DOWNLOAD_ALL,
  sort,
  query,
  from,
  to,
});

export const downloadAllSuccess = ({ data, total }) => ({
  type: DOWNLOAD_ALL_SUCCESS,
  blob: data,
  total,
});

export const downloadAllError = error => ({
  type: DOWNLOAD_ALL_ERROR,
  error,
});

export const paginationChange = ({ limit, offset }) => ({
  type: PAGINATION_CHANGE,
  limit,
  offset,
});

export const selectMeteringProfile = meteringProfileId => ({
  type: SELECT_PROFILE,
  meteringProfileId,
});

export const selectRegister = register => ({
  type: SELECT_REGISTER,
  register,
});

export const setRegisters = registers => ({
  type: SET_REGISTERS,
  registers,
});
