import S from 'common/service/sanctuary';
import React from 'react';
import { connect } from 'react-redux';
import { bool, number } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Actions as FarceActions } from 'farce';
import { Primary } from 'common/mdc/layout';
import Button from 'common/mdc/button';
import CollectionWrapper from 'common/mdc/wrappers/collection';
import {
  selectors,
} from '@sma/store/group/groups';

import GroupTable from './table';

const messages = defineMessages({
  groupsSummary: {
    id: 'groups.pagination.groupsSummary',
    defaultMessage: '{count, number} {count, plural, one {group} other {groups}} of {total} displayed',
  },
  createGroup: {
    id: 'groups.pagination.createGroup',
    defaultMessage: 'Create group',
  },
  noGroups: {
    id: 'groups.noGroups',
    defaultMessage: 'No groups found',
  },
  groupsLoading: {
    id: 'groups.groupsLoading',
    defaultMessage: 'Groups loading',
  },
  noGroupsConfigured: {
    id: 'groups.noGroupsConfigured',
    defaultMessage: 'No groups has been configured in system yet',
  },
  noGroupsMatches: {
    id: 'groups.noGroupsMatches',
    defaultMessage: 'No groups due to filters applied',
  },
});

const GroupPrimary = ({ push, groupsContext, loading, total }) => (
  <Primary loading={loading}>
    <CollectionWrapper
      loading={loading}
      context={groupsContext}
      tools={(
        <Button onClick={() => push('/groups/create')}>
          <FormattedMessage {...messages.createGroup} />
        </Button>
      )}
      messages={{
        loading: <FormattedMessage {...messages.groupsLoading} />,
        empty: <FormattedMessage {...messages.noGroups} />,
        emptyReasons: [
          <FormattedMessage {...messages.noGroupsConfigured} />,
          <FormattedMessage {...messages.noGroupsMatches} />,
        ],
        summary: count => (
          <FormattedMessage
            values={{ count, total }}
            {...messages.groupsSummary}
          />
        ),
      }}
    >{groups => (
      <GroupTable groups={groups} />
    )}
    </CollectionWrapper>
  </Primary>
);

GroupPrimary.propTypes = {
  loading: bool.isRequired,
  total: number,
};

GroupPrimary.defaultProps = {
  total: undefined,
};

export default connect(
  state => ({
    loading: selectors.isLoading(state),
    groupsContext: selectors.getGroupsContext(state),
    total: S.fromMaybe(0, selectors.getTotal(state)),
  }),
  {
    push: FarceActions.push,
  },
)(GroupPrimary);
