import S from 'common/service/sanctuary';
import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import {
  LOAD_CRITERIA,
  APPLY_CRITERIA,
  REMOVE_CRITERIA,
  CLEAR,
} from './actions';

import {
  getDSL,
} from './selectors';

import {
  advancedSearch,
} from '../devices/actions';

const syncCriteriaEpic = (action, state) => action.pipe(
  ofType(LOAD_CRITERIA, APPLY_CRITERIA, REMOVE_CRITERIA, CLEAR),
  map(() => advancedSearch(S.maybeToNullable(getDSL(state.value)))),
);

export default combineEpics(
  syncCriteriaEpic,
);
