import * as R from 'ramda';
import React from 'react';
import { arrayOf, func } from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
} from 'react-intl';
import {
  COLUMN_NAME,
  COLUMN_OBIS,
  COLUMN_PERIOD,
  COLUMN_REGISTERS,
  COLUMN_FEATURES,
} from '@sma/components/meteringProfile/table';

// import { Link } from 'found';
// import DateTime from 'common/components/dateTime';

import Table, {
  TableRow,
  TableCell,
  TableHead,
  TableActionCell,
} from 'common/mdc/table';

import {
  SORT_NAME,
  SORT_OBIS,
  SORT_REGISTERS,
  // SORT_LATEST_READING,
} from '@sma/service/api/meteringProfile';

import {
  selectors,
} from '@sma/store/device/onDemandReading';
import CapturePeriod from '@sma/components/meteringProfile/capturePeriod';
import Features from '@sma/components/meteringProfile/features';

import MeteringProfileMenu from './menu';
import meteringProfileShape from './shape';

export const COLUMN_ACTIONS = Symbol('actions');

export const messages = defineMessages({
  name: {
    id: 'meteringProfile.header.name',
    defaultMessage: 'Name',
  },
  obis: {
    id: 'meteringProfile.header.aliasId',
    defaultMessage: 'OBIS code',
  },
  period: {
    id: 'meteringProfile.header.period',
    defaultMessage: 'Capture period',
  },
  registers: {
    id: 'meteringProfile.header.registers',
    defaultMessage: 'Registers',
  },
  latestReading: {
    id: 'meteringProfile.header.latestReading',
    defaultMessage: 'Latest reading',
  },
  features: {
    id: 'meteringProfile.header.features',
    defaultMessage: 'Features',
  },
  actions: {
    id: 'meteringProfile.header.actions',
    defaultMessage: 'Actions',
  },
});


export const meteringProfileHead = ({
  toggleSort,
  isHidden,
  isSorted,
  ...props
}) => (
  <TableHead {...props}>
    <TableCell
      sortable
      columnId={COLUMN_NAME}
      onClick={() => toggleSort(SORT_NAME)}
      sorted={isSorted(SORT_NAME)}
      style={{
        minWidth: '180px',
      }}
      flex="2"
    ><FormattedMessage {...messages.name} />
    </TableCell>
    <TableCell
      sortable
      columnId={COLUMN_OBIS}
      hidden={isHidden(COLUMN_OBIS)}
      onClick={() => toggleSort(SORT_OBIS)}
      sorted={isSorted(SORT_OBIS)}
    ><FormattedMessage {...messages.obis} />
    </TableCell>
    <TableCell
      columnId={COLUMN_PERIOD}
      hidden={isHidden(COLUMN_PERIOD)}
    ><FormattedMessage {...messages.period} />
    </TableCell>
    <TableCell
      sortable
      columnId={COLUMN_REGISTERS}
      hidden={isHidden(COLUMN_REGISTERS)}
      onClick={() => toggleSort(SORT_REGISTERS)}
      sorted={isSorted(SORT_REGISTERS)}
      alignRight
    ><FormattedMessage {...messages.registers} />
    </TableCell>
    <TableCell
      columnId={COLUMN_FEATURES}
      hidden={isHidden(COLUMN_FEATURES)}
    ><FormattedMessage {...messages.features} />
    </TableCell>
    {/* <TableCell
      sortable
      columnId={COLUMN_LATEST_READING}
      hidden={isHidden(COLUMN_LATEST_READING)}
      onClick={() => toggleSort(SORT_LATEST_READING)}
      sorted={isSorted(SORT_LATEST_READING)}
      flex="3"
    ><FormattedMessage {...messages.latestReading} /></TableCell> */}
    <TableCell
      actions
    ><FormattedMessage {...messages.actions} />
    </TableCell>
  </TableHead>
);

meteringProfileHead.propTypes = {
  toggleSort: func.isRequired,
  isHidden: func.isRequired,
  isSorted: func.isRequired,
};

export const meteringProfileRow = ({ deviceId, ...props }) => meteringProfile => (
  <TableRow
    key={meteringProfile.name}
    {...props}
  >
    <TableCell
      columnId={COLUMN_NAME}
      style={{
        minWidth: '180px',
      }}
      flex="2"
    >
      {/* <Link to={`/metering-profiles/${meteringProfile.id}`}> */}
      {meteringProfile.name}
      {/* </Link> */}
    </TableCell>
    <TableCell columnId={COLUMN_OBIS}>{meteringProfile.profileObisCode}</TableCell>
    <TableCell columnId={COLUMN_PERIOD}>
      {meteringProfile.capturePeriod && <CapturePeriod period={meteringProfile.capturePeriod} />}
    </TableCell>
    <TableCell columnId={COLUMN_REGISTERS}>
      {meteringProfile.registers && meteringProfile.registers.length}
    </TableCell>
    <TableCell columnId={COLUMN_FEATURES}>
      <Features dense features={meteringProfile.features} />
    </TableCell>
    {/* <TableCell columnId={COLUMN_LATEST_READING}>
      {meteringProfile.lastReading && (<DateTime value={meteringProfile.lastReading} />)}
    </TableCell> */}
    <TableActionCell>
      {selectors.isOnDemandSupported(meteringProfile) && (
        <MeteringProfileMenu
          deviceId={deviceId}
          meteringProfile={meteringProfile}
        />
      )}
    </TableActionCell>
  </TableRow>
);

const MeteringProfileTable = ({
  meteringProfiles,
  isSelected,
  onSelectionChange,
  isSorted,
  toggleSort,
  deviceId,
  isHidden,
  ...props
}) => (
  <Table {...props}>
    {meteringProfileHead({
      isSorted,
      toggleSort,
      isHidden,
    })}
    {meteringProfiles.map(meteringProfile => meteringProfileRow({
      deviceId,
      selected: isSelected(meteringProfile),
      onClick: () => onSelectionChange(meteringProfile),
    })(meteringProfile))}
  </Table>
);

MeteringProfileTable.propTypes = {
  meteringProfiles: arrayOf(meteringProfileShape).isRequired,
  isSelected: func.isRequired,
  onSelectionChange: func.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  isHidden: func.isRequired,
};

MeteringProfileTable.defaultProps = {
  isHidden: R.always(false),
};

export default MeteringProfileTable;
