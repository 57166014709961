import { composeReducers } from 'common/store/reducer';
import {
  reducer as loadingReducer,
} from 'common/store/loading';

import {
  reducer as errorReducer,
} from 'common/store/error';

import {
  LOAD_STATUS,
  LOADED_STATUS,
  UPDATE_STATUS,
  ERROR_STATUS,
  CANCELLED_STATUS,
} from './actions';

const initial = {
  ...loadingReducer.defaultState,
  ...errorReducer.defaultState,
  status: undefined,
  deviceId: undefined,
};

export const loadStatus = (state, { deviceId }) => ({
  ...state,
  deviceId,
  status: undefined,
});

const loadedStatus = (state, { status }) => ({ ...state, status });

export default (state = initial, action) => {
  const reducer = {
    [LOAD_STATUS]: composeReducers(
      loadStatus,
      errorReducer.clearError,
      loadingReducer.startLoading,
    ),
    [CANCELLED_STATUS]: loadingReducer.stopLoading,
    [UPDATE_STATUS]: composeReducers(errorReducer.clearError, loadingReducer.startLoading),
    [LOADED_STATUS]: composeReducers(loadedStatus, loadingReducer.stopLoading),
    [ERROR_STATUS]: composeReducers(errorReducer.onError, loadingReducer.stopLoading),
  }[action.type];

  return reducer ? reducer(state, action) : state;
};
