import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { string, shape, oneOf, number } from 'prop-types';
import FirmwareChart from './chart';
import { messages as statusMessages, icons, STATUS_OFFLINE, STATUS_ONLINE, STATUS_POWERON, STATUS_POWEROFF, STATUS_UNKNOWN } from '@sma/components/device/search/status';
import { Widget } from 'common/mdc/card';
import S from 'common/service/sanctuary';
import { selectors } from '@sma/store/widgets/firmware';
import Wrapper from 'common/mdc/wrappers/wrapper';
import { connect } from 'react-redux';

const messages = defineMessages({
  displayName: {
    id: 'widget.firmware.name',
    defaultMessage: '{name}',
  },
  total: {
    id: 'widget.firmware.total',
    defaultMessage: '{total}',
  },
  firmwareLoadError: {
    id: 'widgets.firmware.loadError',
    defaultMessage: 'There was an error while loading the statistics',
  },
  firmwareNoDataLoaded: {
    id: 'widgets.firmware.notLoaded',
    defaultMessage: 'Statistics are not loaded',
  },
  firmwareLoadingData: {
    id: 'widgets.firmware.loadingData',
    defaultMessage: 'Loading statistics',
  },
  firmware: {
    id: 'widgets.firmware.title',
    defaultMessage: 'Meter Firmware Version Statistics',
  },
});

const WidgetFirmware = ({ firmwareDataMaybe, loadingFirmware, error }) => (
  <Widget
    loading={loadingFirmware}
    title={<FormattedMessage {...messages.firmware} />}
  >
    <Wrapper
      empty={firmwareDataMaybe.isNothing}
      loading={loadingFirmware}
      error={error}
      errorMessage={<FormattedMessage {...messages.firmwareLoadError} />}
      notFoundMessage={<FormattedMessage {...messages.firmwareNoDataLoaded} />}
      loadingMessage={<FormattedMessage {...messages.firmwareLoadingData} />}
    >
      <FirmwareChart values = { S.maybeToNullable(firmwareDataMaybe) } />
    </Wrapper>
  </Widget>
);

WidgetFirmware.propTypes = {
  firmwareDataMaybe: shape().isRequired,
};

WidgetFirmware.defaultProps = {
  firmwareDataMaybe: undefined,
};

export default connect(
  state => ({
    firmwareDataMaybe: selectors.getFirmwareWidgetMaybe(state),
    error: selectors.getError(state),
    loadingFirmware: selectors.isLoading(state),
  }),
)(WidgetFirmware);
