import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import Select, { SelectItem } from 'common/mdc/select';
import { ToolbarSection } from 'common/mdc/toolbar';
import { defineMessages, FormattedMessage } from 'react-intl';
import Menu, { MenuItem, MenuWrapper } from 'common/mdc/menu';
import RegisterName from '@sma/components/device/onDemandReading/name';
import { CheckboxField } from 'common/mdc/form';
import { LABEL_AFTER } from 'common/mdc/form/checkbox';
import Icon from 'common/mdc/icon';

import {
  actions,
  selectors,
} from '@sma/store/device/meteringProfileSamples';

const messages = defineMessages({
  from: {
    id: 'device.samples.from',
    defaultMessage: 'From',
  },
  to: {
    id: 'device.samples.to',
    defaultMessage: 'To',
  },
  registers: {
    id: 'device.samples.registers',
    defaultMessage: 'Registers',
  },
  meteringProfile: {
    id: 'device.samples.meteringProfile',
    defaultMessage: 'Metering profile: ',
  },
});

// const updateDateState = (state) => {
//   const strIsDate = str => !isNaN(Date.parse(str));
//   const strToDate = str => new Date(Date.parse(str));
//
//   this.setState(R.mapObjIndexed(strToDate, R.pick(['from', 'to'], R.pickBy(strIsDate, state))));
//
//   if (profile) {
//     this.onSelectProfile(profile.id);
//   }
// };

const SamplesToolbar = ({
  onSelectRegister,
  isRegisterSelected,
  meteringProfiles,
  meteringProfile,
  onSelectProfile,
  onDownload,
}) => (
  <ToolbarSection key="profiles" shrink>
    <div className="mdc-toolbar__text">
      <label htmlFor="metering-profiles-select">
        <FormattedMessage {...messages.meteringProfile} />
      </label>
    </div>
    <div className="mdc-toolbar__text">
      <Select
        onChange={onSelectProfile}
        value={meteringProfile.id}
        id="metering-profiles-select"
      >
        {meteringProfiles.map(
          profiles => (
            <SelectItem value={profiles.id} key={profiles.id}>
              {profiles.name}
            </SelectItem>
          ),
        )}
      </Select>
    </div>
    {!R.isEmpty(meteringProfile.registers) && (
      <MenuWrapper
        floating
        className="registers-button"
        wrappedElement={
          <Icon className="mdc-toolbar__icon">settings</Icon>
        }
      >
        <Menu>
          {meteringProfile.registers.map(
            (register, index) => (
              <MenuItem
                onClick={(e) => { e.stopPropagation(); }}
                key={index}
              >
                <CheckboxField
                  label={<RegisterName register={register} />}
                  id={`register-${index}`}
                  labelId={`label-${index}`}
                  labelPosition={LABEL_AFTER}
                  checked={isRegisterSelected(register)}
                  onChange={() => onSelectRegister(register)}
                />
              </MenuItem>
            ),
          )}
        </Menu>
      </MenuWrapper>
    )}
    <div className="mdc-toolbar__text">
     <label htmlFor="export-csv">
       <Icon
          className="mdc-toolbar__icon"
          onClick = { onDownload }
       >archive</Icon>
     </label>
    </div>
  </ToolbarSection>
);

export default R.compose(
  connect(
    state => ({
      isRegisterSelected: selectors.isRegisterSelected(state),
    }),
    ({
      onSelectProfile: actions.selectMeteringProfile,
      onSelectRegister: actions.selectRegister,
      onDownload: actions.downloadAll,
    }),
  ),
)(SamplesToolbar);
