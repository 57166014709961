import { combineReducers } from 'redux';

import statusData from './status/reducer';
import networkData from './network/reducer';
import consumerData from './consumer/reducer';
import supplyData from './supply/reducer';
import firmwareData from './firmware/reducer';

export default combineReducers({
  statusData,
  networkData,
  consumerData,
  supplyData,
  firmwareData
});
