import React from 'react';
import { Link } from 'found';
import { FormattedMessage } from 'react-intl';

const DeviceLink = ({ message, deviceId, values }) => (
  <FormattedMessage
    {...message}
    values={{
      deviceId: <b><Link to={`/devices/${deviceId}`}>{deviceId}</Link></b>,
      ...values,
    }}
  />
);

export default DeviceLink;
