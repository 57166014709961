import React from 'react';
import { oneOf } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Actions as FarceActions } from 'farce';
import Stepper, { Step } from 'common/mdc/stepper';
import Button from 'common/mdc/button';
import Step2, { STEP_ID as STEP1, title as title1 } from './steps/step1';
import Step3, { STEP_ID as STEP2, title as title2 } from './steps/step2';

export const groupCreate = Symbol('group-create');

export {
  STEP1,
  STEP2,
};

const messages = defineMessages({
  cancel: {
    id: 'groupCreate.actions.cancel',
    defaultMessage: 'Cancel',
  },
});

const CancelAction = connect(
  null,
  {
    cancel: () => FarceActions.go(-1),
  },
)(
  ({ cancel }) => (
    <Button type="reset" onClick={cancel}>
      <FormattedMessage {...messages.cancel} />
    </Button>
  ),
);

const GroupCreate = ({ initialStep, initialCriteria }) => (
  <Stepper
    initialStep={initialStep}
  >
    <Step
      title={title1}
      stepId={STEP1}
    >{stepper => (
      <Step2
        stepper={stepper}
        initialCriteria={initialCriteria}
        cancelAction={<CancelAction />}
      />
    )}
    </Step>
    <Step
      title={title2}
      stepId={STEP2}
    >{stepper => (
      <Step3
        stepper={stepper}
        cancelAction={<CancelAction />}
      />
    )}
    </Step>
  </Stepper>
);

GroupCreate.propTypes = {
  initialStep: oneOf([STEP1, STEP2]),
};

GroupCreate.defaultProps = {
  initialStep: STEP1,
};

export default GroupCreate;
