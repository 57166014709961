import {
  collection,
  selection,
  composeReducers,
} from 'common/store/reducer';

import {
  NAMESPACE,
} from './actions';

const initial = {
  ...collection.defaultSort('deviceId'),
};

export default composeReducers(
  collection.factory(NAMESPACE),
  selection.factory(NAMESPACE),
  (state = initial) => state,
);
