import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, takeUntil, catchError } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { defineMessages } from 'react-intl';
import { createErrorStream } from 'common/store/error';
import {
  TYPE_ERROR,
} from 'common/mdc/notifications/notification';
import { addNotification } from 'common/mdc/notifications/actions';

import {
  LOAD,
  ON_DEMAND_SAMPLING_CLOSE,
  clear,
  loadSuccess,
  loadError,
} from './actions';

const messages = defineMessages({
  readingFailed: {
    id: 'device.on-demand-reading.readingFailed',
    defaultMessage: 'On demand reading failed for meter {deviceId}',
  },
  retry: {
    id: 'device.on-demand-reading.retry',
    defaultMessage: 'Retry',
  },
});

export const errorNotification = ({ intl, subject, action }) => () =>
  addNotification({
    type: TYPE_ERROR,
    text: intl.formatMessage(messages.readingFailed, { deviceId: action.deviceId }),
    actions: [
      {
        text: intl.formatMessage(messages.retry),
        icon: 'autorenew',
        onClick: () => subject.next(action),
      },
    ],
  });

const clickEventStream = new Subject();

const loadSamples = (action, state, { intl, deviceService }) => merge(
  clickEventStream,
  action.pipe(
    ofType(LOAD),
    switchMap(action$ => deviceService
      .getOnDemandSamples(action$.deviceId, action$.registers)
      .pipe(
        map(loadSuccess),
        takeUntil(action.pipe(ofType(ON_DEMAND_SAMPLING_CLOSE))),
        catchError(createErrorStream(
          action$,
          loadError,
          errorNotification({
            intl,
            subject: clickEventStream,
            action: action$,
          }),
        )),
      )),
  ),
);

const closeOnDemandReadingEpic = action => action.pipe(
  ofType(ON_DEMAND_SAMPLING_CLOSE),
  map(clear),
);

export default combineEpics(
  loadSamples,
  closeOnDemandReadingEpic,
);
