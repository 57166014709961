import S from 'common/service/sanctuary';
import * as search from 'common/store/search/advanced/actions';
import { meterSearchCriteria } from '@sma/components/device/search/advanced';

export const NAMESPACE = 'DEVICE/SEARCH';

const searchActions = search.factory(NAMESPACE);

export const {
  LOAD_CRITERIA,
  APPLY_CRITERIA,
  REMOVE_CRITERIA,
  CLEAR,
} = searchActions.TYPES;

export const {
  toggleExpand,
  addCriteria,
  removeCriteria,
  applyCriteria,
  addConstraint,
  removeConstraint,
  clearConstraints,
  setOperator,
  setValue,
  clear,
} = searchActions;

export const loadCriteria = criteria => ({
  ...searchActions.loadCriteria(S.map(
    criteria$ => ({
      ...criteria$,
      ...meterSearchCriteria[criteria$.property],
    }),
    criteria,
  )),
});
