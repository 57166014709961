import S from 'common/service/sanctuary';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { number } from 'prop-types';

const SEC = 1;
const MIN = 60;
const HOUR = 3600;
const DAY = 86400;
const MONTH = 2592000;

export const messages = defineMessages({
  second: {
    id: 'capturePeriod.second',
    defaultMessage: '{amount, number} sec',
  },
  minute: {
    id: 'capturePeriod.minute',
    defaultMessage: '{amount, number} min',
  },
  hour: {
    id: 'capturePeriod.hour',
    defaultMessage: '{amount, number} {amount, plural, one {hour} other {hours}}',
  },
  day: {
    id: 'capturePeriod.day',
    defaultMessage: '{amount, number} {amount, plural, one {day} other {days}}',
  },
  month: {
    id: 'capturePeriod.month',
    defaultMessage: '{amount, number} {amount, plural, one {month} other {months}}',
  },
});

const getMessageForUnit = unit => S.toMaybe({
  [SEC]: messages.second,
  [MIN]: messages.minute,
  [HOUR]: messages.hour,
  [DAY]: messages.day,
  [MONTH]: messages.month,
}[unit]);

const getUnits = (sec) => {
  const duration = {};
  let secondsLeft = sec;

  [MONTH, DAY, HOUR, MIN].forEach((unit) => {
    duration[unit] = Math.floor(secondsLeft / unit);
    secondsLeft -= duration[unit] * unit;
  });

  duration[SEC] = secondsLeft;

  return duration;
};

const CapturePeriod = ({ period }) => {
  if (period === 0) {
    return (
      <span className="capture-period">
        <FormattedMessage values={{ amount: period }} {...messages.second} />
      </span>);
  }

  const units = getUnits(period);

  const getFormattedMessage = (unit, amount) => (
    amount
      ? S.maybeToNullable(S.map(
        message => <FormattedMessage key={unit} values={{ amount }} {...message} />,
        getMessageForUnit(unit),
      ))
      : null
  );

  const formattedMessages = [MONTH, DAY, HOUR, MIN, SEC]
    .reduce(
      (result, unit) => {
        const message = getFormattedMessage(unit, units[unit]);
        return message ? [...result, ' ', message] : result;
      },
      [],
    );

  return (
    <span className="capture-period">
      {formattedMessages.length ? formattedMessages : '-'}
    </span>
  );
};

CapturePeriod.propTypes = {
  period: number.isRequired,
};

export default CapturePeriod;
