import {
  collection,
  selection,
} from 'common/store/actions';

export const NAMESPACE = 'DEVICE/METERING_PROFILES';

const collectionActions = collection.factory(NAMESPACE);
const selectionActions = selection.factory(NAMESPACE);

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  TOGGLE_SORT,
} = collectionActions.TYPES;

export const {
  SET_SELECTED,
  CLEAR_SELECTED,
} = selectionActions.TYPES;

export const {
  setSelected,
  clearSelected,
} = selectionActions;

export const {
  loadSuccess,
  loadError,
  toggleSort,
  update,
} = collectionActions;

export const load = (deviceId, storeParams) => ({
  ...collectionActions.load(storeParams),
  deviceId,
});
