import React from 'react';
import S from 'common/service/sanctuary';
import { connect } from 'react-redux';
import { Primary, Hbox } from 'common/mdc/layout';
import { defineMessages, FormattedMessage } from 'react-intl';
import { func, bool, shape } from 'prop-types';
import {
  selectors as groupDevicesSelectors,
} from '@sma/store/group/devices';
import { selectors as groupSelectors } from '@sma/store/group/group';
import { Actions as FarceActions } from 'farce';
import Button from 'common/mdc/button';
import Toolbar, { ToolbarRow, ToolbarSection, ALIGN_END } from 'common/mdc/toolbar';
import { ResourceWrapper } from 'common/mdc/wrappers';
import GroupDetails from '@sma/components/group/details';
import DevicesCollection from '@sma/components/group/devicesCollection';

export const messages = defineMessages({
  edit: {
    id: 'group.internal.edit',
    defaultMessage: 'Edit',
  },
});

const GroupDevicesPrimary = ({
  loading,
  groupContext,
  groupMaybe,
  push,
}) => (
  <Primary loading={loading}>
    <Hbox style={{ flex: '1 0 auto', paddingTop: '16px' }}>
      <span className="group-details">
        <ResourceWrapper context={groupContext}>
          {group => <GroupDetails group={group} />}
        </ResourceWrapper>
      </span>
      <Toolbar className="group-details-toolbar">
        <ToolbarRow style={{ alignItems: 'baseline' }}>
          <ToolbarSection align={ALIGN_END}>
            {S.maybeToNullable(S.map(
              group => (
                <Button onClick={() => push(`/groups/${group.id}/edit`)}>
                  <FormattedMessage {...messages.edit} />
                </Button>
              ),
              groupMaybe,
            ))}
          </ToolbarSection>
        </ToolbarRow>
      </Toolbar>
    </Hbox>
    <DevicesCollection />
  </Primary>
);

export default connect(
  state => ({
    groupContext: groupSelectors.getGroupContext(state),
    groupMaybe: groupSelectors.getGroupMaybe(state),
    loading: groupDevicesSelectors.isLoading(state)
    || groupSelectors.isLoading(state),
  }),
  {
    push: FarceActions.push,
  },
)(GroupDevicesPrimary);
