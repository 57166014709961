import * as R from 'ramda';
import React from 'react';
import { arrayOf, func } from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
} from 'react-intl';

// import { Link } from 'found';

import Table, {
  TableRow,
  TableCell,
  TableHead,
} from 'common/mdc/table';

import {
  SORT_NAME,
  SORT_OBIS,
} from '@sma/service/api/meteringProfile';

import meteringProfileShape from './shape';
import CapturePeriod from './capturePeriod';
import Features from './features';

export const COLUMN_NAME = Symbol('name');
export const COLUMN_OBIS = Symbol('obis');
export const COLUMN_PERIOD = Symbol('period');
export const COLUMN_REGISTERS = Symbol('registers');
export const COLUMN_FEATURES = Symbol('features');
export const COLUMN_LATEST_READING = Symbol('latest-reading');

export const columns = [
  COLUMN_NAME,
  COLUMN_OBIS,
  COLUMN_PERIOD,
  COLUMN_REGISTERS,
  COLUMN_FEATURES,
  COLUMN_LATEST_READING,
];

export const messages = defineMessages({
  name: {
    id: 'meteringProfile.header.name',
    defaultMessage: 'Name',
  },
  obis: {
    id: 'meteringProfile.header.aliasId',
    defaultMessage: 'OBIS code',
  },
  period: {
    id: 'meteringProfile.header.period',
    defaultMessage: 'Capture period',
  },
  registers: {
    id: 'meteringProfile.header.registers',
    defaultMessage: 'Registers',
  },
  latestReading: {
    id: 'meteringProfile.header.latestReading',
    defaultMessage: 'Latest reading',
  },
  features: {
    id: 'meteringProfile.header.features',
    defaultMessage: 'Features',
  },
});


export const meteringProfileHead = ({
  toggleSort,
  isHidden,
  isSorted,
  ...props
}) => (
  <TableHead {...props}>
    <TableCell
      sortable
      columnId={COLUMN_NAME}
      onClick={() => toggleSort(SORT_NAME)}
      sorted={isSorted(SORT_NAME)}
      style={{
        minWidth: '180px',
      }}
      flex="2"
    ><FormattedMessage {...messages.name} />
    </TableCell>
    <TableCell
      sortable
      columnId={COLUMN_OBIS}
      hidden={isHidden(COLUMN_OBIS)}
      onClick={() => toggleSort(SORT_OBIS)}
      sorted={isSorted(SORT_OBIS)}
    ><FormattedMessage {...messages.obis} />
    </TableCell>
    <TableCell
      columnId={COLUMN_PERIOD}
      hidden={isHidden(COLUMN_PERIOD)}
    ><FormattedMessage {...messages.period} />
    </TableCell>
    <TableCell
      columnId={COLUMN_REGISTERS}
      hidden={isHidden(COLUMN_REGISTERS)}
      alignRight
    ><FormattedMessage {...messages.registers} />
    </TableCell>
    <TableCell
      columnId={COLUMN_FEATURES}
      hidden={isHidden(COLUMN_FEATURES)}
    ><FormattedMessage {...messages.features} />
    </TableCell>
  </TableHead>
);

meteringProfileHead.propTypes = {
  toggleSort: func.isRequired,
  isHidden: func.isRequired,
  isSorted: func.isRequired,
};

export const meteringProfileRow = ({ deviceId, ...props }) => meteringProfile => (
  <TableRow
    key={meteringProfile.id}
    {...props}
  >
    <TableCell
      columnId={COLUMN_NAME}
      style={{
        minWidth: '180px',
      }}
      flex="2"
    >
      {/* <Link to={`/metering-profiles/${meteringProfile.id}`}> */}
      {meteringProfile.name}
      {/* </Link> */}
    </TableCell>
    <TableCell columnId={COLUMN_OBIS}>{meteringProfile.profileObisCode}</TableCell>
    <TableCell columnId={COLUMN_PERIOD}>
      {meteringProfile.capturePeriod && <CapturePeriod period={meteringProfile.capturePeriod} />}
    </TableCell>
    <TableCell columnId={COLUMN_REGISTERS}>
      {meteringProfile.registers && meteringProfile.registers.length}
    </TableCell>
    <TableCell columnId={COLUMN_FEATURES}>
      <Features dense features={meteringProfile.features} />
    </TableCell>
  </TableRow>
);

const MeteringProfileTable = ({
  meteringProfiles,
  isSelected,
  onSelectionChange,
  isSorted,
  toggleSort,
  isHidden,
  ...props
}) => (
  <Table {...props}>
    {meteringProfileHead({
      isSorted,
      toggleSort,
      isHidden,
    })}
    {meteringProfiles.map(meteringProfile => meteringProfileRow({
      selected: isSelected(meteringProfile),
      onClick: () => onSelectionChange(meteringProfile),
    })(meteringProfile))}
  </Table>
);

MeteringProfileTable.propTypes = {
  meteringProfiles: arrayOf(meteringProfileShape).isRequired,
  isSelected: func.isRequired,
  onSelectionChange: func.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  isHidden: func.isRequired,
};

MeteringProfileTable.defaultProps = {
  isHidden: R.always(false),
};

export default MeteringProfileTable;
