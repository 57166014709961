import { compose, isEmpty, isNil } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { func, bool, shape } from 'prop-types';
import { Secondary } from 'common/mdc/layout';
import { ResourceWrapper } from 'common/mdc/wrappers';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import {
  selectors as deviceSelectors,
} from '@sma/store/device/device';
import Tabs, { TabItem } from 'common/mdc/tabs';
import Icon from 'common/mdc/icon';
import DeviceActions from './actions';
import DeviceTitle from './header';
import DetailsForm from './detailsForm';
import AlarmsState from './alarmState';
import DeviceShape from './shape';

const messages = defineMessages({
  details: {
    id: 'device.details',
    defaultMessage: 'Details',
  },
  alarms: {
    id: 'device.alarms',
    defaultMessage: 'Alarms',
  },
  noDeviceDetails: {
    id: 'device.noDeviceDetails',
    defaultMessage: 'No meter details loaded',
  },
  noDeviceAlarms: {
    id: 'device.noDeviceAlarms',
    defaultMessage: 'No alarms on this meter',
  },
});

const TAB_DETAILS = Symbol('details');
const TAB_ALARMS = Symbol('alarms');

class DeviceSecondary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.select = this.select.bind(this);
    this.state = {
      tab: TAB_DETAILS,
    };
  }

  select(tab) {
    this.setState({ tab });
  }

  render() {
    const { device, onClose, loading, deviceContext, intl } = this.props;
    const { tab } = this.state;

    return (
      <Secondary
        title={<DeviceTitle device={device} />}
        onClose={onClose}
        loading={loading}
        tools={<DeviceActions device={device} />}
        tabsRow={(
          <Tabs>
            <TabItem
              active={tab === TAB_DETAILS}
              onClick={() => this.select(TAB_DETAILS)}
            >
              <FormattedMessage {...messages.details} />
            </TabItem>
            <TabItem
              active={tab === TAB_ALARMS}
              onClick={() => this.select(TAB_ALARMS)}
            >
              <FormattedMessage {...messages.alarms} />
            </TabItem>
          </Tabs>
        )}
      >
        <ResourceWrapper
          context={deviceContext}
          messages={{
            empty: <FormattedMessage {...messages.noDeviceDetails} />,
          }}
        >
          {deviceDetails => ({
            [TAB_DETAILS]: <DetailsForm device={deviceDetails} displayDeviceLink />,
            [TAB_ALARMS]: isNil(deviceDetails.alarms) || isEmpty(deviceDetails.alarms) ?
              <div className="device-alarms-pane empty-alarms">
                <Icon>check_circle</Icon>{intl.formatMessage(messages.noDeviceAlarms)}
              </div> :
              <AlarmsState alarms={deviceDetails.alarms} />,
          }[tab])}
        </ResourceWrapper>
      </Secondary>
    );
  }
}

DeviceTitle.propTypes = {
  device: DeviceShape.isRequired,
};

DeviceSecondary.propTypes = {
  onClose: func.isRequired,
  device: shape().isRequired,
  loading: bool,
};

DeviceSecondary.defaultProps = {
  loading: false,
};

export default compose(
  connect(
    state => ({
      loading: deviceSelectors.isLoading(state),
      deviceContext: deviceSelectors.getDeviceContext(state),
    }),
  ),
  injectIntl,
)(DeviceSecondary);
