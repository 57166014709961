import React from 'react';
import Icon from 'common/mdc/icon/icon';
import { string, arrayOf, shape } from 'prop-types';
import { injectIntl } from 'react-intl';
import { toString } from './type';

const AlarmsWarning = ({ alarms }) => (
  (alarms && alarms.length) ? (
    <Icon
      className="alarm"
      title={
        alarms
          .map(toString)
          .join(', ')
      }
    >
      warning
    </Icon>
  ) : null
);

AlarmsWarning.propTypes = {
  alarms: arrayOf(shape({
    alarmType: string.isRequired,
    alarmBit: string.isRequired,
    status: string,
  })).isRequired,
};

export default injectIntl(AlarmsWarning);
