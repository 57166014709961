import S from 'common/service/sanctuary';
import { createSelector } from 'reselect';
import {
  collection,
  selection,
} from 'common/store/selectors';

const getScope = ({ meteringProfile: { meteringProfiles } }) => meteringProfiles;

const collectionSelectors = collection.factory(getScope);
const selectionSelectors = selection.factory(getScope);

export const {
  isSorted,
  isLoading,
  getPaginator,
  getQuery,
  localSearch,
  getCollectionContext,
} = collectionSelectors;

export const {
  isSelected,
  getSelected,
} = selectionSelectors;

const map2 = S.compose(S.map, S.map);

export const getMeteringProfilesContext = createSelector(
  localSearch,
  collectionSelectors.getCollectionContext,
  (search, context) => map2(search)(context),
);

export const getStoreParams = createSelector(
  collectionSelectors.getStoreParams,
  ({ query, ...params }) => params,
);
