import S from 'common/service/sanctuary';
import { createSelector } from 'reselect';
import * as R from 'ramda';
import {
  collection,
} from 'common/store/selectors';

export const getScope = ({ widgets: { firmwareData } }) => firmwareData;

const collectionFactory = collection.factory(getScope);

const chooseSafeNumber = (l, r) => (Number(r) || l);

export const {
  isLoading,
  getCollectionContext,
  getCollectionMaybe,
} = collectionFactory;

export const getError = createSelector(
  getScope,
  ({ error }) => error,
);

export const getFirmwareWidgetMaybe = collectionFactory.getCollectionMaybe;
