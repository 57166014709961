import S from 'common/service/sanctuary';
import * as R from 'ramda';
import collection from 'common/store/collection';
import { createSelector } from 'reselect';
import {
  getDeviceProfilesContext,
  getMeteringProfile as getMeteringProfileMaybe,
  compareRegisters,
  getDeviceId,
  hasFeature,
  hasOnDemandFeatures,
  hasRegisters,
} from '../meteringProfile/selectors';

const getScope = ({ device: { onDemandReading } }) => onDemandReading;

const {
  isLoading,
  getCollectionMaybe,
} = collection.selectorsFactory(getScope);

export const isOnDemandSupported = profile => profile.features
  && hasOnDemandFeatures(profile)
  && hasRegisters(profile);

const lift2 = R.compose(R.map, R.map);

const getOnDemandProfilesContext = createSelector(
  getDeviceProfilesContext,
  getDeviceId,
  (ctx, deviceId) => lift2(R.filter(isOnDemandSupported))(ctx(S.maybeToNullable(deviceId))),
);

const getMeterginProfilesByFeature = createSelector(
  getOnDemandProfilesContext,
  profilesContext => feature => S.map(
    S.filter(hasFeature(feature)),
    S.chain(S.eitherToMaybe, profilesContext),
  ),
);

const getSamples = createSelector(
  getCollectionMaybe,
  S.fromMaybe([]),
);

const getSample = createSelector(
  getSamples,
  samples => register => S.map(
    S.prop('value'),
    S.find(compareRegisters(register), samples),
  ),
);

const getRawValue = createSelector(
  getSamples,
  samples => register => S.map(
    S.prop('rawValue'),
    S.find(compareRegisters(register), samples),
  ),
);

const getSelectedMeteringProfile = createSelector(
  getScope,
  getMeteringProfileMaybe,
  ({ meteringProfileId }, getProfile) => S.chain(getProfile, S.toMaybe(meteringProfileId)),
);

export {
  isLoading,
  getSelectedMeteringProfile,
  getOnDemandProfilesContext,
  getMeterginProfilesByFeature,
  getSample,
  getRawValue
};
