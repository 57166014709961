import S from 'common/service/sanctuary';
import React from 'react';
import { Link } from 'found';
import { connect } from 'react-redux';
import { shape, element, string } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Main from 'common/mdc/layout';
import Breadcrumbs from 'common/mdc/breadcrumbs';
import Tabs, { TabItem } from 'common/mdc/tabs';
import { ToolbarRow, ToolbarSection } from 'common/mdc/toolbar';
import DeviceTitle from '@sma/components/device/header';
import DeviceActions from '@sma/components/device/actions';
import { ResourceWrapper } from 'common/mdc/wrappers';

import {
  selectors as deviceSelectors,
} from '@sma/store/device/device';

import { messages as devicesMessages } from '../devices';

export const messages = defineMessages({
  dashboard: {
    id: 'title.device.dashboard',
    defaultMessage: 'Dashboard',
  },
  meteringProfiles: {
    id: 'title.device.meteringProfiles',
    defaultMessage: 'Metering profiles',
  },
  samples: {
    id: 'title.device.samples',
    defaultMessage: 'Samples',
  },
  alarms: {
    id: 'title.device.alarms',
    defaultMessage: 'Alarm log',
  },
  events: {
    id: 'title.device.events',
    defaultMessage: 'Events',
  },
  notFound: {
    id: 'page.device.notFound',
    defaultMessage: 'Meter not found',
  },
  deviceNotExists: {
    id: 'page.device.deviceNotExists',
    defaultMessage: 'Meter with id "{deviceId}" does not exist',
  },
  error: {
    id: 'page.device.error',
    defaultMessage: 'Meter error',
  },
});

const DeviceTabs = ({ deviceId }) => (
  <ToolbarRow>
    <ToolbarSection align={ToolbarSection.ALIGN_START} shrink>
      <Tabs>
        <Link
          exact
          to={`/devices/${encodeURI(deviceId)}`}
          activePropName="active"
          Component={TabItem}
        ><FormattedMessage {...messages.dashboard} />
        </Link>
        <Link
          to={`/devices/${encodeURI(deviceId)}/metering-profiles`}
          activePropName="active"
          Component={TabItem}
        ><FormattedMessage {...messages.meteringProfiles} />
        </Link>
        <Link
          to={`/devices/${encodeURI(deviceId)}/samples`}
          activePropName="active"
          Component={TabItem}
        ><FormattedMessage {...messages.samples} />
        </Link>
        <Link
          to={`/devices/${encodeURI(deviceId)}/alarms`}
          activePropName="active"
          Component={TabItem}
        ><FormattedMessage {...messages.alarms} />
        </Link>
        <Link
          to={`/devices/${encodeURI(deviceId)}/events`}
          activePropName="active"
          Component={TabItem}
        ><FormattedMessage {...messages.events} />
        </Link>
      </Tabs>
    </ToolbarSection>
  </ToolbarRow>
);

const Device = ({
  loading,
  children,
  deviceMaybe,
  deviceContext,
  params: { deviceId },
}) => (
  <Main
    title={
      <Breadcrumbs>
        <Link to="/devices"><FormattedMessage {...devicesMessages.title} /></Link>
        {S.maybe(
          <span>{deviceId}</span>,
          device => <DeviceTitle device={device} />,
          deviceMaybe,
        )}
      </Breadcrumbs>
    }
    tools={S.maybeToNullable(S.map(device => <DeviceActions device={device} />, deviceMaybe))}
    toolbar={<DeviceTabs deviceId={deviceId} />}
  >
    <ResourceWrapper
      loading={loading}
      context={deviceContext}
      messages={{
        empty: <FormattedMessage {...messages.notFound} />,
        emptyReasons: [
          <FormattedMessage values={{ deviceId }} {...messages.deviceNotExists} />,
        ],
      }}
    >{device => (React.cloneElement(React.Children.only(children), {
        device,
      })
    )}
    </ResourceWrapper>
  </Main>
);

Device.propTypes = ({
  children: element.isRequired,
  params: shape({
    deviceId: string.isRequired,
  }).isRequired,
});

export default connect(
  state => ({
    loading: deviceSelectors.isLoading(state),
    deviceMaybe: deviceSelectors.getDeviceMaybe(state),
    deviceContext: deviceSelectors.getDeviceContext(state),
  }),
)(Device);
