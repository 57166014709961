import React from 'react';
import { DetailsForm } from '@sma/components/device/meteringProfile';
import meteringProfileShape from '@sma/components/device/meteringProfile/shape';

const Details = ({ meteringProfile }) => (
  <div className="metering-profile-details-pane">
    <DetailsForm meteringProfile={meteringProfile} />
  </div>
);

Details.propTypes = {
  meteringProfile: meteringProfileShape.isRequired,
};

export default Details;
