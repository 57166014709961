import React from 'react';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { string } from 'prop-types';

// https://confluence.se.cyco.io/display/ENG/%5BDesignSpec%5D+OmniMesh+-+Meter+alarms
const METER_PHASE_MISSING_R = 0;
const METER_PHASE_MISSING_Y = 1;
const METER_PHASE_MISSING_B = 2;
const METER_OVER_VOLTAGE = 3;
const METER_UNDER_VOLTAGE = 4;
const METER_VOLT_UNBALANCE = 5;
const METER_CURRENT_REVERSE_R = 6;
const METER_CURRENT_REVERSE_Y = 7;
const METER_CURRENT_REVERSE_B = 8;
const METER_CURRENT_UNBALANCE = 9;
const METER_CURRENT_BYPASS = 10;
const METER_OVER_CURRENT = 11;
const METER_LOW_PF = 12;
const METER_EARTH_LEAKAGE = 51;
const METER_MAGNETIC_INFLUENCE = 81;
const METER_NEUTRAL_DISTURBANCE = 82;
const METER_COVER_OPEN = 83;
const METER_CONNECT_DISCONNECT = 84;
const METER_LAST_GASP = 85;
const METER_FIRST_BREATH = 86;
const METER_BILLING_INCREMENT = 87;
const METER_PHASE_OPEN_R = 120;
const METER_PHASE_OPEN_Y = 121;
const METER_PHASE_OPEN_B = 122;
const METER_SWITCH_WELD = 124;
const METER_NEUTRAL_MISS = 125;
const METER_OVER_LOAD = 126;
const METER_COVER_OPEN_MODULE = 127;

export const messages = defineMessages({
  [METER_PHASE_MISSING_R]: {
    id: `device.alarms.type.${METER_PHASE_MISSING_R}`,
    defaultMessage: '1/2 - R Phase - Voltage missing for 3 phase meter (Occurrence / Restoration)',
  },
  [METER_PHASE_MISSING_Y]: {
    id: `device.alarms.type.${METER_PHASE_MISSING_Y}`,
    defaultMessage: '3/4 - Y Phase - Voltage missing',
  },
  [METER_PHASE_MISSING_B]: {
    id: `device.alarms.type.${METER_PHASE_MISSING_B}`,
    defaultMessage: '5/6 - B Phase - Voltage missing',
  },
  [METER_OVER_VOLTAGE]: {
    id: `device.alarms.type.${METER_OVER_VOLTAGE}`,
    defaultMessage: '7/8 - Over voltage in any phase',
  },
  [METER_UNDER_VOLTAGE]: {
    id: `device.alarms.type.${METER_UNDER_VOLTAGE}`,
    defaultMessage: '9/10 - Low voltage in any phase',
  },
  [METER_VOLT_UNBALANCE]: {
    id: `device.alarms.type.${METER_VOLT_UNBALANCE}`,
    defaultMessage: '11/12 - Voltage unbalance',
  },
  [METER_CURRENT_REVERSE_R]: {
    id: `device.alarms.type.${METER_CURRENT_REVERSE_R}`,
    defaultMessage: '51/52 - R Phase current reverse (Import type only)',
  },
  [METER_CURRENT_REVERSE_Y]: {
    id: `device.alarms.type.${METER_CURRENT_REVERSE_Y}`,
    defaultMessage: '53/54 - Y Phase current reverse (Import type only)',
  },
  [METER_CURRENT_REVERSE_B]: {
    id: `device.alarms.type.${METER_CURRENT_REVERSE_B}`,
    defaultMessage: '55/56 - B Phase current reverse (Import type only)',
  },
  [METER_CURRENT_UNBALANCE]: {
    id: `device.alarms.type.${METER_CURRENT_UNBALANCE}`,
    defaultMessage: '63/64 - Current unbalance',
  },
  [METER_CURRENT_BYPASS]: {
    id: `device.alarms.type.${METER_CURRENT_BYPASS}`,
    defaultMessage: '65/66 - Current bypass/short',
  },
  [METER_OVER_CURRENT]: {
    id: `device.alarms.type.${METER_OVER_CURRENT}`,
    defaultMessage: '67/68 - Over current in any phase',
  },
  [METER_LOW_PF]: {
    id: `device.alarms.type.${METER_LOW_PF}`,
    defaultMessage: '205/206 - Very low PF',
  },
  [METER_EARTH_LEAKAGE]: {
    id: `device.alarms.type.${METER_EARTH_LEAKAGE}`,
    defaultMessage: '69/70 - Earth loading',
  },
  [METER_MAGNETIC_INFLUENCE]: {
    id: `device.alarms.type.${METER_MAGNETIC_INFLUENCE}`,
    defaultMessage: '201/202 - Influence of permanent magnet or ac/dc electromagnet',
  },
  [METER_NEUTRAL_DISTURBANCE]: {
    id: `device.alarms.type.${METER_NEUTRAL_DISTURBANCE}`,
    defaultMessage: '203/204 - Neutral disturbance - HF, dc or alternate method',
  },
  [METER_COVER_OPEN]: {
    id: `device.alarms.type.${METER_COVER_OPEN}`,
    defaultMessage: '251 - Meter cover opening',
  },
  [METER_CONNECT_DISCONNECT]: {
    id: `device.alarms.type.${METER_CONNECT_DISCONNECT}`,
    defaultMessage: '301/302 - Meter load disconnected/Meter load connected',
  },
  [METER_LAST_GASP]: {
    id: `device.alarms.type.${METER_LAST_GASP}`,
    defaultMessage: 'Last Gasp - Occurrence',
  },
  [METER_FIRST_BREATH]: {
    id: `device.alarms.type.${METER_FIRST_BREATH}`,
    defaultMessage: 'First breath - Restoration',
  },
  [METER_BILLING_INCREMENT]: {
    id: `device.alarms.type.${METER_BILLING_INCREMENT}`,
    defaultMessage: 'Increment in billing counter (Manual/MRI reset)',
  },
  [METER_PHASE_OPEN_R]: {
    id: `device.alarms.type.${METER_PHASE_OPEN_R}`,
    defaultMessage: '0 R Phase- CT open',
  },
  [METER_PHASE_OPEN_Y]: {
    id: `device.alarms.type.${METER_PHASE_OPEN_Y}`,
    defaultMessage: '1 Y Phase- CT open',
  },
  [METER_PHASE_OPEN_B]: {
    id: `device.alarms.type.${METER_PHASE_OPEN_B}`,
    defaultMessage: '2 B Phase- CT open',
  },
  [METER_SWITCH_WELD]: {
    id: `device.alarms.type.${METER_SWITCH_WELD}`,
    defaultMessage: '4 Switch weld',
  },
  [METER_NEUTRAL_MISS]: {
    id: `device.alarms.type.${METER_NEUTRAL_MISS}`,
    defaultMessage: '5 Neutral Miss',
  },
  [METER_OVER_LOAD]: {
    id: `device.alarms.type.${METER_OVER_LOAD}`,
    defaultMessage: '6 Over load',
  },
  [METER_COVER_OPEN_MODULE]: {
    id: `device.alarms.type.${METER_COVER_OPEN_MODULE}`,
    defaultMessage: '7 Module cover Open',
  },
});


export const toString = alarm => `${alarm.alarmBit} - ${alarm.alarmType}`;
export const translate = (intl, alarm) =>
  messages[alarm.alarmBit] && intl.formatMessage(messages[alarm.alarmBit]);

const AlarmType = ({ type, intl }) => (
  <span title={messages[type] && intl.formatMessage(messages[type])}>
    {messages[type] ? <FormattedMessage {...messages[type]} /> : type}
  </span>
);

AlarmType.propTypes = {
  type: string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(AlarmType);
