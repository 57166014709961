import S from 'common/service/sanctuary';
import { propEq, isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import {
  collection,
  selection,
} from 'common/store/selectors';

import * as features from './features';

const getScope = ({ device: { meteringProfile } }) => meteringProfile;

export const compareRegisters = ({ attributeId, obisCode, profileObisCode }) => register => (
  attributeId === register.attributeId
  && obisCode === register.obisCode
  && profileObisCode === register.profileObisCode
);

const collectionSelectors = collection.factory(getScope);
const selectionSelectors = selection.factory(getScope);

const getFeatures = S.get(S.is(Array), 'features');

export const hasFeature = feature => profile => S.maybe(
  false,
  S.elem(feature),
  getFeatures(profile),
);

export const hasOnDemandFeatures = profile => S.maybe(
  false,
  S.anyPass([
    S.elem(features.ON_DEMAND),
    S.elem(features.ON_DEMAND_PROFILE),
  ]),
  getFeatures(profile),
);

export const hasRegisters = profile => S.maybe(
  false,
  S.complement(isEmpty),
  S.get(S.is(Array), 'registers', profile),
);

export const {
  isSorted,
  isLoading,
  getStoreParams,
} = collectionSelectors;

export const {
  isSelected,
  getSelected,
} = selectionSelectors;

export const getDeviceId = createSelector(
  getScope,
  ({ deviceId }) => S.toMaybe(deviceId),
);

export const getMeteringProfilesContext = collectionSelectors.getCollectionContext;
export const getMeteringProfilesMaybe = collectionSelectors.getCollectionMaybe;

export const getDeviceProfilesContext = createSelector(
  getDeviceId,
  collectionSelectors.getCollectionContext,
  (maybeDeviceId, collectionContext) => deviceId => S.chain(
    id => (
      deviceId === id
        ? collectionContext
        : S.Nothing
    ),
    maybeDeviceId,
  ),
);

export const getMeteringProfile = createSelector(
  getMeteringProfilesMaybe,
  maybeProfiles => meteringProfileId => S.chain(
    S.find(propEq('id', meteringProfileId)),
    maybeProfiles,
  ),
);
