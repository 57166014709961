import { isEmpty } from 'ramda';
import React from 'react';
import { arrayOf } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Vbox } from 'common/mdc/layout';
import Empty from 'common/mdc/wrappers/empty';
import RegistersTable from './table';
import registerShape from './shape';

export const messages = defineMessages({
  empty: {
    id: 'device.meteringProfiles.registers.empty',
    defaultMessage: 'No registers found',
  },
  registers: {
    id: 'device.meteringProfiles.registers.noRegisters',
    defaultMessage: 'Metering profile does not contain any registers',
  },
});

const Registers = ({ registers }) => (
  <Vbox className="metering-profile-registers-pane">
    {isEmpty(registers)
      ? (
        <Empty
          title={<FormattedMessage {...messages.empty} />}
          reasons={[
            <FormattedMessage {...messages.registers} />,
          ]}
        />
      )
      : <RegistersTable registers={registers} />
  }
  </Vbox>
);

Registers.propTypes = {
  registers: arrayOf(registerShape).isRequired,
};

export default Registers;
