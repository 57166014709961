import React from 'react';
import Header, { AppSelector } from 'common/components/header';
import { UserMenu } from './user';

const SMAHeader = () => (
  <Header
    userMenu={<UserMenu />}
    app={AppSelector.SMAS}
  />
);

export default SMAHeader;
