import { composeReducers } from 'common/store/reducer';
import factory from 'common/store/collection/reducer';
import { NAMESPACE } from './actions';

const collectionReducer = factory(NAMESPACE);

export default composeReducers(
  collectionReducer,
  (state, action) => {
    const reducer = {
    }[action.type];

    return reducer ? reducer(state, action) : state;
  },
);
