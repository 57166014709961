import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { shape, string, arrayOf } from 'prop-types';
import { toString, translate } from '@sma/components/device/alarms/type';
import AlarmState from '@sma/components/device/alarms/state';
import Form, { DisplayField } from 'common/mdc/form';

const Alarms = ({ alarms, intl }) => (
  <Form className="device-alarms-pane">
    {alarms.map(alarm => (
      <DisplayField label={toString(alarm)}>
        <AlarmState status={alarm.status} />
      </DisplayField>))}
  </Form>
);

Alarms.propTypes = {
  alarms: arrayOf(shape({
    alarmType: string.isRequired,
    status: string.isRequired,
  })).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(Alarms);
