import defaults from '../../etc/config.default.json';
import configURL from '../../etc/config.json';

export default () => fetch(configURL)
  .catch(() => ({}))
  .then(res => res.json())
  .then(config => ({
    ...defaults,
    ...config,
  }));

