import React from 'react';
import { oneOf, arrayOf } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import Icon from 'common/mdc/icon';
import List, { ListItem } from 'common/mdc/list';


export const DEVICE_MANAGEMENT = 'DEVICE_MANAGEMENT';
export const METERING_PROFILE = 'METERING_PROFILE';
export const POWER_SWITCH = 'POWER_SWITCH';
export const DEVICE_ALARMS = 'DEVICE_ALARMS';
export const DATA_PROFILE = 'DATA_PROFILE';
export const DLMS_OP = 'DLMS_OP';
export const ON_DEMAND_READ = 'ON_DEMAND_READ';

export const messages = defineMessages({
  deviceManagement: {
    id: 'device.addOns.deviceManagement',
    defaultMessage: 'Meter management',
  },
  meteringProfile: {
    id: 'device.addOns.meteringProfile',
    defaultMessage: 'Metering profile',
  },
  powerSwitch: {
    id: 'device.addOns.powerSwitch',
    defaultMessage: 'Power switch',
  },
  deviceAlarms: {
    id: 'device.addOns.deviceAlarms',
    defaultMessage: 'Meter alarms',
  },
  dataProfile: {
    id: 'device.addOns.dataProfile',
    defaultMessage: 'Data profile',
  },
  dlmsOp: {
    id: 'device.addOns.dlmsOp',
    defaultMessage: 'DLMS operations',
  },
  onDemandRead: {
    id: 'device.addOns.onDemandRead',
    defaultMessage: 'On demand reading',
  },
});

const getMessage = type => ({
  [DEVICE_MANAGEMENT]: messages.deviceManagement,
  [METERING_PROFILE]: messages.meteringProfile,
  [POWER_SWITCH]: messages.powerSwitch,
  [DEVICE_ALARMS]: messages.deviceAlarms,
  [DATA_PROFILE]: messages.dataProfile,
  [DLMS_OP]: messages.dlmsOp,
  [ON_DEMAND_READ]: messages.onDemandRead,
})[type];

const icons = {
  [DEVICE_MANAGEMENT]: 'compare_arrows',
  [METERING_PROFILE]: 'trending_up',
  [POWER_SWITCH]: 'power_settings_new',
  [DEVICE_ALARMS]: 'lightbulb_outline',
  [DATA_PROFILE]: 'description',
  [DLMS_OP]: 'import_export',
  [ON_DEMAND_READ]: 'insert_chart',
};

const AddOn = ({ addOns }) => (
  <List dense>
    { addOns.map(addOn => (
      <ListItem key={addOn} style={{ height: 'auto' }}>
        <Icon>
          {icons[addOn]}
        </Icon>
        <span className="description">
          {getMessage(addOn) && (
            <FormattedMessage {...getMessage(addOn)} />
          )}
        </span>
      </ListItem>
    ))
    }
  </List>
);

AddOn.propTypes = {
  addOns: arrayOf(oneOf([
    DEVICE_MANAGEMENT,
    METERING_PROFILE,
    POWER_SWITCH,
    DEVICE_ALARMS,
    DATA_PROFILE,
    DLMS_OP,
    ON_DEMAND_READ,
  ])).isRequired,
};

export default AddOn;
