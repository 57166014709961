import {
  collection,
  composeReducers,
} from 'common/store/reducer';

import {
  NAMESPACE,
  SELECT_PROFILE,
  ON_DEMAND_SAMPLING_CLOSE,
} from './actions';

const initial = {
  ...collection.defaultState,
  deviceId: undefined,
  meteringProfileId: undefined,
};

const selectProfile = (state, { meteringProfileId }) => ({
  ...state,
  meteringProfileId,
});

const onDemandSamplingClose = state => ({
  ...state,
  deviceId: undefined,
  meteringProfileId: undefined,
  loading: false,
});

const onDemandReadingReducer = (state = initial, action) => {
  const reducer = {
    [SELECT_PROFILE]: selectProfile,
    [ON_DEMAND_SAMPLING_CLOSE]: onDemandSamplingClose,
  }[action.type];

  return reducer ? reducer(state, action) : state;
};

export default composeReducers(
  collection.factory(NAMESPACE),
  onDemandReadingReducer,
);

