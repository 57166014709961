import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Link } from 'found';
import { string, shape, oneOf, number } from 'prop-types';
import { ProgressDeterminate } from 'common/mdc/progress';
import Icon from 'common/mdc/icon';
import { messages as statusMessages, icons, STATUS_OFFLINE, STATUS_ONLINE, STATUS_POWERON, STATUS_POWEROFF, STATUS_UNKNOWN } from '@sma/components/device/search/status';
import { iconFlag } from '@sma/components/device/header';
import { EQUALS } from 'common/store/search/advanced/constraint/operators';
import { STATUS } from '@sma/components/device/search/advanced';
import { Widget } from 'common/mdc/card';
import S from 'common/service/sanctuary';
import { sum } from 'ramda';
import { selectors } from '@sma/store/widgets/supply';
import Wrapper from 'common/mdc/wrappers/wrapper';
import { connect } from 'react-redux';

const messages = defineMessages({
  displayName: {
    id: 'widget.supply.name',
    defaultMessage: '{name}',
  },
  powerOnInPercentage: {
    id: 'widget.supply.powerOnInPercentage',
    defaultMessage: '{progress}% power on',
  },
  unknownInPercentage: {
    id: 'widget.supply.unknownInPercentage',
    defaultMessage: '{progress}% unknown',
  },
  supplyLoadError: {
    id: 'widgets.supply.loadError',
    defaultMessage: 'There was an error while loading the statistics',
  },
  supplyNoDataLoaded: {
    id: 'widgets.supply.notLoaded',
    defaultMessage: 'Status statistics are not loaded',
  },
  supplyLoadingData: {
    id: 'widgets.supply.loadingData',
    defaultMessage: 'Loading status statistics',
  },
  supply: {
    id: 'widgets.supply.status',
    defaultMessage: 'Meter Power Supply Status',
  },
});

const progress = ({ total, count }) =>
  total && Math.round((count / total) * 100);

const ProgressBlock = ({ name, total, count, unknown }) => (
  <div className="progress-bar">
    <div className="left-label">
      <FormattedMessage
        values={{
          name: name,
        }}
        {...messages.displayName}
      />
      <Link
        className="count"
        to={{
          pathname: '/devices',
          params: {
            initialCriteria: [],
          },
        }}
      >{total}
      </Link>
    </div>
    <div className="right-label">
      <FormattedMessage
        values={{
          progress: progress({ total, count }),
        }}
        {...messages.powerOnInPercentage}
      />{" "}
      <FormattedMessage
        values={{
          progress: progress({ total, count: unknown }),
        }}
        {...messages.unknownInPercentage}
      />
    </div>
    <ProgressDeterminate progress={progress({ total, count })} />
  </div>
);

ProgressBlock.propTypes = {
  name: string.isRequired,
  total: number.isRequired,
  count: number.isRequired,
};

const WidgetSupply = ({ supplyDataMaybe, loadingSupply, error }) => (
  <Widget
    loading={loadingSupply}
    title={<FormattedMessage {...messages.supply} />}
    subtitle={
      S.maybeToNullable(S.map(
        ({ categoryOne, categoryTwo, categoryThree }) => (
          <Link to={{
            pathname: '/devices',
            params: {
              initialCriteria: [],
            },
          }}
          >{sum([
            categoryOne.powerOn,
            categoryOne.powerOff,
            categoryOne.unknown,
            categoryTwo.powerOn,
            categoryTwo.powerOff,
            categoryTwo.unknown,
            categoryThree.powerOn,
            categoryThree.powerOff,
            categoryThree.unknown,
          ])}
          </Link>
        ),
        supplyDataMaybe,
      ))
    }
  >
    <Wrapper
      empty={supplyDataMaybe.isNothing}
      loading={loadingSupply}
      error={error}
      errorMessage={<FormattedMessage {...messages.supplyLoadError} />}
      notFoundMessage={<FormattedMessage {...messages.supplyNoDataLoaded} />}
      loadingMessage={<FormattedMessage {...messages.supplyLoadingData} />}
    >
      {S.maybeToNullable(S.map(
        ({ categoryOne, categoryTwo, categoryThree }) => (
          <div className="widget-supply">
            <div className="powered-devices">
              <div className="power-on-devices">
                <Icon>{icons[STATUS_ONLINE]}</Icon>
                <Link
                  className="count"
                  to={{
                    pathname: '/devices',
                    params: {
                      initialCriteria: [],
                    },
                  }}
                >
                  {`${sum([categoryOne.powerOn, categoryTwo.powerOn, categoryThree.powerOn])} `}
                  <FormattedMessage {...statusMessages[STATUS_POWERON]} />
                </Link>
              </div>
              <div className="power-off-devices">
                <Icon>{icons[STATUS_OFFLINE]}</Icon>
                <Link
                  className="count"
                  to={{
                    pathname: '/devices',
                    params: {
                      initialCriteria: [],
                    },
                  }}
                >
                  {`${sum([categoryOne.powerOff, categoryTwo.powerOff, categoryThree.powerOff])} `}
                  <FormattedMessage {...statusMessages[STATUS_POWEROFF]} />
                </Link>
              </div>
              <div className="unknown-devices">
                <Icon>{icons[STATUS_UNKNOWN]}</Icon>
                <Link
                  className="count"
                  to={{
                    pathname: '/devices',
                    params: {
                      initialCriteria: [],
                    },
                  }}
                >
                  {`${sum([categoryOne.unknown, categoryTwo.unknown, categoryThree.unknown])} `}
                  <FormattedMessage {...statusMessages[STATUS_UNKNOWN]} />
                </Link>
              </div>
            </div>
            <ProgressBlock
              name={categoryOne.name}
              total={sum([categoryOne.powerOn, categoryOne.powerOff, categoryOne.unknown])}
              count={categoryOne.powerOn}
              unknown={categoryOne.unknown}
            />
            <ProgressBlock
              name={categoryTwo.name}
              total={sum([categoryTwo.powerOn, categoryTwo.powerOff, categoryTwo.unknown])}
              count={categoryTwo.powerOn}
              unknown={categoryTwo.unknown}
            />
            <ProgressBlock
              name={categoryThree.name}
              total={sum([categoryThree.powerOn, categoryThree.powerOff, categoryThree.unknown])}
              count={categoryThree.powerOn}
              unknown={categoryThree.unknown}
            />
          </div>
        ),
        supplyDataMaybe,
      ))}
    </Wrapper>
  </Widget>
);

WidgetSupply.propTypes = {
  devicesDataMaybe: shape().isRequired,
};

WidgetSupply.defaultProps = {
  devicesDataMaybe: undefined,
};

export default connect(
  state => ({
    supplyDataMaybe: selectors.getSupplyWidgetMaybe(state),
    error: selectors.getError(state),
    loadingSupply: selectors.isLoading(state),
  }),
)(WidgetSupply);
