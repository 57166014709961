import * as R from 'ramda';
import { of } from 'rxjs';
import { Actions as FarceActions } from 'farce';
import { addNotification, replaceNotification } from 'common/mdc/notifications/actions';
import {
  TYPE_SUCCESS, TYPE_ERROR, TYPE_PENDING,
} from 'common/mdc/notifications/notification';
import { PublicError } from 'common/service/error';
import { ofType } from 'redux-observable';
import { createErrorStream } from 'common/store/error';
import { catchError, mergeMap, startWith } from 'rxjs/operators';

import {
  PURGE,
} from '@sma/store/device/devices/actions';

const messages = {
  title: {
    id: 'device.actions.delete',
    defaultMessage: 'Device delete',
  },
  pending: {
    id: 'device.actions.delete.pending',
    defaultMessage: 'Device {deviceId} is being deleted',
  },
  success: {
    id: 'device.actions.delete.success',
    defaultMessage: 'Device {deviceId} has been deleted',
  },
  failure: {
    id: 'device.actions.delete.failed',
    defaultMessage: 'Device {deviceId} has not been deleted',
  },
};

const purgeEpiq = (action, state, { deviceService, intl }) => action.pipe(
  ofType(PURGE),
  mergeMap((action$) => {
    const { deviceId } = action$;

    const pendingNotification = addNotification({
      autoHide: false,
      type: TYPE_PENDING,
      title: intl.formatMessage(messages.title),
      text: intl.formatMessage(messages.pending, { deviceId }),
    });

    const failureMessage = intl.formatMessage(
      messages.failure,
      { deviceId }
    );

    return deviceService
      .purge(deviceId)
      .pipe(
        mergeMap(() => of(
          FarceActions.push('/devices'),
          replaceNotification(pendingNotification.notification, {
            type: TYPE_SUCCESS,
            autoHide: true,
            text: intl.formatMessage(messages.success,  { deviceId }),
            actions: [],
          }),
        )),
        startWith(pendingNotification),
        catchError(createErrorStream(action$,
          err => replaceNotification(pendingNotification.notification, {
            type: TYPE_ERROR,
            autoHide: true,
            text: R.is(PublicError, err) && err.message
              ? err.message
              : failureMessage,
            actions: [],
          }))),
      );
  }),
);

export default purgeEpiq;
