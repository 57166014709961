import React, { Fragment } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { string,shape } from 'prop-types';
import { Widget } from 'common/mdc/card';
import S from 'common/service/sanctuary';
import { selectors } from 'common/store/server/version';
import { connect } from 'react-redux';
import { DisplayField } from 'common/mdc/form';

const messages = defineMessages({
servers: {
    id: 'widgets.server.servers',
    defaultMessage: 'Servers',
  },
   serverVersion: {
    id: 'widgets.server.version',
    defaultMessage: 'Server version',
  },
  
});

const WidgetServers = ({
  serversMaybe,
  version,
  loading,
  intl,
}) => (
<Widget
    loading={loading}
    title={intl.formatMessage(messages.servers)}
    subtitle={S.maybeToNullable(S.map(S.prop('length'), serversMaybe))}
    className="widgets-servers widgets-card"
  >
    <DisplayField
      label={intl.formatMessage(messages.serverVersion)}
      style={{ width: '100%' }}
    >
      {version}
    </DisplayField>
  </Widget>


);

WidgetServers.propTypes = {
  serversContext: shape().isRequired,
};

WidgetServers.defaultProps = {
};

export default connect(
  state => ({
    serversMaybe: selectors.getServersWidgetMaybe(state),
    loading: selectors.isLoading(state),
    version: state.server.version.version,
  }),
)(injectIntl(WidgetServers));
