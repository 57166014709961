import { createSelector } from 'reselect';
import { isLoading as loading } from 'common/store/loading/selectors';
import { selectors } from 'common/store/resource';

const getScope = ({ device: { relayConnectDisconnect } }) => relayConnectDisconnect;

export const getStatusContext = createSelector(
  getScope,
  ({ status, error }) => selectors.getResourceContext({
    data: status,
    error,
  }),
);

export const getStatusMaybe = createSelector(
  getScope,
  ({ status, error }) => selectors.getResourceMaybe({
    data: status,
    error,
  }),
);

export const getDeviceId = createSelector(
  getScope,
  ({ deviceId }) => deviceId,
);

export const isOpen = createSelector(
  getScope,
  ({ open }) => open,
);

export const isLoading = createSelector(
  getScope,
  loading,
);
