import React from 'react';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap, takeUntil, startWith } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';
import { of } from 'rxjs';
import { Actions as FarceActions } from 'farce';
import { defineMessages } from 'react-intl';
import { actions as notificatonActions } from 'common/mdc/notifications';
import {
  TYPE_SUCCESS, TYPE_ERROR,
} from 'common/mdc/notifications/notification';
import * as R from 'ramda';
import { PublicError } from 'common/service/error';
import GroupLink from '@sma/components/group/link';

import {
  LOAD,
  CLEAR,
  SAVE,
  loadSuccess,
  saveSuccess,
  loadError,
  editError,
} from './actions';

import {
  actions as nodesActions,
} from '../devices';

const messages = defineMessages({
  saved: {
    id: 'group.saved',
    defaultMessage: 'Group {groupName} successfully updated',
  },
  error: {
    id: 'group.error',
    defaultMessage: 'Group {groupName} could not be updated',
  },
});

const loadGroup = (action, state, { groupService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => groupService
    .getById(action$.groupId)
    .pipe(
      map(loadSuccess),
      takeUntil(action.pipe(ofType(CLEAR))),
      catchError(createErrorStream(action$, loadError)),
      startWith(nodesActions.load(action$.groupId)),
    )),
);

const saveGroup = (action, state, { groupService }) => action.pipe(
  ofType(SAVE),
  switchMap(action$ => groupService
    .save(action$.group)
    .pipe(
      mergeMap(group => of(
        saveSuccess(group),
        FarceActions.push(`/groups/${group.id}`),
        notificatonActions.addNotification({
          type: TYPE_SUCCESS,
          autoHide: true,
          text: <GroupLink groupId={group.id} groupName={group.name} message={messages.saved} />,
        }),
      )),
      catchError(createErrorStream(
        action$,
        editError,
        err => notificatonActions.addNotification({
          type: TYPE_ERROR,
          autoHide: true,
          text: R.is(PublicError, err) && err.message
            ? err.message
            : (<GroupLink
              groupId={action$.group.id}
              groupName={action$.group.name}
              message={messages.error}
            />),
        }),
      )),
    )),
);

export default combineEpics(
  loadGroup,
  saveGroup,
);
