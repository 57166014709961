import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { ToolbarSection } from 'common/mdc/toolbar';
import Input from 'common/mdc/textfield';
import { defineMessages, FormattedMessage } from 'react-intl';
import DatetimeRange from 'common/mdc/form/datetimeRange';

import {
  actions, selectors,
} from '@sma/store/device/events';

const messages = defineMessages({
  registers: {
    id: 'device.events.registers',
    defaultMessage: 'Registers',
  },
  meteringProfile: {
    id: 'device.events.meteringProfile',
    defaultMessage: 'Metering profile: ',
  },
  filter: {
    id: 'device.events.filter',
    defaultMessage: 'Displaying period',
  },
});

class EventsToolbar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.now = new Date();
  }

  render() {
    return (
      <ToolbarSection
        key="filter"
        className="main-toolbar-section--filter"
        align={ToolbarSection.ALIGN_END}
      >
        <div className="mdc-toolbar__text">
          <FormattedMessage {...messages.filter} />
        </div>
        <DatetimeRange
          from={this.props.from}
          to={this.props.to}
          max={this.now}
          onChange={this.props.load}
        />
      </ToolbarSection>
    );
  }
}

export default R.compose(
  connect(
    state => ({
      from: selectors.getFrom(state),
      to: selectors.getTo(state),
    }),
    ({
      load: actions.load,
    }),
  ),
)(EventsToolbar);
