import * as R from 'ramda';
import S from 'common/service/sanctuary';
import { of } from 'rxjs';
import { Actions as FarceActions } from 'farce';
import { ofType } from 'redux-observable';
import { startWith, catchError, mergeMap } from 'rxjs/operators';
import { addNotification, replaceNotification } from 'common/mdc/notifications/actions';
import {
  TYPE_SUCCESS, TYPE_ERROR, TYPE_PENDING,
} from 'common/mdc/notifications/notification';
import { PublicError } from 'common/service/error';
import { createErrorStream } from 'common/store/error';

import {
  REMOVE_GROUP,
  clearSelected,
} from '../groups/actions';

import {
  actions,
  selectors,
} from '../group';

import {
  getSelected,
} from '../groups/selectors';

const messages = {
  title: {
    id: 'group.actions.remove',
    defaultMessage: 'Group remove',
  },
  pending: {
    id: 'group.actions.remove.pending',
    defaultMessage: 'Group {groupName} is being removed',
  },
  success: {
    id: 'group.actions.remove.success',
    defaultMessage: 'Group {groupName} has been removed',
  },
  failure: {
    id: 'group.actions.remove.failed',
    defaultMessage: 'Group {groupName} has not been removed',
  },
};

const removeEpiq = (action, state, { groupService, intl }) => action.pipe(
  ofType(REMOVE_GROUP),
  mergeMap((action$) => {
    const { group: { id, name } } = action$;

    const pendingNotification = addNotification({
      autoHide: false,
      type: TYPE_PENDING,
      title: intl.formatMessage(messages.title),
      text: intl.formatMessage(messages.pending, { groupName: name }),
    });

    const failureMessage = intl.formatMessage(
      messages.failure,
      { groupName: name },
    );

    return groupService
      .remove(id)
      .pipe(
        mergeMap(() => {
          const selected = S.maybeToNullable(getSelected(state.value));
          const groupId = S.maybeToNullable(selectors.getGroupId(state.value));
          return groupId === id && selected.id === id
            ? of(
              FarceActions.push('/groups'),
              replaceNotification(pendingNotification.notification, {
                type: TYPE_SUCCESS,
                autoHide: true,
                text: intl.formatMessage(messages.success, { groupName: name }),
                actions: [],
              }),
              actions.clear(),
              clearSelected(),
            )
            : of(
              FarceActions.push('/groups'),
              replaceNotification(pendingNotification.notification, {
                type: TYPE_SUCCESS,
                autoHide: true,
                text: intl.formatMessage(messages.success, { groupName: name }),
                actions: [],
              }),
            );
        }),
        startWith(pendingNotification),
        catchError(createErrorStream(action$,
          err => replaceNotification(pendingNotification.notification, {
            type: TYPE_ERROR,
            autoHide: true,
            text: R.is(PublicError, err) && err.message
              ? err.message
              : failureMessage,
            actions: [],
          }))),
      );
  }),
);

export default removeEpiq;
