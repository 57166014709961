import React from 'react';
import { Link } from 'found';

class Error extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      
    };
  }

  render() {
    console.log("dasdas",window.URL)
    return (
      <div style={{marginLeft: "1em"}}>
        <h1>HTTP ERROR 404</h1>
        <p>Problem accessing /{window.location.href.split("/").pop()}</p>
        <p>Reason: Not Found.</p>
        <Link to={`/`}>
            Return Home
        </Link>
      </div>
    );
  }
}

export default Error;
