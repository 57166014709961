export const LOAD_STATUS = 'DEVICE/RELAY_CONNECT_DISCONNECT/LOAD';
export const LOADED_STATUS = 'DEVICE/RELAY_CONNECT_DISCONNECT/LOADED';
export const CANCELLED_STATUS = 'DEVICE/RELAY_CONNECT_DISCONNECT/CANCELLED';
export const ERROR_STATUS = 'DEVICE/RELAY_CONNECT_DISCONNECT/ERROR';
export const UPDATE_STATUS = 'DEVICE/RELAY_CONNECT_DISCONNECT/UPDATE_STATUS';

export const updateConnectionStatus = ({ deviceId, status }) => ({
  type: UPDATE_STATUS,
  deviceId,
  status,
});

export const loadStatus = deviceId => ({
  type: LOAD_STATUS,
  deviceId,
});

export const loadedStatus = ({ state }) => ({
  type: LOADED_STATUS,
  status: state,
});

export const cancelledStatus = () => ({
  type: CANCELLED_STATUS,
});

export const errorStatus = err => ({
  type: ERROR_STATUS,
  error: err,
});
