import {
  collection,
} from 'common/store/selectors';
import { createSelector } from 'reselect';

const getScope = ({ device: { alarms } }) => alarms;
const collectionFactory = collection.factory(getScope);

export const {
  isSorted,
  getPaginator,
  getTotal,
  isLoading,
  getQuery,
} = collectionFactory;

export const getStoreParams = createSelector(
  collectionFactory.getStoreParams,
  state => getScope(state),
  (params, { deviceId, from, to }) => ({
    ...params,
    deviceId,
    from: from.toISOString(),
    to: to.toISOString(),
  }),
);

export const getFrom = createSelector(
  getScope,
  ({ from }) => from,
);

export const getTo = createSelector(
  getScope,
  ({ to }) => to,
);

export const getDeviceId = createSelector(
    getScope,
    ({ deviceId }) => deviceId,
);

export const getAlarmsContext = collectionFactory.getCollectionContext;
export const getAlarmsMaybe = collectionFactory.getCollectionMaybe;
