import React from 'react';
import classnames from 'classnames';
import { oneOf } from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Icon from 'common/mdc/icon';

export const STATUS_CLEARED = 'CLEARED';
export const STATUS_RAISED = 'RAISED';

export const messages = defineMessages({
  [STATUS_CLEARED]: {
    id: 'alarms.status.cleared',
    defaultMessage: 'Cleared',
  },
  [STATUS_RAISED]: {
    id: 'alarms.status.raised',
    defaultMessage: 'Raised',
  },
});

export const icons = {
  [STATUS_CLEARED]: 'check_circle',
  [STATUS_RAISED]: 'warning',
};

const className = {
  [STATUS_CLEARED]: 'checked',
  [STATUS_RAISED]: 'unchecked',
};

const AlarmState = ({ status, intl }) => (
  <span
    title={intl.formatMessage(messages[status])}
    className={classnames(
      'alarms',
      className[status],
    )}
  >
    <Icon>{icons[status]}</Icon>
    <FormattedMessage {...messages[status]} />
  </span>
);

AlarmState.propTypes = {
  status: oneOf([STATUS_CLEARED, STATUS_RAISED]).isRequired,
  intl: intlShape.isRequired,
};
export default injectIntl(AlarmState);
