import React from 'react';
import * as R from 'ramda';
import Form, { DisplayField } from 'common/mdc/form';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { Link } from 'found';
import { NodeLink } from '@sma/components/node';
import DeviceShape from '@sma/components/device/shape';
import AddOn from './addOns';

const messages = defineMessages({
  deviceId: {
    id: 'device.field.deviceId',
    defaultMessage: 'Meter ID',
  },
  nodeId: {
    id: 'device.field.nodeId',
    defaultMessage: 'Node ID',
  },
  vendor: {
    id: 'device.field.vendor',
    defaultMessage: 'Vendor',
  },
  firmware: {
    id: 'device.field.firmware',
    defaultMessage: 'Firmware',
  },
  hardware: {
    id: 'device.field.hardware',
    defaultMessage: 'Hardware',
  },
  driver: {
    id: 'device.field.driver',
    defaultMessage: 'Driver',
  },
  meteringProfiles: {
    id: 'device.field.meteringProfiles',
    defaultMessage: 'Metering profiles',
  },
  registers: {
    id: 'device.field.registers',
    defaultMessage: 'Registers',
  },
  addOns: {
    id: 'device.field.addOns',
    defaultMessage: 'AddOns',
  },
  alarms: {
    id: 'device.field.alarms',
    defaultMessage: 'Alarms',
  },
});

const DetailsForm = ({ device, displayDeviceLink, intl }) => (
  <div className="device-details-pane">
    <Form style={{ padding: 0 }}>
      <DisplayField label={intl.formatMessage(messages.deviceId)}>
        {displayDeviceLink ?
          <Link to={`/devices/${encodeURI(device.deviceId)}`}>
            {device.deviceId}
          </Link> :
          device.deviceId
        }
      </DisplayField>
      <DisplayField label={intl.formatMessage(messages.nodeId)}>
        {device.nodeId && (
          <NodeLink nodeId={device.nodeId} terminalReferenceId={device.terminalReferenceId} >
            {device.nodeId}
          </NodeLink>
        )}
      </DisplayField>
      <DisplayField label={intl.formatMessage(messages.vendor)}>
        {device.vendor}
      </DisplayField>
      <DisplayField label={intl.formatMessage(messages.firmware)}>
        {device.firmwareVersion}
      </DisplayField>
      <DisplayField label={intl.formatMessage(messages.hardware)}>
        {device.hardwareVersion}
      </DisplayField>
      <DisplayField label={intl.formatMessage(messages.driver)}>
        {device.driver}
      </DisplayField>
      {device.alarms && (
        <DisplayField label={intl.formatMessage(messages.alarms)} style={{ alignItems: 'start' }}>
          {device.alarms.length}
        </DisplayField>
      )}
      <DisplayField label={intl.formatMessage(messages.meteringProfiles)}>
        {device.meteringProfiles}
      </DisplayField>
      <DisplayField label={intl.formatMessage(messages.registers)}>
        {device.registers}
      </DisplayField>
      <DisplayField label={intl.formatMessage(messages.addOns)} style={{ alignItems: 'start' }}>
        {device.addOns && !R.isEmpty(device.addOns) && (
          <AddOn addOns={device.addOns} />)}
      </DisplayField>
    </Form>
  </div>
);

DetailsForm.propTypes = {
  device: DeviceShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(DetailsForm);
