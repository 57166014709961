import React from 'react';
import Dialog from 'common/mdc/dialog';
import { arrayOf, node, number } from 'prop-types';
import { defineMessages, injectIntl, intlShape, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  saveChangesTitle: {
    id: 'group.edit.static.dialogs.save-changes-title',
    defaultMessage: 'Save group',
  },
  addedDevices: {
    id: 'group.edit.static.dialogs.confirm-save',
    defaultMessage: '{count, number} {count, plural, one {meter} other {meters}} will be added to group',
  },
  removedDevices: {
    id: 'group.edit.static.dialogs.confirm-remove',
    defaultMessage: '{count, number} {count, plural, one {meter} other {meters}} will be removed',
  },
});

const SavingDialog = ({
  buttons,
  addedCount,
  removedCount,
  intl,
}) => (
  <Dialog
    title={intl.formatMessage(messages.saveChangesTitle)}
    buttons={buttons}
  >
    <div>
      <FormattedMessage {...messages.addedDevices} values={{ count: addedCount }} />
      <br />
      <FormattedMessage {...messages.removedDevices} values={{ count: removedCount }} />
    </div>
  </Dialog>
);

SavingDialog.propTypes = {
  buttons: arrayOf(node),
  intl: intlShape.isRequired,
  addedCount: number,
  removedCount: number,
};

SavingDialog.defaultProps = {
  buttons: [],
  addedCount: 0,
  removedCount: 0,
};

export default injectIntl(SavingDialog);
