import { collection } from 'common/store/actions';

export const NAMESPACE = 'WIDGETS/SUPPLY';
const collectionActions = collection.factory(NAMESPACE);

export const SET_VALUE = `${NAMESPACE}/SET_VALUE`;
export const RESET = `${NAMESPACE}/RESET`;

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
} = collectionActions.TYPES;

export const {
  load,
  loadSuccess,
  loadError,
} = collectionActions;
