import {
  resource,
  composeReducers,
} from 'common/store/reducer';

import {
  NAMESPACE,
  LOAD,
} from './actions';

const initial = {
  deviceId: null,
  meteringProfileId: null,
};

const setDeviceId = (state, { deviceId }) => ({
  ...state,
  deviceId,
});

const setMeteringProfileId = (state, { meteringProfileId }) => ({
  ...state,
  meteringProfileId,
});

export default composeReducers(
  resource.factory(NAMESPACE),
  (state = initial, action) => {
    const reducer = {
      [LOAD]: composeReducers(setDeviceId, setMeteringProfileId),
    }[action.type];

    return reducer ? reducer(state, action) : state;
  },
);
