import React from 'react';
import S from 'common/service/sanctuary';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { func, bool, number } from 'prop-types';
import {
  actions as groupDevicesActions,
  selectors as groupDevicesSelectors,
} from '@sma/store/group/devices';
import { selectors as groupSelectors } from '@sma/store/group/group';
import DeviceTable from '@sma/components/device/table';
import { CollectionWrapper } from 'common/mdc/wrappers';
import Pagination from 'common/mdc/table/pagination';

export const messages = defineMessages({
  devicesSummary: {
    id: 'group.internal.devices.pagination.summary',
    defaultMessage: '{count, number} {count, plural, one {device} other {devices}} of {total} displayed',
  },
  noDevices: {
    id: 'group.internal.devices.noDevices',
    defaultMessage: 'No devices found',
  },
  devicesLoading: {
    id: 'group.internal.devices.loading',
    defaultMessage: 'Loading devices',
  },
  noDevicesConfigured: {
    id: 'group.internal.devices.noDevicesConfigured',
    defaultMessage: 'No devices has been configured in system yet',
  },
  noDevicesMatches: {
    id: 'group.internal.devices.noDevicesMatches',
    defaultMessage: 'No devices due to filters applied',
  },
  devicesPerPage: {
    id: 'group.internal.table.pagination.devicesPerPage',
    defaultMessage: 'Devices per page',
  },
});

const GroupDevicesCollection = ({
  loading,
  groupDevicesContext,
  setSelected,
  clearSelected,
  isSorted,
  onSortChange,
  onPaginationChange,
  paginator,
  isSelected,
  displayColumns,
  total,
}) => (
  <CollectionWrapper
    loading={loading}
    context={groupDevicesContext}
    pagination={S.maybeToNullable(S.map(
      props => (
        <Pagination
          {...props}
          onChange={onPaginationChange}
          itemsPerPage={messages.devicesPerPage}
        />
      ),
      paginator,
    ))}
    messages={{
      empty: <FormattedMessage {...messages.noDevices} />,
      loading: <FormattedMessage {...messages.devicesLoading} />,
      emptyReasons: [
        <FormattedMessage {...messages.noDevicesConfigured} />,
        <FormattedMessage {...messages.noDevicesMatches} />,
      ],
      summary: count => (
        <FormattedMessage
          values={{ count, total }}
          {...messages.devicesSummary}
        />
      ),
    }}
  >{devices => (
    <DeviceTable
      devices={devices}
      isSelected={isSelected}
      onSelectionChange={device => (isSelected(device) ? clearSelected() : setSelected(device))}
      isSorted={isSorted}
      toggleSort={onSortChange}
      displayColumns={displayColumns}
    />
  )}
  </CollectionWrapper>
);

GroupDevicesCollection.propTypes = {
  isSorted: func.isRequired,
  loading: bool.isRequired,
  setSelected: func.isRequired,
  clearSelected: func.isRequired,
  onSortChange: func.isRequired,
  onPaginationChange: func.isRequired,
  isSelected: func.isRequired,
  total: number.isRequired,
};

export default connect(
  state => ({
    groupDevicesContext: groupDevicesSelectors.getDevicesContext(state),
    loading: groupDevicesSelectors.isLoading(state) || groupSelectors.isLoading(state),
    isSorted: groupDevicesSelectors.isSorted(state),
    isSelected: groupDevicesSelectors.isSelected(state),
    total: S.maybeToNullable(groupDevicesSelectors.getTotal(state)),
    paginator: groupDevicesSelectors.getPaginator(state),
  }),
  {
    setSelected: groupDevicesActions.setSelected,
    clearSelected: groupDevicesActions.clearSelected,
    onSortChange: groupDevicesActions.toggleSort,
    onPaginationChange: groupDevicesActions.paginationChange,
  },
)(GroupDevicesCollection);
