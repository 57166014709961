import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import Menu, { MenuItem } from 'common/mdc/menu';
import { logout } from 'common/store/auth/actions';
import { Actions as FarceActions } from 'farce';

const messages = defineMessages({
  logout: {
    id: 'header.profile.logout',
    defaultMessage: 'Logout',
  },
});

const ProfileMenu = ({ logout, push, ...props }) => (
  <Menu {...props}>
    <MenuItem key="logout" onClick={() => { logout(); push('/'); }} >
      <FormattedMessage {...messages.logout} />
    </MenuItem>
  </Menu>
);

ProfileMenu.propTypes = {
  logout: func.isRequired,
};

export default connect(
  null,
  {
    logout,
    push: FarceActions.push,
  },
)(ProfileMenu);
