import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Link } from 'found';
import { string, shape, oneOf, number } from 'prop-types';
import { ProgressDeterminate } from 'common/mdc/progress';
import Icon from 'common/mdc/icon';
import { messages as statusMessages, icons, STATUS_ONLINE, STATUS_OFFLINE, STATUS_CONNECTED, STATUS_DISCONNECTED, STATUS_UNKNOWN } from '@sma/components/device/search/status';
import { iconFlag } from '@sma/components/device/header';
import { EQUALS } from 'common/store/search/advanced/constraint/operators';
import { CONSUMER_STATUS } from '@sma/components/device/search/advanced';
import { Widget } from 'common/mdc/card';
import S from 'common/service/sanctuary';
import { sum } from 'ramda';
import { GridCell } from 'common/mdc/grid';
import { selectors } from '@sma/store/widgets/consumer';
import Wrapper from 'common/mdc/wrappers/wrapper';
import { connect } from 'react-redux';
import { sumOfMeters } from '../sumOfMeters';

const messages = defineMessages({
  displayName: {
    id: 'widget.consumer.name',
    defaultMessage: '{name}',
  },
  connectedInPercentage: {
    id: 'widget.consumer.connectedInPercentage',
    defaultMessage: '{progress}% connected',
  },
  unknownInPercentage: {
    id: 'widget.consumer.unknownInPercentage',
    defaultMessage: '{progress}% unknown',
  },
  consumerLoadError: {
    id: 'widgets.consumer.loadError',
    defaultMessage: 'There was an error while loading the statistics',
  },
  consumerNoDataLoaded: {
    id: 'widgets.consumer.notLoaded',
    defaultMessage: 'Status statistics are not loaded',
  },
  consumerLoadingData: {
    id: 'widgets.consumer.loadingData',
    defaultMessage: 'Loading status statistics',
  },
  consumer: {
    id: 'widgets.consumer.consumer',
    defaultMessage: 'Meter Consumer Status',
  },
});

const progress = ({ total, count }) =>
  total && Math.round((count / total) * 100);

const ProgressBlock = ({ name, total, count, unknown }) => (
  <div className="progress-bar">
    <div className="left-label">
      <FormattedMessage
        values={{
          name: name,
        }}
        {...messages.displayName}
      />
      {/**<Link
        className="count"
        to={{
          pathname: '/devices',
          params: {
            initialCriteria: [],
          },
        }}
      >*/}&nbsp;({total})
      {/**</Link>*/}
    </div>
    <div className="right-label">
      <FormattedMessage
        values={{
          progress: progress({ total, count }),
        }}
        {...messages.connectedInPercentage}
      />{" "}
      <FormattedMessage
        values={{
          progress: progress({ total, count: unknown }),
        }}
        {...messages.unknownInPercentage}
      />
    </div>
    <ProgressDeterminate progress={progress({ total, count })} />
  </div>
);

ProgressBlock.propTypes = {
  name: string.isRequired,
  total: number.isRequired,
  count: number.isRequired,
};

const WidgetConsumer = ({ consumerDataMaybe, loadingConsumer, error }) => (
  S.maybeToNullable(S.map(
    ({ data }) => (
      sum([
        sumOfMeters(data, "connected"),
        sumOfMeters(data, "disconnected"),
        sumOfMeters(data, "unknown")
      ])
    ),
    consumerDataMaybe,
  )) === 0 ? null :
    <GridCell>
      <Widget
        loading={loadingConsumer}
        title={<FormattedMessage {...messages.consumer} />}
        subtitle={
          S.maybeToNullable(S.map(
            ({ data }) => (
              <Link to={{
                pathname: '/devices',
                params: {
                  initialCriteria: [],
                },
              }}
              >{sum([
                sumOfMeters(data, "connected"),
                sumOfMeters(data, "disconnected"),
                sumOfMeters(data, "unknown")
              ])}
              </Link>
            ),
            consumerDataMaybe,
          ))
        }
      >
        <Wrapper
          empty={consumerDataMaybe.isNothing}
          loading={loadingConsumer}
          error={error}
          errorMessage={<FormattedMessage {...messages.consumerLoadError} />}
          notFoundMessage={<FormattedMessage {...messages.consumerNoDataLoaded} />}
          loadingMessage={<FormattedMessage {...messages.consumerLoadingData} />}
        >
          {S.maybeToNullable(S.map(
            ({ data }) => (
              <div className="widget-consumer">
                <div className="consumer-devices">
                  <div className="connected-devices">
                    <Icon>{icons[STATUS_ONLINE]}</Icon>
                    <Link
                      className="count"
                      to={{
                        pathname: '/devices',
                        params: {
                          initialCriteria: [ {
                            property: CONSUMER_STATUS,
                            constraints: [
                              {
                                operator: EQUALS,
                                value: STATUS_CONNECTED,
                              },
                            ],
                          },],
                        },
                      }}
                    >
                      {`${sumOfMeters(data, "connected")} `}
                      <FormattedMessage {...statusMessages[STATUS_CONNECTED]} />
                    </Link>
                  </div>
                  <div className="disconnected-devices">
                    <Icon>{icons[STATUS_OFFLINE]}</Icon>
                    <Link
                      className="count"
                      to={{
                        pathname: '/devices',
                        params: {
                          initialCriteria: [ {
                            property: CONSUMER_STATUS,
                            constraints: [
                              {
                                operator: EQUALS,
                                value: STATUS_DISCONNECTED,
                              },
                            ],
                          },],
                        },
                      }}
                    >
                      {`${sumOfMeters(data, "disconnected")} `}
                      <FormattedMessage {...statusMessages[STATUS_DISCONNECTED]} />
                    </Link>
                  </div>
                  <div className="unknown-devices">
                    <Icon>{icons[STATUS_UNKNOWN]}</Icon>
                    <Link
                      className="count"
                      to={{
                        pathname: '/devices',
                        params: {
                          initialCriteria: [ {
                            property: CONSUMER_STATUS,
                            constraints: [
                              {
                                operator: EQUALS,
                                value: STATUS_UNKNOWN,
                              },
                            ],
                          },],
                        },
                      }}
                    >
                      {`${sumOfMeters(data, "unknown")} `}
                      <FormattedMessage {...statusMessages[STATUS_UNKNOWN]} />
                    </Link>
                  </div>
                </div>
                {
                  data &&
                  data.map((category) => (
                    sum([category.connected, category.disconnected, category.unknown]) > 0 &&
                    <ProgressBlock
                      name={category.name}
                      total={sum([category.connected, category.disconnected, category.unknown])}
                      count={category.connected}
                      unknown={category.unknown}
                    />
                  ))
                }
              </div>
            ),
            consumerDataMaybe,
          ))}
        </Wrapper>
      </Widget>
    </GridCell>
);

WidgetConsumer.propTypes = {
  consumerDataMaybe: shape().isRequired,
};

WidgetConsumer.defaultProps = {
  consumerDataMaybe: undefined,
};

export default connect(
  state => ({
    consumerDataMaybe: selectors.getConsumerWidgetMaybe(state),
    error: selectors.getError(state),
    loadingConsumer: selectors.isLoading(state),
  }),
)(WidgetConsumer);
