import React from 'react';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { isNil } from 'ramda';
// import { Link } from 'found';
import Form, { DisplayField } from 'common/mdc/form';
import DateTime from 'common/components/dateTime';
import SamplingPeriod from '@sma/components/meteringProfile/capturePeriod';
import Features from '@sma/components/meteringProfile/features';
import meteringProfileShape from './shape';

const messages = defineMessages({
  name: {
    id: 'meteringProfile.field.name',
    defaultMessage: 'Name',
  },
  obisCode: {
    id: 'meteringProfile.field.obisCode',
    defaultMessage: 'OBIS code',
  },
  capturePeriod: {
    id: 'meteringProfile.field.capturePeriod',
    defaultMessage: 'Capture period',
  },
  registers: {
    id: 'meteringProfile.field.registers',
    defaultMessage: 'Registers',
  },
  lastReading: {
    id: 'meteringProfile.field.lastReading',
    defaultMessage: 'Last reading',
  },
  viewSamples: {
    id: 'meteringProfile.field.viewSamples',
    defaultMessage: 'View samples',
  },
  features: {
    id: 'meteringProfile.field.features',
    defaultMessage: 'Features',
  },
});

const DetailsForm = ({ meteringProfile, intl }) => (
  <Form style={{ padding: 0 }}>
    <DisplayField label={intl.formatMessage(messages.name)}>
      {/* <Link to={'/devices/'}> */}
      {meteringProfile.name}
      {/* </Link> */}
    </DisplayField>
    <DisplayField label={intl.formatMessage(messages.obisCode)}>
      {meteringProfile.profileObisCode}
    </DisplayField>
    {!isNil(meteringProfile.capturePeriod) && (
      <DisplayField label={intl.formatMessage(messages.capturePeriod)}>
        <SamplingPeriod period={meteringProfile.capturePeriod} />
      </DisplayField>
    )}
    {meteringProfile.registers &&
    <DisplayField label={intl.formatMessage(messages.registers)}>
      {meteringProfile.registers && meteringProfile.registers.length}
    </DisplayField>}
    {meteringProfile.lastReading &&
    <DisplayField label={intl.formatMessage(messages.lastReading)}>
      <DateTime value={meteringProfile.lastReading} />
    </DisplayField>}
    <DisplayField label={intl.formatMessage(messages.features)} style={{ alignItems: 'start' }}>
      <Features features={meteringProfile.features} />
    </DisplayField>
    {/* <Link to={'/devices/'} style={{ paddingLeft: '4px' }}>
      {intl.formatMessage(messages.viewSamples)}
    </Link> */}
  </Form>
);

DetailsForm.propTypes = {
  meteringProfile: meteringProfileShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(DetailsForm);
