import React from 'react';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { arrayOf, string } from 'prop-types';

export const METER_DISCOVERED = 'METER_DISCOVERED';
export const METER_CHANGED = 'METER_CHANGED';
export const METER_CONNECTED = 'METER_CONNECTED';
export const METER_DISCONNECTED = 'METER_DISCONNECTED';
export const METER_PURGED = 'METER_PURGED';

export const messages = defineMessages({
  [METER_DISCOVERED]: {
    id: 'device.events.type.meter_discovered',
    defaultMessage: 'Meter discovered',
  },
  [METER_CHANGED]: {
    id: 'device.events.type.meter_changed',
    defaultMessage: 'Meter changed',
  },
  [METER_CONNECTED]: {
    id: 'device.events.type.meter_connected',
    defaultMessage: 'Meter connected',
  },
  [METER_DISCONNECTED]: {
    id: 'device.events.type.meter_disconnected',
    defaultMessage: 'Meter disconnected',
  },
  [METER_PURGED]: {
    id: 'device.events.type.meter_purged',
    defaultMessage: 'Meter purged',
  },
});


const EventType = ({ type, intl }) => (
  <span title={messages[type] && intl.formatMessage(messages[type])}>
    {messages[type] ? <FormattedMessage {...messages[type]} /> : type}
  </span>
);

export const EventsTypesList = injectIntl(({ events, intl }) => (
  events.map(type => (messages[type] ?
    intl.formatMessage(messages[type]) :
    type
  )).join(', ')
));

EventsTypesList.propTypes = {
  events: arrayOf(string).isRequired,
};

EventType.propTypes = {
  type: string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EventType);
