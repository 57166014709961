import S from 'common/service/sanctuary';
import { compose, without, prop } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { defineMessages, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { CollectionWrapper } from 'common/mdc/wrappers';
import Button from 'common/mdc/button';
import { StepperContent } from 'common/mdc/stepper';
import { SwitchField } from 'common/mdc/form';
import { ToolbarSection } from 'common/mdc/toolbar';
import { Pagination } from 'common/mdc/table';
import withMultiSelection from 'common/mdc/table/multiSelection';

import {
  selectors,
  actions,
} from '@sma/store/device/devices';

import {
  selectors as searchSelectors,
  actions as searchActions,
} from '@sma/store/device/search';

import {
  TYPE_DYNAMIC,
  TYPE_STATIC,
} from '@sma/components/group/groupType';

import DeviceTable, {
  COLUMN_ACTIONS,
  DeviceSelectionTable,
  columns,
} from '@sma/components/device/table';

import { Advanced } from '@sma/components/device/search';

export const STEP_ID = Symbol('refine-selection');

const messages = defineMessages({
  title: {
    id: 'groupCreate.title.step2',
    defaultMessage: 'Specify devices',
  },
  continue: {
    id: 'groupCreate.actions.continue',
    defaultMessage: 'Continue',
  },
  noDevicesSelected: {
    id: 'groupCreate.noDevicesSelected',
    defaultMessage: 'Please, select at least one device',
  },
  noCriteria: {
    id: 'groupCreate.noCriteria',
    defaultMessage: 'Please, specify search criteria',
  },
  selectDevices: {
    id: 'groupCreate.selectDevices',
    defaultMessage: 'Select specific meters',
  },
  createStatic: {
    id: 'groupCreate.createStatic',
    defaultMessage: 'Create static group',
  },
  createDynamic: {
    id: 'groupCreate.createDynamic',
    defaultMessage: 'Create dynamic group',
  },
  summary: {
    id: 'groupCreate.summary',
    defaultMessage: '{count, number} {count, plural, one {meter} other {meters}} of {total} displayed',
  },
  selected: {
    id: 'groupCreate.selected',
    defaultMessage: '{count} {count, plural, one {meter} other {meters}} selected',
  },
  devicesPerPage: {
    id: 'groupCreate.devicesPerPage',
    defaultMessage: 'Meters per page',
  },
});

export const title = <FormattedMessage {...messages.title} />;

const displayColumns = without([
  COLUMN_ACTIONS,
], columns);

class Step1 extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      type: S.maybe(
        TYPE_STATIC,
        prop('type'),
        props.stepper.getData(STEP_ID),
      ),
    };

    this.proceed = this.proceed.bind(this);
    this.toggleType = this.toggleType.bind(this);
    this.isStatic = this.isStatic.bind(this);
  }

  componentWillMount() {
    const { load, loadCriteria, stepper, initialCriteria } = this.props;

    loadCriteria(S.fromMaybe(
      initialCriteria || [],
      S.chain(
        S.get(S.is(Array), 'criteria'),
        stepper.getData(STEP_ID),
      ),
    ));

    load({ query: null });
  }

  componentWillReceiveProps({ criteria }) {
    const { stepper } = this.props;

    if (criteria.length && stepper.hasError(STEP_ID)) {
      stepper.cleanError();
    }
  }

  toggleType() {
    this.props.stepper.cleanError();

    this.setState(({ type }) => ({
      type: type === TYPE_DYNAMIC ? TYPE_STATIC : TYPE_DYNAMIC,
    }));
  }

  isStatic() {
    return this.state.type === TYPE_STATIC;
  }

  proceed() {
    const { type } = this.state;
    const { multiSelection, stepper, criteria } = this.props;

    if (this.isStatic()) {
      const selection = multiSelection.getSelection();

      if (!selection.length) {
        return stepper.reject(
          this.props.intl.formatMessage(messages.noDevicesSelected),
        );
      }

      return stepper.resolve({
        selection,
        criteria,
        type,
        displaySelected: multiSelection.isDisplaySelected(),
      });
    }

    if (!criteria.length) {
      return stepper.reject(
        this.props.intl.formatMessage(messages.noCriteria),
      );
    }

    return stepper.resolve({
      criteria,
      type,
      displaySelected: multiSelection.isDisplaySelected(),
    });
  }

  render() {
    const {
      devicesContext,
      sort,
      loading,
      toggleSort,
      onPaginationChange,
      isSorted,
      multiSelection,
      stepper,
      cancelAction,
      total,
      paginator,
    } = this.props;

    const context = multiSelection.isDisplaySelected()
      ? S.Just(S.Right(sort(multiSelection.getSelection())))
      : devicesContext;

    return (
      <StepperContent
        loading={loading}
        onSubmit={this.proceed}
        title={title}
        actions={[
          cancelAction,
          <Button raised type="submit">
            <FormattedMessage {...messages.continue} />
          </Button>,
        ]}
      >
        <Advanced />
        <CollectionWrapper
          loading={loading}
          context={context}
          tools={[
            items => (
              <ToolbarSection key="before" align={ToolbarSection.ALIGN_START} >
                {/* this is part of dynamic group search, will be enabled in future */}
                {/* <SwitchField
                className="select-specific-nodes"
                id="select-specific-nodes"
                label={<FormattedMessage {...messages.selectDevices} />}
                labelPosition={SwitchField.LABEL_BEFORE}
                  checked={this.isStatic()}
                  disabled={items.length===0}
                  onChange={this.toggleType}
                /> */}
                {this.isStatic() && (
                  <FormattedMessage
                    values={{ count: multiSelection.getSelection().length }}
                    {...messages.selected}
                  />
                )}
              </ToolbarSection>
            ),
            <ToolbarSection key="after" align={ToolbarSection.ALIGN_END} shrink>
              {this.isStatic() && multiSelection.getFilter()}
            </ToolbarSection>,
          ]}
          messages={{
            summary: count => (<FormattedMessage
              values={{ count, total }}
              {...messages.summary}
            />),
          }}
          pagination={S.maybeToNullable(S.map(props =>
            !(this.isStatic() && multiSelection.isDisplaySelected())
            && (
              <Pagination
                {...props}
                onChange={onPaginationChange}
                itemsPerPage={messages.devicesPerPage}
              />
          ), paginator))}
        >
          {devices => (this.isStatic()
            ? <DeviceSelectionTable
              devices={devices}
              toggleSort={toggleSort}
              isSorted={isSorted}
              isAllSelected={multiSelection.isAllSelected(devices)}
              isSelected={multiSelection.selected}
              onSelect={S.compose(stepper.cleanError, multiSelection.handleItemSelect)}
              onSelectionChange={S.compose(
                stepper.cleanError,
                multiSelection.handleSelectAll(devices),
              )}
            />
            : <DeviceTable
              displayColumns={displayColumns}
              devices={devices}
              isSorted={isSorted}
              toggleSort={toggleSort}
            />
          )}
        </CollectionWrapper>
      </StepperContent>
    );
  }
}

Step1.propTypes = {
  connect: func.isRequired,
  load: func.isRequired,
  toggleSort: func.isRequired,
  isSorted: func.isRequired,
  intl: intlShape.isRequired,
};

export default compose(
  connect(
    state => ({
      devicesContext: selectors.getDevicesContext(state),
      devicesMaybe: selectors.getDevicesMaybe(state),
      sort: selectors.getSorter(state),
      loading: selectors.isLoading(state),
      isSorted: selectors.isSorted(state),
      paginator: selectors.getPaginator(state),
      total: S.fromMaybe(0, selectors.getTotal(state)),
      criteria: searchSelectors.getCriteria(state),
    }),
    {
      load: actions.load,
      loadCriteria: searchActions.loadCriteria,
      toggleSort: actions.toggleSort,
      onPaginationChange: actions.paginationChange,
    },
  ),
  injectIntl,
  withMultiSelection(({ stepper }) => ({
    initialSelection: S.fromMaybe([], S.chain(
      S.get(S.is(Array), 'selection'),
      stepper.getData(STEP_ID),
    )),
    intitialDisplaySelected: S.maybe(false, prop('displaySelected'), stepper.getData(STEP_ID)),
    mapItemToSelection: S.I,
  })),
)(Step1);
