import {
  collection,
  selection,
  composeReducers,
} from 'common/store/reducer';
import { SORT_ASC } from 'common/store/collection/reducer';

import {
  SORT_NAME,
} from '@sma/service/api/meteringProfile';

import {
  NAMESPACE,
  TOGGLE_SORT,
  LOAD_SUCCESS,
} from './actions';

const initial = {
  ...collection.defaultSort(SORT_NAME, SORT_ASC),
};

export default composeReducers(
  collection.factory(NAMESPACE),
  selection.factory(NAMESPACE),
  (state = initial, action) => {
    const reducer = {
      [TOGGLE_SORT]: collection.sortCaseInsensitive,
      [LOAD_SUCCESS]: composeReducers(collection.loadSuccess, collection.sortCaseInsensitive),
    }[action.type];

    return reducer ? reducer(state, action) : state;
  },
);
