import S from 'common/service/sanctuary';
import React, { Fragment } from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { func, string, bool } from 'prop-types';
import { FormattedMessage, defineMessages, intlShape, injectIntl } from 'react-intl';
import Button from 'common/mdc/button';
import Dialog from 'common/mdc/dialog';
import { ResourceWrapper } from 'common/mdc/wrappers';

import {
  actions as relayActions,
  selectors as relaySelectors,
} from '@sma/store/device/relayConnectDisconnect';

import { DISCONNECTED, CONNECTED_ACTIVE } from '@sma/service/api/device';
import DeviceStatus from './status';

const messages = defineMessages({
  relayConnectDisconnect: {
    id: 'smas.devices.actions.relay-connect-disconnect.on-demand-reading',
    defaultMessage: 'Relay connect/disconnect',
  },
  disconnect: {
    id: 'smas.devices.actions.relay-connect-disconnect.disconnect',
    defaultMessage: 'Disconnect',
  },
  connect: {
    id: 'smas.devices.actions.relay-connect-disconnect.connect',
    defaultMessage: 'Connect',
  },
  cancel: {
    id: 'smas.devices.actions.relay-connect-disconnect.cancel',
    defaultMessage: 'Cancel',
  },
  validateStatus: {
    id: 'smas.devices.actions.relay-connect-disconnect.validateStatus',
    defaultMessage: 'Validate status',
  },
  error: {
    id: 'smas.devices.actions.relay-connect-disconnect.error',
    defaultMessage: 'Error {status}',
  },
  errorBody: {
    id: 'smas.devices.actions.relay-connect-disconnect.errorBody',
    defaultMessage: 'Meter reported error status {status}',
  },
});

class RelayConnectDisconnect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.updateStatus = this.updateStatus.bind(this);
    this.onClose = S.compose(
      props.cancelStatus,
      props.onClose,
    );
  }

  componentDidMount() {
    const { loadStatus, deviceId } = this.props;

    loadStatus(deviceId);
  }

  updateStatus(status) {
    const { deviceId, updateConnectionStatus } = this.props;

    updateConnectionStatus({
      deviceId,
      status,
    });
  }

  render() {
    const {
      deviceId,
      statusContext,
      statusMaybe,
      loadStatus,
      loading,
      intl,
      onClick,
    } = this.props;

    const buttons = (
      <Fragment>
        <Button onClick={this.onClose} className="mdc-dialog__footer__button">
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          raised
          onClick={() => loadStatus(deviceId)}
          className="mdc-dialog__footer__button"
        >
          <FormattedMessage {...messages.validateStatus} />
        </Button>
        {S.maybeToNullable(S.map(
          status => (
            <Button
              raised
              onClick={() => this.updateStatus(
                status === DISCONNECTED
                  ? CONNECTED_ACTIVE
                  : DISCONNECTED,
              )}
              className="mdc-dialog__footer__button"
            >
              <FormattedMessage
                {...status === DISCONNECTED ? messages.connect : messages.disconnect}
              />
            </Button>
          ),
          statusMaybe,
        ))}
      </Fragment>
    );

    return (
      <Dialog
        title={intl.formatMessage(messages.relayConnectDisconnect)}
        onClose={this.onClose}
        buttons={buttons}
        loading={loading}
        onClick={onClick}
      >
        <div className="relay-connect-disconnect-row">
          <ResourceWrapper
            loading={loading}
            context={statusContext}
          >{status => (
            <DeviceStatus status={status} deviceId={deviceId} />
          )}
          </ResourceWrapper>
        </div>
      </Dialog>
    );
  }
}

RelayConnectDisconnect.propTypes = ({
  loadStatus: func.isRequired,
  loading: bool.isRequired,
  deviceId: string.isRequired,
  updateConnectionStatus: func.isRequired,
  intl: intlShape.isRequired,
  onClose: func.isRequired,
  cancelStatus: func.isRequired,
});

export default compose(
  connect(
    state => (
      {
        statusContext: relaySelectors.getStatusContext(state),
        statusMaybe: relaySelectors.getStatusMaybe(state),
        loading: relaySelectors.isLoading(state),
      }
    ),
    ({
      loadStatus: relayActions.loadStatus,
      updateConnectionStatus: relayActions.updateConnectionStatus,
      cancelStatus: relayActions.cancelledStatus,
    }),
  ),
  injectIntl,
)(RelayConnectDisconnect);
