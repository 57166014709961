import { shape, string, number, arrayOf } from 'prop-types';

export const DATETIME = 'datetime';

export default shape({
  name: string.isRequired,
  profileObisCode: string.isRequired,
  capturePeriod: number,
  features: arrayOf(string).isRequired,
  profileHash: string.isRequired,
});
