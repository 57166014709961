import {
  collection,
  composeReducers,
} from 'common/store/reducer';

import {
  LOAD,
  NAMESPACE,
} from './actions';

const initialState = {
  to: null,
  from: null,
};

const loadEvents = (
  state,
  { deviceId = state.deviceId, from = state.from, to = state.to, ...props },
) => ({
  ...collection.load(state, props),
  deviceId,
  from,
  to,
});

export default composeReducers(
  collection.factory(NAMESPACE),
  (state = initialState, action) => {
    const reducer = {
      [LOAD]: loadEvents,
    }[action.type];

    return reducer ? reducer(state, action) : state;
  },
);
