import {
  collection,
  composeReducers,
} from 'common/store/reducer';

import {
  actions as onDemandReadingActions,
} from '@sma/store/device/onDemandReading';

import {
  NAMESPACE,
  LOAD,
} from './actions';

export const load = (state, {
  deviceId = state.deviceId,
  meteringProfileObis = state.meteringProfileObis,
  from = state.from,
  to = state.to,
  ...props
}) => ({
  ...collection.load(state, props),
  deviceId,
  meteringProfileObis,
  from,
  to,
});

const onDemandClose = state => ({ ...state, loading: false });

const onDemandReadingReducer = (state, action) => {
  const reducer = {
    [LOAD]: load,
    [onDemandReadingActions.ON_DEMAND_SAMPLING_CLOSE]: onDemandClose,
  }[action.type];

  return reducer ? reducer(state, action) : state;
};

export default composeReducers(
  collection.factory(NAMESPACE),
  onDemandReadingReducer,
);
