import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { func, shape, arrayOf } from 'prop-types';

import Table, { TableRow, TableCell, TableHead } from 'common/mdc/table';
import ScrollContainer from 'common/mdc/scrollbar/container';
import { COLUMN_ALARM_ID, COLUMN_ALARM_TIME, COLUMN_REGISTERED_TIME } from '@sma/service/api/alarms';
import Datetime from 'common/components/dateTime';
import { toString } from '@sma/components/device/alarms/type';

const messages = defineMessages({
  alarmId: {
    id: 'alarms.table.header.alarmId',
    defaultMessage: 'Alarm ID',
  },
  alarmTime: {
    id: 'alarms.table.header.alarmTime',
    defaultMessage: 'Meter time',
  },
  registeredTime: {
    id: 'alarms.table.header.registeredTime',
    defaultMessage: 'Server time',
  },
  alarms: {
    id: 'alarms.table.header.alarms',
    defaultMessage: 'Alarms',
  },
});

const AlarmHead = ({
  ...props
}) => (
  <TableHead {...props}>
    <TableCell
      columnId={COLUMN_ALARM_ID}
      flex="0"
      style={{
        minWidth: '180px',
      }}
    ><FormattedMessage {...messages.alarmId} />
    </TableCell>
    <TableCell
      columnId={COLUMN_ALARM_TIME}
      flex="0"
      style={{
        minWidth: '180px',
      }}
      alignRight
    ><FormattedMessage {...messages.alarmTime} />
    </TableCell>
    <TableCell
      columnId={COLUMN_REGISTERED_TIME}
      flex="0"
      style={{
        minWidth: '180px',
      }}
      alignRight
    ><FormattedMessage {...messages.registeredTime} />
    </TableCell>
    <TableCell>
      <FormattedMessage {...messages.alarms} />
    </TableCell>
  </TableHead>
);

AlarmHead.propTypes = {
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
};

const AlarmRow = ({ alarm }) => (
  <TableRow key={alarm.id}>
    <TableCell columnId={COLUMN_ALARM_ID}>
      {alarm.id}
    </TableCell>
    <TableCell columnId={COLUMN_ALARM_TIME}>
      {alarm.alarmTime && <Datetime value={alarm.alarmTime} />}
    </TableCell>
    <TableCell columnId={COLUMN_REGISTERED_TIME}>
      {alarm.registeredTime && <Datetime value={alarm.registeredTime} />}
    </TableCell>
    <TableCell>
      {alarm.alarms.map(toString).join(', ')}
    </TableCell>
  </TableRow>
);

AlarmRow.propTypes = {
  alarm: shape().isRequired,
};

const Alarms = ({
  alarms,
  ...props
}) => (
  <ScrollContainer useRef="tableRef" settings={{ suppressScrollY: true }}>
    <Table className="alarm-table"{...props}>
      {AlarmHead({
        ...props,
      })}
      {alarms.map(alarm => (AlarmRow({ alarm })))}
    </Table>
  </ScrollContainer>
);

Alarms.propTypes = {
  alarms: arrayOf(shape()).isRequired,
};

export default Alarms;
