import React from 'react';
import Form, { Error, DisplayField } from 'common/mdc/form/index';
import TextField from 'common/mdc/textfield/index';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { TYPE_DYNAMIC } from '@sma/components/group/groupType';
import { shape } from 'prop-types';

const messages = defineMessages({
  groupName: {
    id: 'group.edit.groupName',
    defaultMessage: 'Group name',
  },
  rule: {
    id: 'group.edit.rule',
    defaultMessage: 'Rule',
  },
});

class EditForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getUpdatedGroup = this.getUpdatedGroup.bind(this);
  }

  getUpdatedGroup() {
    return {
      ...this.props.group,
      name: this.nameInput.value,
      rule: this.ruleInput && this.ruleInput.value,
    };
  }

  render() {
    const { style, intl, group, error } = this.props;

    return (
      <Form style={{ marginTop: '16px', ...style }}>
        {error && <Error>{error}</Error>}
        <DisplayField label={intl.formatMessage(messages.groupName)}>
          <TextField
            className="group-name-textfield"
            inputRef={(input) => { this.nameInput = input; }}
            defaultValue={group.name}
            type="text"
            style={{ minWidth: '300px' }}
          />
        </DisplayField>
        {group.type === TYPE_DYNAMIC && (
          <DisplayField label={intl.formatMessage(messages.rule)}>
            <TextField
              fullWidth
              className="group-rule-textfield"
              inputRef={(input) => { this.ruleInput = input; }}
              defaultValue={group.rule}
              type="text"
              style={{ minWidth: '300px' }}
            />
          </DisplayField>
        )}
      </Form>
    );
  }
}

EditForm.propTypes = {
  group: shape().isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(EditForm, {
  withRef: true,
});
