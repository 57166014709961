import { ofType } from 'redux-observable';
import { map, catchError, switchMap } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';
import {
  LOAD,
  loadError,
  loadSuccess,
} from './actions';

const loadConsumer = (action, state, { widgetService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => widgetService
    .getWidgetDeviceConsumerStatusData()
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

export default loadConsumer;
