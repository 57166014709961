import React from 'react';
import classnames from 'classnames';
import { oneOf, bool } from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Icon from 'common/mdc/icon';

export const STATUS_ONLINE = 'ONLINE';
export const STATUS_OFFLINE = 'OFFLINE';
export const STATUS_CONNECTED = 'CONNECTED';
export const STATUS_DISCONNECTED = 'DISCONNECTED';
export const STATUS_LOGIN = 'LOGIN';
export const STATUS_LOGOUT = 'LOGOUT';
export const STATUS_POWERON = 'POWERON';
export const STATUS_POWEROFF = 'POWEROFF';
export const STATUS_UNKNOWN = 'UNKNOWN';

export const messages = defineMessages({
  [STATUS_ONLINE]: {
    id: 'device.status.online',
    defaultMessage: 'Online',
  },
  [STATUS_OFFLINE]: {
    id: 'device.status.offline',
    defaultMessage: 'Offline',
  },
  [STATUS_CONNECTED]: {
    id: 'device.consumer.connected',
    defaultMessage: 'Connected',
  },
  [STATUS_DISCONNECTED]: {
    id: 'device.consumer.disconnected',
    defaultMessage: 'Disconnected',
  },
  [STATUS_LOGIN]: {
    id: 'device.network.login',
    defaultMessage: 'Logged In',
  },
  [STATUS_LOGOUT]: {
    id: 'device.network.logout',
    defaultMessage: 'Logged Out',
  },
  [STATUS_POWERON]: {
    id: 'device.supply.poweron',
    defaultMessage: 'Power On',
  },
  [STATUS_POWEROFF]: {
    id: 'device.supply.poweroff',
    defaultMessage: 'Power Off',
  },
  [STATUS_UNKNOWN]: {
    id: 'device.status.unknown',
    defaultMessage: 'Unknown',
  },
});

export const icons = {
  [STATUS_ONLINE]: 'check_circle',
  [STATUS_OFFLINE]: 'remove_circle',
  [STATUS_UNKNOWN]: 'help',
};

const className = {
  [STATUS_ONLINE]: 'online',
  [STATUS_OFFLINE]: 'offline',
  [STATUS_CONNECTED]: 'connected',
  [STATUS_DISCONNECTED]: 'disconnected',
  [STATUS_LOGIN]: 'loggedin',
  [STATUS_LOGOUT]: 'loggedout',
  [STATUS_POWERON]: 'poweron',
  [STATUS_POWEROFF]: 'poweroff',
  [STATUS_UNKNOWN]: 'unknown',
};
