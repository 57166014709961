import React from 'react';
import { arrayOf } from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';

import Table, {
  TableRow,
  TableCell,
  TableHead,
} from 'common/mdc/table';

import registerShape from './shape';

export const COLUMN_NAME = Symbol('name');
export const COLUMN_OBIS = Symbol('obis');
export const COLUMN_IC = Symbol('ic');
export const COLUMN_ATTRIBUTE_ID = Symbol('attributeId');
export const COLUMN_PERIOD = Symbol('period');
export const COLUMN_REGISTERS = Symbol('registers');
export const COLUMN_UNIT = Symbol('unit');
export const COLUMN_SCALER = Symbol('scaler');
export const COLUMN_ACTIONS = Symbol('actions');

export const columns = [
  COLUMN_NAME,
  COLUMN_OBIS,
  COLUMN_IC,
  COLUMN_ATTRIBUTE_ID,
  COLUMN_PERIOD,
  COLUMN_REGISTERS,
  COLUMN_UNIT,
  COLUMN_SCALER,
  COLUMN_ACTIONS,
];

export const messages = defineMessages({
  name: {
    id: 'meteringProfile.register.header.name',
    defaultMessage: 'Name',
  },
  obis: {
    id: 'meteringProfile.register.header.obis',
    defaultMessage: 'OBIS code',
  },
  IC: {
    id: 'meteringProfile.register.header.ic',
    defaultMessage: 'IC',
  },
  attributeId: {
    id: 'meteringProfile.register.header.attributeId',
    defaultMessage: 'Attribute id',
  },
  unit: {
    id: 'meteringProfile.register.header.unit',
    defaultMessage: 'Unit',
  },
  scaler: {
    id: 'meteringProfile.register.header.scaler',
    defaultMessage: 'Scaler',
  },
});

export const registerHead = ({ intl, ...props }) => (
  <TableHead {...props}>
    <TableCell
      columnId={COLUMN_NAME}
      title={intl.formatMessage(messages.name)}
      style={{
        minWidth: '180px',
      }}
      flex="2"
    ><FormattedMessage {...messages.name} />
    </TableCell>
    <TableCell
      columnId={COLUMN_OBIS}
      title={intl.formatMessage(messages.obis)}
      flex="2"
      style={{
        minWidth: '130px',
      }}
    ><FormattedMessage {...messages.obis} />
    </TableCell>
    <TableCell
      columnId={COLUMN_IC}
      alignRight
      title={intl.formatMessage(messages.IC)}
    ><FormattedMessage {...messages.IC} />
    </TableCell>
    <TableCell
      columnId={COLUMN_ATTRIBUTE_ID}
      title={intl.formatMessage(messages.attributeId)}
      flex="2"
      alignRight
    ><FormattedMessage {...messages.attributeId} />
    </TableCell>
    <TableCell
      columnId={COLUMN_UNIT}
      title={intl.formatMessage(messages.unit)}
    ><FormattedMessage {...messages.unit} />
    </TableCell>
    <TableCell
      columnId={COLUMN_SCALER}
      alignRight
      title={intl.formatMessage(messages.scaler)}
    ><FormattedMessage {...messages.scaler} />
    </TableCell>
  </TableHead>
);

export const registerRow = props => register => (
  <TableRow
    key={`${register.obisCode}-${register.attributeId}`}
    {...props}
  >
    <TableCell
      style={{
        minWidth: '180px',
      }}
      flex="2"
      columnId={COLUMN_NAME}
    >
      {register.name}
    </TableCell>
    <TableCell columnId={COLUMN_OBIS}>{register.obisCode}</TableCell>
    <TableCell columnId={COLUMN_IC}>{register.ic}</TableCell>
    <TableCell columnId={COLUMN_ATTRIBUTE_ID}>{register.attributeId}</TableCell>
    <TableCell columnId={COLUMN_UNIT}>{register.unit}</TableCell>
    <TableCell columnId={COLUMN_SCALER}>{register.scaler}</TableCell>
  </TableRow>
);

const RegisterTable = ({
  registers,
  intl,
  ...props
}) => (
  <Table {...props}>
    {registerHead({ intl })}
    {registers.map(register => registerRow()(register))}
  </Table>
);

RegisterTable.propTypes = {
  registers: arrayOf(registerShape).isRequired,
  intl: intlShape.isRequired,
};

registerHead.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(RegisterTable);
