import { combineReducers } from 'redux';
import foundReducer from 'found/lib/foundReducer';

import { reducer as drawer } from 'common/mdc/drawer';
import { reducer as stepper } from 'common/mdc/stepper';
import { reducer as notifications } from 'common/mdc/notifications';
import { reducer as auth } from 'common/store/auth';
import { reducer as customer } from 'common/store/customer';
import { reducer as server } from 'common/store/server';
import widgets from './widgets/reducer';
import device from './device/reducer';
import { reducer as group } from './group';
import meteringProfile from './meteringProfile/reducer';

export default combineReducers({
  found: foundReducer,
  drawer,
  stepper,
  notifications,
  auth,
  customer,
  widgets,
  device,
  server,
  group,
  meteringProfile,
});
