import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Link } from 'found';
import { string, shape, oneOf, number } from 'prop-types';
import { ProgressDeterminate } from 'common/mdc/progress';
import Icon from 'common/mdc/icon';
import { messages as statusMessages, icons, STATUS_ONLINE, STATUS_OFFLINE, STATUS_LOGIN, STATUS_LOGOUT } from '@sma/components/device/search/status';
import { iconFlag } from '@sma/components/device/header';
import { EQUALS } from 'common/store/search/advanced/constraint/operators';
import { NETWORK_STATUS } from '@sma/components/device/search/advanced';
import { Widget } from 'common/mdc/card';
import S from 'common/service/sanctuary';
import { sum } from 'ramda';
import { selectors } from '@sma/store/widgets/network';
import Wrapper from 'common/mdc/wrappers/wrapper';
import { connect } from 'react-redux';
import { sumOfMeters } from '../sumOfMeters';

const messages = defineMessages({
  displayName: {
    id: 'widget.network.name',
    defaultMessage: '{name}',
  },
  loggedInPercentage: {
    id: 'widget.network.loggedInPercentage',
    defaultMessage: '{progress}% logged in',
  },
  networkLoadError: {
    id: 'widgets.network.loadError',
    defaultMessage: 'There was an error while loading the statistics',
  },
  networkNoDataLoaded: {
    id: 'widgets.network.notLoaded',
    defaultMessage: 'Status statistics are not loaded',
  },
  networkLoadingData: {
    id: 'widgets.network.loadingData',
    defaultMessage: 'Loading status statistics',
  },
  network: {
    id: 'widgets.network.network',
    defaultMessage: 'Meter Network Status',
  },
});

const progress = ({ total, count }) =>
  total && Math.round((count / total) * 100);

const ProgressBlock = ({ name, total, count }) => (
  <div className="progress-bar">
    <div className="left-label">
      <FormattedMessage
        values={{
          name: name,
        }}
        {...messages.displayName}
      />
      {/**<Link
        className="count"
        to={{
          pathname: '/devices',
          params: {
            initialCriteria: [],
          },
        }}
      >*/}&nbsp;({total})
      {/**</Link>*/}
    </div>
    <div className="right-label">
      <FormattedMessage
        values={{
          progress: progress({ total, count }),
        }}
        {...messages.loggedInPercentage}
      />
    </div>
    <ProgressDeterminate progress={progress({ total, count })} />
  </div>
);

ProgressBlock.propTypes = {
  name: string.isRequired,
  total: number.isRequired,
  count: number.isRequired,
};

const WidgetNetwork = ({ networkDataMaybe, loadingNetwork, error }) => (
  <Widget
    loading={loadingNetwork}
    title={<FormattedMessage {...messages.network} />}
    subtitle={
      S.maybeToNullable(S.map(
        ({ data }) => (
          <Link to={{
            pathname: '/devices',
            params: {
              initialCriteria: [],
            },
          }}
          >{sum([
            sumOfMeters(data, "loggedIn"),
            sumOfMeters(data, "loggedOff"),
          ])}
          </Link>
        ),
        networkDataMaybe,
      ))
    }
  >
    <Wrapper
      empty={networkDataMaybe.isNothing}
      loading={loadingNetwork}
      error={error}
      errorMessage={<FormattedMessage {...messages.networkLoadError} />}
      notFoundMessage={<FormattedMessage {...messages.networkNoDataLoaded} />}
      loadingMessage={<FormattedMessage {...messages.networkLoadingData} />}
    >
      {S.maybeToNullable(S.map(
        ({ data }) => (
          <div className="widget-network">
            <div className="logged-devices">
              <div className="logged-in-devices">
                <Icon>{icons[STATUS_ONLINE]}</Icon>
                <Link
                  className="count"
                  to={{
                    pathname: '/devices',
                    params: {
                      initialCriteria: [
                        {
                        property: NETWORK_STATUS,
                        constraints: [
                          {
                            operator: EQUALS,
                            value: STATUS_LOGIN,
                          },
                        ],
                      },
                    ],
                    },
                  }}
                >
                  {`${sumOfMeters(data, "loggedIn")} `}
                  <FormattedMessage {...statusMessages[STATUS_LOGIN]} />
                </Link>
              </div>
              <div className="logged-off-devices">
                <Icon>{icons[STATUS_OFFLINE]}</Icon>
                <Link
                  className="count"
                  to={{
                    pathname: '/devices',
                    params: {
                      initialCriteria: [
                        {
                          property: NETWORK_STATUS,
                          constraints: [
                            {
                              operator: EQUALS,
                              value: STATUS_LOGOUT,
                            },
                          ],
                        },
                      ],
                    },
                  }}
                >
                  {`${sumOfMeters(data, "loggedOff")} `}
                  <FormattedMessage {...statusMessages[STATUS_LOGOUT]} />
                </Link>
              </div>
            </div>
            {
              data &&
              data.map((category) => (
                sum([category.loggedIn, category.loggedOff, category.unknown]) > 0 &&
                <ProgressBlock
                  name={category.name}
                  total={sum([category.loggedIn, category.loggedOff, category.unknown])}
                  count={category.loggedIn}
                />
              ))
            }
          </div>
        ),
        networkDataMaybe,
      ))}
    </Wrapper>
  </Widget>
);

WidgetNetwork.propTypes = {
  networkDataMaybe: shape().isRequired,
};

WidgetNetwork.defaultProps = {
  networkDataMaybe: undefined,
};

export default connect(
  state => ({
    networkDataMaybe: selectors.getNetworkWidgetMaybe(state),
    error: selectors.getError(state),
    loadingNetwork: selectors.isLoading(state),
  }),
)(WidgetNetwork);
