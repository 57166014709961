import * as R from 'ramda';
import React from 'react';
import Table, { SELECTION_MULTIPLE } from 'common/mdc/table/index';
import { arrayOf, func, bool } from 'prop-types';
import { injectIntl } from 'react-intl';
import deviceShape from '@sma/components/device/shape';
import {
  COLUMN_ACTIONS,
  deviceHead,
  deviceRow,
  columns,
} from '@sma/components/device/table';

const displayColumns = R.without([
  COLUMN_ACTIONS,
], columns);
const ITEM_ADDED = 'edit-static-group--added';
const ITEM_REMOVED = 'edit-static-group--removed';

const DevicePrimaryTable = ({
  devices,
  onSelect,
  selected,
  onSelectionChange,
  isSorted,
  onSortChange,
  allSelected,
  isAdditionalItem,
  intl,
  ...props
}) => {
  const getRowClass = device => (
    (selected(device) && isAdditionalItem(device) && ITEM_ADDED)
    || (!selected(device) && !isAdditionalItem(device) && ITEM_REMOVED)
  );

  return (
    <Table selectable selectionType={SELECTION_MULTIPLE} {...props}>
      {deviceHead({
        displayColumns,
        intl,
        isSorted,
        toggleSort: onSortChange,
        checked: allSelected,
        onSelectionChange,
      })}
      {
        devices.map((device) => {
          const onClick = () => onSelect(device);

          return deviceRow({
            checked: Boolean(selected(device)),
            onClick,
            onSelect: onClick,
            className: getRowClass(device),
            linkProps: {
              onClick: event => event.stopPropagation(),
              target: '_blank',
            },
          })(device);
        })
      }
    </Table>
  );
};

DevicePrimaryTable.propTypes = {
  devices: arrayOf(deviceShape).isRequired,
  onSelect: func.isRequired,
  onSelectionChange: func.isRequired,
  isSorted: func.isRequired,
  onSortChange: func.isRequired,
  selected: func.isRequired,
  isAdditionalItem: func.isRequired,
  allSelected: bool.isRequired,
};

export default injectIntl(DevicePrimaryTable);
