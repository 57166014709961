import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, takeUntil, catchError } from 'rxjs/operators';
import { defineMessages } from 'react-intl';
import { createErrorStream } from 'common/store/error';
import {
  TYPE_ERROR,
} from 'common/mdc/notifications/notification';
import { addNotification } from 'common/mdc/notifications/actions';
import { Subject, merge } from 'rxjs';

import {
  LOAD_STATUS, loadedStatus,
  CANCELLED_STATUS, errorStatus,
  UPDATE_STATUS, updateConnectionStatus,
  loadStatus,
} from './actions';

const messages = defineMessages({
  connectFailed: {
    id: 'device.relay-connect-disconnect.view-details',
    defaultMessage: 'Connection to meter {deviceId} failed',
  },
  retry: {
    id: 'device.relay-connect-disconnect.retry',
    defaultMessage: 'Retry',
  },
});

const clickEventStream = new Subject();

const errorNotification = ({ intl, action$: { deviceId, status = null } }) =>
  addNotification({
    type: TYPE_ERROR,
    text: intl.formatMessage(messages.connectFailed, { deviceId }),
    actions: [
      {
        text: intl.formatMessage(messages.retry),
        icon: 'autorenew',
        onClick: () => clickEventStream.next(status
          ? updateConnectionStatus({ deviceId, status })
          : loadStatus(deviceId)),
      },
    ],
  });

const loadStatusEpic = (action, state, { intl, deviceService }) => merge(
  clickEventStream,
  action.pipe(
    ofType(LOAD_STATUS),
    switchMap(action$ => deviceService
      .getStatus(action$.deviceId)
      .pipe(
        map(loadedStatus),
        takeUntil(action.pipe(ofType(CANCELLED_STATUS))),
        catchError(createErrorStream(
          action$,
          errorStatus,
          errorNotification.bind(null, { intl, action$ }),
        )),
      )),
  ),
);

const updateStatusEpic = (action, state, { intl, deviceService }) => merge(
  clickEventStream,
  action.pipe(
    ofType(UPDATE_STATUS),
    switchMap(action$ => deviceService
      .updateStatus(action$.deviceId, action$.status)
      .pipe(
        map(loadedStatus),
        takeUntil(action.pipe(ofType(CANCELLED_STATUS))),
        catchError(createErrorStream(
          action$,
          errorStatus,
          errorNotification.bind(null, { intl, action$ }),
        )),
      )),
  ),
);

export default combineEpics(
  loadStatusEpic,
  updateStatusEpic,
);
