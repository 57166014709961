import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { number, bool, func } from 'prop-types';
import { Summary } from 'common/components/search/advanced';

import {
  selectors,
  actions,
} from '@sma/store/device/search';

const MeterSearchSummary = ({
  count,
  searchExpanded,
  toggleAdvancedSearch,
}) => (
  <Summary
    count={count}
    isExpanded={searchExpanded}
    toggleAdvancedSearch={toggleAdvancedSearch}
  />
);

MeterSearchSummary.propTypes = {
  count: number.isRequired,
  searchExpanded: bool.isRequired,
  toggleAdvancedSearch: func.isRequired,
};

export default compose(
  connect(
    state => ({
      searchExpanded: selectors.isExpanded(state),
      count: selectors.getCount(state),
    }),
    ({
      toggleAdvancedSearch: actions.toggleExpand,
    }),
  ),
)(MeterSearchSummary);
