import { combineReducers } from 'redux';

import onDemandReading from './onDemandReading/reducer';
import onDemandHistory from './onDemandHistory/reducer';
import relayConnectDisconnect from './relayConnectDisconnect/reducer';
import devices from './devices/reducer';
import device from './device/reducer';
import meteringProfile from './meteringProfile/reducer';
import meteringProfileDetails from './meteringProfileDetails/reducer';
import meteringProfileSamples from './meteringProfileSamples/reducer';
import search from './search/reducer';
import alarms from './alarms/reducer';
import events from './events/reducer';

export default combineReducers({
  meteringProfileDetails,
  meteringProfile,
  meteringProfileSamples,
  relayConnectDisconnect,
  onDemandReading,
  onDemandHistory,
  devices,
  device,
  search,
  alarms,
  events,
});
