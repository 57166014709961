import {
  collection,
  selection,
} from 'common/store/selectors';

const getScope = ({ group: { groups } }) => groups;

const collectionSelectors = collection.factory(getScope);
const selectionSelectors = selection.factory(getScope);

export const {
  getTotal,
  isLoading,
  getPaginator,
  isSorted,
  getStoreParams,
  getQuery,
} = collection.factory(getScope);

export const {
  isSelected,
  getSelected,
} = selectionSelectors;

export const getGroupsContext = collectionSelectors.getCollectionContext;
export const getGroupsMaybe = collectionSelectors.getCollectionMaybe;
