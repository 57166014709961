import S from 'common/service/sanctuary';
import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { Primary } from 'common/mdc/layout';
import { Link } from 'found';
import { func, bool, string } from 'prop-types';
import { intlShape, injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import QuickSearch from 'common/components/search';
import { withConfirmationDialog } from 'common/mdc/dialog';
import Breadcrumbs from 'common/mdc/breadcrumbs';
import GroupEdit from '@sma/components/group/edit';
import { CancelingDialog } from '@sma/components/group/edit/dialogs';
import { TYPE_STATIC } from '@sma/components/group/groupType';

import {
  actions as groupDevicesActions,
  selectors as groupDevicesSelectors,
} from '@sma/store/group/devices';

import {
  actions as devicesActions,
  selectors as devicesSelectors,
} from '@sma/store/device/devices';

import {
  selectors as groupSelectors,
} from '@sma/store/group/group';

import Group from './group';

export const messages = defineMessages({
  group: {
    id: 'group.edit.static.group-title',
    defaultMessage: 'Groups',
  },
  title: {
    id: 'group.edit.static.title',
    defaultMessage: 'Edit group',
  },
  search: {
    id: 'group.edit.static.search-devices',
    defaultMessage: 'Search for devices',
  },
});

class GroupEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.removeHook = props.router.addTransitionHook(() => new Promise((resolve) => {
      props.cancelingDialog({
        resolve,
        reject: () => false,
        name: S.maybe(
          'group',
          group => group.name,
          this.props.maybeGroup,
        ),
      });
    }));
  }

  componentWillUnmount() {
    this.removeHook();
  }

  render() {
    const {
      loading,
      loadingGroup,
      maybeGroup,
      search,
      query,
      intl,
      params: { groupId },
    } = this.props;

    return (
      <Group
        groupId={groupId}
        title={
          <Breadcrumbs>
            <Link key={messages.group.id} to="/groups">
              <FormattedMessage {...messages.group} />
            </Link>
            <Link key={groupId} to={`/groups/${groupId}`}>{S.maybe(
              groupId,
              group => group.name,
              maybeGroup,
            )}
            </Link>
            <FormattedMessage key={messages.title.id} {...messages.title} />
          </Breadcrumbs>}
        tools={
          <QuickSearch
            placeholder={intl.formatMessage(messages.search)}
            value={query}
            onChange={e => search(e.target.value)}
          />}
        loading={loadingGroup}
      >
        {group => (
          <Primary loading={loading}>
            <GroupEdit
              group={group}
              onSave={this.removeHook}
            />
          </Primary>)}
      </Group>
    );
  }
}

GroupEditPage.propTypes = {
  loading: bool.isRequired,
  search: func,
  query: string,
  intl: intlShape.isRequired,
  cancelingDialog: func.isRequired,
  loadingGroup: bool.isRequired,
};

GroupEditPage.defaultProps = {
  search: () => {},
  query: '',
};

export default compose(
  connect(
    state => ({
      maybeGroup: groupSelectors.getGroupMaybe(state),
      query: devicesSelectors.getQuery(state),
      groupQuery: groupDevicesSelectors.getQuery(state),
      loadingGroup: groupSelectors.isLoading(state),
      loading: (
        groupDevicesSelectors.isLoading(state)
        || groupSelectors.isLoading(state)
        || devicesSelectors.isLoading(state)
      ),
    }),
    {
      searchDevices: devicesActions.search,
      searchGroupDevices: groupDevicesActions.search,
    },
    (stateProps, { searchDevices, searchGroupDevices }, props) => ({
      ...props,
      ...stateProps,
      ...S.maybeToNullable(S.map(
        group => (group.type === TYPE_STATIC
          ? ({
            query: stateProps.query,
            search: searchDevices,
          })
          : ({
            query: stateProps.groupQuery,
            search: searchGroupDevices,
          })),
        stateProps.maybeGroup,
      )),
    }),
  ),
  injectIntl,
  withConfirmationDialog(CancelingDialog, 'cancelingDialog'),
)(GroupEditPage);
