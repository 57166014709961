import { actions } from 'common/store/resource';

export const NAMESPACE = 'DEVICE/METERING_PROFILE_DETAILS';

const resourceActions = actions.factory(NAMESPACE);

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  CLEAR,
  UPDATE,
} = resourceActions.TYPES;

export const {
  loadSuccess,
  loadError,
  clear,
  update,
} = resourceActions;

export const load = (deviceId, meteringProfileId) => ({
  ...resourceActions.load(),
  deviceId,
  meteringProfileId,
});
