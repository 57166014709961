import {
  collection,
} from 'common/store/actions';

export const NAMESPACE = 'DEVICE/ALARMS';

const collectionActions = collection.factory(NAMESPACE);

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  TOGGLE_SORT,
  SEARCH,
  PAGINATION_CHANGE,
  DOWNLOAD,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
} = collectionActions.TYPES;

export const {
  loadSuccess,
  loadError,
  toggleSort,
  search,
  paginationChange,
  downloadSuccess,
  downloadError,
} = collectionActions;

export const load = ({ limit, offset, sort, query, ...props } = {}) => ({
  ...props,
  ...collectionActions.load({ limit, offset, sort, query }),
});

export const download = ({ sort, query, ...props } = {}) => ({
  ...props,
  ...collectionActions.download({ sort, query }),
});
