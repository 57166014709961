import S from 'common/service/sanctuary';
import { compose } from 'ramda';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { bool, func, string } from 'prop-types';
import Main from 'common/mdc/layout';
import Search, { Advanced, Summary } from '@sma/components/device/search';
import Primary from '@sma/components/device/primary';
import Secondary from '@sma/components/device/secondary';

import {
  selectors,
  actions,
} from '@sma/store/device/devices';

import {
  selectors as searchSelectors,
} from '@sma/store/device/search';

export const messages = defineMessages({
  title: {
    id: 'title.devices',
    defaultMessage: 'Meters',
  },
  search: {
    id: 'search.devices.placeholder',
    defaultMessage: 'Search for meters',
  },
});

const Devices = ({
  intl,
  selectedDevice,
  clearSelection,
  searchExpanded,
}) => (
  <Main
    title={<FormattedMessage {...messages.title} />}
    tools={
      <Fragment>
        <Search />
        <Summary />
      </Fragment>
    }
    toolbar={searchExpanded ? <Advanced /> : null}
  >
    <Primary />
    {S.maybeToNullable(S.map(device => (
      <Secondary device={device} onClose={clearSelection} />
    ), selectedDevice))}
  </Main>
);

Devices.propTypes = {
  clearSelection: func.isRequired,
  searchExpanded: bool.isRequired,
  query: string,
  intl: intlShape.isRequired,
  loading: bool,
};

Devices.defaultProps = {
  query: '',
  loading: false,
};

export default compose(
  connect(
    state => ({
      selectedDevice: selectors.getSelected(state),
      searchExpanded: searchSelectors.isExpanded(state),
    }),
    ({
      clearSelection: actions.clearSelected,
    }),
  ),
  injectIntl,
)(Devices);
