import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { Actions as FarceActions } from 'farce';
import { defineMessages, FormattedMessage } from 'react-intl';
import { func, shape } from 'prop-types';
import Button from 'common/mdc/button/index';

const messages = defineMessages({
  saveChanges: {
    id: 'group.edit.static.actions.save-changes',
    defaultMessage: 'Save changes',
  },
  cancel: {
    id: 'group.edit.static.actions.cancel',
    defaultMessage: 'Cancel',
  },
});

const PrimaryActions = ({
  push,
  group,
  onSave,
}) => (
  <div className="static-primary-actions">
    <Button
      key={messages.cancel.id}
      onClick={() => push(`/groups/${group.id}`)}
    >
      <FormattedMessage {...messages.cancel} />
    </Button>
    <Button
      key={messages.saveChanges.id}
      raised
      onClick={() => onSave()}
    >
      <FormattedMessage {...messages.saveChanges} />
    </Button>
  </div>
);

PrimaryActions.propTypes = {
  group: shape().isRequired,
  push: func.isRequired,
  onSave: func.isRequired,
};

export default compose(
  connect(
    null,
    ({
      push: FarceActions.push,
    }),
  ),
)(PrimaryActions);
