import { combineEpics } from 'redux-observable';
import { epic as auth } from 'common/store/auth';
import { epic as customer } from 'common/store/customer';
import { epic as server } from 'common/store/server';
import { epic as appError } from 'common/store/error';

import widgets from './widgets/epic';
import devices from './device/epic';
import { epic as groups } from './group';
import meteringProfiles from './meteringProfile/meteringProfiles/epic';

export default combineEpics(
  appError,
  auth,
  customer,
  widgets,
  devices,
  server,
  groups,
  meteringProfiles,
);
