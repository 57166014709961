import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import QuickSearch from 'common/components/search';
import { string, func } from 'prop-types';
import { injectIntl, intlShape, defineMessages } from 'react-intl';

import {
  selectors,
  actions,
} from '@sma/store/device/devices';

export const messages = defineMessages({
  search: {
    id: 'meters.search.quick.placeholder',
    defaultMessage: 'Search for meters',
  },
});

const MeterSearch = ({
  intl,
  query,
  search,
}) => (
  <QuickSearch
    placeholder={intl.formatMessage(messages.search)}
    value={query}
    onChange={search}
  />
);

MeterSearch.propTypes = {
  intl: intlShape.isRequired,
  search: func.isRequired,
  query: string,
};

MeterSearch.defaultProps = {
  query: '',
};

export default compose(
  connect(
    state => ({
      query: selectors.getQuery(state),
    }),
    ({
      search: evt => actions.search(evt.target.value),
    }),
  ),
  injectIntl,
)(MeterSearch);
