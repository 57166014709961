import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { MenuWrapper } from 'common/mdc/menu';
import Icon from 'common/mdc/icon';
import Button from 'common/mdc/button';
import JobMenu from './jobMenu';

const messages = defineMessages({
  actions: {
    id: 'device.bulkActions',
    defaultMessage: 'Bulk actions',
  },
});

const MeterBulkActions = () => (
  <MenuWrapper
    left={false}
    overlay={false}
    wrappedElement={
      <Button>
        <FormattedMessage {...messages.actions} />
        <Icon className="mdc-button__icon right">arrow_drop_down</Icon>
      </Button>}
  >
    <JobMenu />
  </MenuWrapper>
);

export default MeterBulkActions;
