import S from 'common/service/sanctuary';
import React from 'react';
import * as R from 'ramda';
import { defineMessages, FormattedMessage } from 'react-intl';
import { arrayOf, shape } from 'prop-types';
import Table, { TableHead, TableCell, TableRow } from 'common/mdc/table';
import SampleValue from '@sma/components/samples/sampleValue';
import { compareRegisters } from '@sma/store/device/meteringProfile/selectors';
import DateTime from 'common/components/dateTime';
import classnames from 'classnames';
import ScrollContainer from 'common/mdc/scrollbar/container';

import RegisterName from '../name';
import registerShape from '../shape';

import {
  COLUMN_SAMPLE_TIME,
  COLUMN_REGISTERED_TIME,
} from '@sma/service/api/meteringProfileSamples';

const messages = defineMessages({
  datetime: {
    id: 'smas.devices.actions.on-demand-history.datetime',
    defaultMessage: 'Meter datetime',
  },
  registeredTime: {
    id: 'smas.devices.actions.on-demand-history.registeredTime',
    defaultMessage: 'Server time',
  },
});

export const sampleRow = ({ key, registers, ...props }) => (sample) => {
  const samples = (sample && sample.registerValues) || [];
  const getRegisterSample = register => R.find(compareRegisters(register), samples);

  return (
    <TableRow
      key = { sample.sampleTime }
      {...props}
    >
      <TableCell columnId={COLUMN_SAMPLE_TIME}>
        {sample.sampleTime && (
          <DateTime value={sample.sampleTime} title seconds />
        )}
      </TableCell>
      <TableCell columnId={COLUMN_REGISTERED_TIME}>
        {sample.registeredTime && (
          <DateTime value={sample.registeredTime} title seconds />
        )}
      </TableCell>
      { registers.map((register, index) => {
        const registerSample = getRegisterSample(register);

        return (
          <TableCell key={index} columnId={index}>
            {registerSample && (
              registerSample.rawValue ?
                <SampleValue
                  sampleValue={registerSample.rawValue}
                  stringValue={registerSample.value}
                /> :
                registerSample.value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
const OnDemandHistoryTable = ({ registers, samples }) => (
  <ScrollContainer useRef="tableRef" settings={{ suppressScrollY: true }}>
    <Table className={classnames('samplesTable', 'on-demand-history-table')}>
      <TableHead>
        <TableCell columnId={COLUMN_SAMPLE_TIME} flex="2" alignRight>
          <FormattedMessage {...messages.datetime} />
        </TableCell>
        <TableCell columnId={COLUMN_REGISTERED_TIME} flex="2" alignRight>
          <FormattedMessage {...messages.registeredTime} />
        </TableCell>
        {registers.map((register, index) => (
          <TableCell
            key={index}
            columnId={index}
            flex="2"
            alignRight
          >
            <RegisterName register={register} />
          </TableCell>
        ))}
      </TableHead>
      {samples.map((sample, key) => sampleRow({key, registers})(sample))}
    </Table>
  </ScrollContainer>
);

OnDemandHistoryTable.propTypes = {
  registers: arrayOf(registerShape).isRequired,
  samples: arrayOf(shape()).isRequired,
};

export default OnDemandHistoryTable;
