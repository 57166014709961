import {
  collection,
  selection,
} from 'common/store/selectors';

const getScope = ({ group: { devices } }) => devices;

const collectionSelectors = collection.factory(getScope);

export const {
  isSorted,
  getPaginator,
  getTotal,
  isLoading,
  getCollectionContext,
  getCollectionMaybe,
  getQuery,
  getStoreParams,
} = collectionSelectors;

export const {
  isSelected,
  getSelected,
} = selection.factory(getScope);

export const getDevicesContext = collectionSelectors.getCollectionContext;
export const getDevicesMaybe = collectionSelectors.getCollectionMaybe;
