import { combineEpics, ofType } from 'redux-observable';
import * as R from 'ramda';
import { map, catchError, switchMap, debounceTime, startWith, mergeMap } from 'rxjs/operators';
import { Subject, merge, of } from 'rxjs';
import { PublicError } from 'common/service/error';
import { addNotification, replaceNotification, removeNotification } from 'common/mdc/notifications/actions';
import {
  TYPE_SUCCESS, TYPE_ERROR, TYPE_PENDING,
} from 'common/mdc/notifications/notification';
import { createErrorStream } from 'common/store/error';

import {
  LOAD, load,
  loadSuccess,
  loadError,
  downloadSuccess,
  downloadError,
  TOGGLE_SORT,
  PAGINATION_CHANGE,
  SEARCH,
  SET_SELECTED,
  ADVANCED_SEARCH,
  DOWNLOAD,
} from './actions';

import {
  getStoreParams,
} from './selectors';

import {
  actions,
} from '../device';

import purgeEpic from './purgeEpic';

const successText = (response) => {
  switch (response.status) {
    case 206:
      return "Meter list data partially downloaded";
    default:
      return "Meter list data downloaded";
  }
};

const loadEpic = (action, state, { deviceService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => deviceService
    .getAll(getStoreParams(state.value))
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

const downloadEpic = (action, state, { deviceService }) => action.pipe(
  ofType(DOWNLOAD),
  mergeMap((action$) => {

    const pendingNotification = addNotification({
      autoHide: false,
      type: TYPE_PENDING,
      text: "Meter list data download in progress"
    });
    const failureMessage = "";

    return deviceService
    .downloadAll(getStoreParams(state.value))
      .pipe(
        mergeMap((response) => of(
          replaceNotification(pendingNotification.notification, {
            type: TYPE_SUCCESS,
            autoHide: true,
            text: successText(response),
          }),
        )),
        startWith(pendingNotification),
        catchError(createErrorStream(
          action$,
          err => replaceNotification(pendingNotification.notification, {
            type: TYPE_ERROR,
            autoHide: true,
            text: R.is(PublicError, err) && err.message
                ? err.message
                : err.status === 429 ? "Too many requests. Please try again later." : failureMessage,
          }),
        )),
      );
  }),
);

const update = action => action.pipe(
  ofType(TOGGLE_SORT, PAGINATION_CHANGE, ADVANCED_SEARCH),
  map(load),
);

const search = action => action.pipe(
  ofType(SEARCH),
  debounceTime(150),
  map(load),
);

const loadSelected = action => action.pipe(
  ofType(SET_SELECTED),
  map(({ selected }) => actions.load(selected.deviceId)),
);

export default combineEpics(
  loadEpic,
  downloadEpic,
  purgeEpic,
  update,
  search,
  loadSelected,
);
