import { combineEpics, ofType } from 'redux-observable';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions as FarceActions } from 'farce';
import { defineMessages } from 'react-intl';
import { addNotification } from 'common/mdc/notifications/actions';
import {
  TYPE_SUCCESS,
} from 'common/mdc/notifications/notification';
import { actions as stepperActions } from 'common/mdc/stepper';
import { createErrorStream } from 'common/store/error';
import {
  actions as searchActions,
} from '@sma/store/device/search';
import {
  CREATE, createSuccess, createError,
} from './actions';

const messages = defineMessages({
  viewGroupDetails: {
    id: 'group.actions.view-details',
    defaultMessage: 'View group details',
  },
  created: {
    id: 'group.created',
    defaultMessage: 'Group {group} successfully created',
  },
});

const create = (action, state, { intl, groupService }) => action.pipe(
  ofType(CREATE),
  switchMap(action$ => groupService
    .create(action$.group)
    .pipe(
      mergeMap(group => of(
        createSuccess(group),
        FarceActions.push('/groups'),
        searchActions.loadCriteria([]),
        addNotification({
          type: TYPE_SUCCESS,
          text: intl.formatMessage(messages.created, {
            group: group.name,
          }),
          // actions: [
          //   {
          //     text: intl.formatMessage(messages.viewGroupDetails),
          //     icon: 'open_in_new',
          //     onClick: () => store.dispatch(FarceActions.push(`/groups/${group.id}`)),
          //   },
          // ],
        }),
      )),
      catchError(createErrorStream(
        action$,
        createError,
        stepperActions.reject,
      )),
    )),

);

export default combineEpics(
  create,
);
