import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';
import {
  LOAD,
  loadSuccess,
  loadError,
  load, PAGINATION_CHANGE,
} from './actions';


import {
  getStoreParams,
} from './selectors';

const loadAllEpic = (action, state, { meteringProfileService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => meteringProfileService
    .getAll(getStoreParams(state.value))
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

const updateAllEpic = action => action.pipe(
  ofType(PAGINATION_CHANGE),
  map(load),
);

export default combineEpics(
  loadAllEpic,
  updateAllEpic,
);
