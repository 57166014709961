import * as R from 'ramda';
import S from 'common/service/sanctuary';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bool, shape, func } from 'prop-types';
import { Pagination } from 'common/mdc/table';
import { defineMessages, FormattedMessage } from 'react-intl';
import CollectionWrapper from 'common/mdc/wrappers/collection';
import ExpandableDeviceTable from '@sma/components/device/expandable';
import { COLUMN_FIRMWARE, COLUMN_HARDWARE, COLUMN_DRIVER, columns } from '@sma/components/device/table';

import {
  actions,
  selectors,
} from '@sma/store/group/devices';

export const messages = defineMessages({
  summary: {
    id: 'group.devices.summary',
    defaultMessage: '{count, number} group {count, plural, one {meter} other {meters}} of {total} displayed',
  },
  loading: {
    id: 'group.devices.loading',
    defaultMessage: 'Loading group meters',
  },
  error: {
    id: 'group.devices.error',
    defaultMessage: 'Loading group meters error',
  },
  empty: {
    id: 'group.devices.empty',
    defaultMessage: 'Group meters not found',
  },
  devicesPerPage: {
    id: 'group.pagination.devicesPerPage',
    defaultMessage: 'Meters per page',
  },
  emptyReason: {
    id: 'group.device.empty.reason',
    defaultMessage: 'Group does not contain any meter',
  },
});

const displayColumns = R.without([
  COLUMN_FIRMWARE,
  COLUMN_HARDWARE,
  COLUMN_DRIVER,
], columns);

const GroupDevices = ({
  paginator,
  onPaginationChange,
  devicesContext,
  loading,
  isSorted,
  toggleSort,
  total,
}) => (
  <CollectionWrapper
    className="group-devices"
    loading={loading}
    context={devicesContext}
    messages={{
      loading: <FormattedMessage {...messages.loading} />,
      empty: <FormattedMessage {...messages.empty} />,
      emptyReasons: [
        <FormattedMessage {...messages.emptyReason} />,
      ],
      summary: count => (
        <FormattedMessage
          values={{ count, total }}
          {...messages.summary}
        />
      ),
    }}
  >
    {devices => (
      <Fragment>
        <ExpandableDeviceTable
          devices={devices}
          isSorted={isSorted}
          displayColumns={displayColumns}
          toggleSort={toggleSort}
        />
        {S.maybeToNullable(S.map(props => (
          <Pagination
            {...props}
            onChange={onPaginationChange}
            itemsPerPage={messages.devicesPerPage}
          />
        ), paginator))}
      </Fragment>)}
  </CollectionWrapper>
);

GroupDevices.propTypes = {
  loading: bool.isRequired,
  paginator: shape().isRequired,
  onPaginationChange: func.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
};

export default connect(
  state => ({
    loading: selectors.isLoading(state),
    devicesContext: selectors.getDevicesContext(state),
    paginator: selectors.getPaginator(state),
    isSorted: selectors.isSorted(state),
    total: S.fromMaybe(0, selectors.getTotal(state)),
  }),
  ({
    onPaginationChange: actions.paginationChange,
    toggleSort: actions.toggleSort,
  }),
)(GroupDevices);
