import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { injectIntl, intlShape } from 'react-intl';
import Table from 'common/mdc/table';
import { arrayOf, func, symbol } from 'prop-types';
import { actions, selectors } from '@sma/store/device/devices';
import { deviceHead, deviceRow } from './table';
import DetailsForm from './detailsForm';
import deviceShape from './shape';

const ExpandableDeviceTable = ({
  devices,
  displayColumns,
  isSorted,
  toggleSort,
  setSelected,
  isSelected,
  clearSelected,
  intl,
  ...props
}) => (
  <Table className="expandable-device-table" expandable {...props}>
    {deviceHead({
      displayColumns,
      isSorted,
      toggleSort,
      intl,
    })}
    {devices.map(device => [
      deviceRow({
        selected: isSelected(device),
        expanded: isSelected(device),
        onClick: () => (isSelected(device) ? clearSelected() : setSelected(device)),
        toggleExpand: (e) => {
          e.stopPropagation();
          if (isSelected(device)) {
            clearSelected();
          } else {
            setSelected(device);
          }
        },
        expandElement: (
          <DetailsForm device={device} />
        ),
      })(device),
    ])}
  </Table>
);

ExpandableDeviceTable.propTypes = {
  devices: arrayOf(deviceShape).isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  displayColumns: arrayOf(symbol),
  isSelected: func.isRequired,
  setSelected: func.isRequired,
  intl: intlShape.isRequired,
};

ExpandableDeviceTable.defaultProps = {
  displayColumns: [],
};

export default compose(
  connect(
    state => ({
      isSelected: selectors.isSelected(state),
    }),
    {
      setSelected: actions.setSelected,
      clearSelected: actions.clearSelected,
    },
  ),
  injectIntl,
)(ExpandableDeviceTable);
