import S from 'common/service/sanctuary';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { bool, func, string } from 'prop-types';
import Main, { Primary } from 'common/mdc/layout';
import { MeteringProfilesTable } from '@sma/components/meteringProfile';
import MeteringProfilesSecondary from '@sma/components/meteringProfile/secondary';
import CollectionWrapper from 'common/mdc/wrappers/collection';
import QuickSearch from 'common/components/search';

import {
  actions,
  selectors,
} from '@sma/store/meteringProfile/meteringProfiles';
import { COLUMN_FEATURES } from '@sma/components/meteringProfile/table';

export const messages = defineMessages({
  title: {
    id: 'meteringProfiles.title',
    defaultMessage: 'Metering profiles',
  },
  summary: {
    id: 'meteringProfiles.summary',
    defaultMessage: '{count, number} {count, plural, one {metering profile} other {metering profiles}} displayed',
  },
  loading: {
    id: 'meteringProfiles.loading',
    defaultMessage: 'Loading metering profiles',
  },
  empty: {
    id: 'meteringProfiles.empty',
    defaultMessage: 'No metering profiles found',
  },
  noConfigured: {
    id: 'meteringProfiles.noConfigured',
    defaultMessage: 'No metering profiles has been discovered in system yet',
  },
  noMatches: {
    id: 'meteringProfiles.noMatches',
    defaultMessage: 'No metering profiles due to filters applied',
  },
  search: {
    id: 'meteringProfiles.search',
    defaultMessage: 'Search for metering profiles',
  },
});

const MeteringProfiles = ({
  loading,
  isSelected,
  meteringProfilesContext,
  clearSelection,
  select,
  isSorted,
  toggleSort,
  selectedMeteringProfile,
  intl,
  search,
  query,
}) => (
  <Main
    title={<FormattedMessage {...messages.title} />}
    tools={<QuickSearch
      placeholder={intl.formatMessage(messages.search)}
      value={query}
      onChange={search}
    />}
  >
    <Primary loading={loading}>
      <CollectionWrapper
        loading={loading}
        context={meteringProfilesContext}
        messages={{
          loading: <FormattedMessage {...messages.loading} />,
          empty: <FormattedMessage {...messages.empty} />,
          summary: count => (
            <FormattedMessage
              values={{ count }}
              {...messages.summary}
            />
          ),
          emptyReasons: [
            <FormattedMessage {...messages.noConfigured} />,
            <FormattedMessage {...messages.noMatches} />,
          ],
        }}
      >{meteringProfiles => (
        <MeteringProfilesTable
          meteringProfiles={meteringProfiles}
          isSelected={isSelected}
          isSorted={isSorted}
          toggleSort={toggleSort}
          isHidden={column => COLUMN_FEATURES === column && S.isJust(selectedMeteringProfile)}
          onSelectionChange={meteringProfile => (
            isSelected(meteringProfile)
              ? clearSelection()
              : select(meteringProfile)
          )}
        />
      )}
      </CollectionWrapper>
    </Primary>
    {S.maybeToNullable(S.map(meteringProfile => (
      <MeteringProfilesSecondary
        onClose={clearSelection}
        meteringProfile={meteringProfile}
      />
    ), selectedMeteringProfile))}
  </Main>
);

MeteringProfiles.propTypes = {
  loading: bool.isRequired,
  isSelected: func.isRequired,
  clearSelection: func.isRequired,
  select: func.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  search: func.isRequired,
  query: string,
  intl: intlShape.isRequired,
};

MeteringProfiles.defaultProps = {
  query: '',
};

export default R.compose(
  connect(
    state => ({
      loading: selectors.isLoading(state),
      meteringProfilesContext: selectors.getMeteringProfilesContext(state),
      isSelected: selectors.isSelected(state),
      isSorted: selectors.isSorted(state),
      selectedMeteringProfile: selectors.getSelected(state),
      query: selectors.getQuery(state),
    }),
    ({
      clearSelection: actions.clearSelected,
      select: actions.setSelected,
      toggleSort: actions.toggleSort,
      search: evt => actions.search(evt.target.value),
    }),
  ),
  injectIntl,
)(MeteringProfiles);
