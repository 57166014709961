import S from 'common/service/sanctuary';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { bool, func, shape, string } from 'prop-types';
import { Hbox, Primary, SlideSecondary } from 'common/mdc/layout';
import { MeteringProfilesTable } from '@sma/components/device/meteringProfile';
import MeteringProfilesSecondary from '@sma/components/device/meteringProfile/secondary';
import CollectionWrapper from 'common/mdc/wrappers/collection';

import {
  actions,
  selectors,
} from '@sma/store/device/meteringProfile';

export const messages = defineMessages({
  title: {
    id: 'device.meteringProfiles.title',
    defaultMessage: 'Metering profiles',
  },
  summary: {
    id: 'device.meteringProfiles.summary',
    defaultMessage: '{count, number} {count, plural, one {metering profile} other {metering profiles}} displayed',
  },
  loading: {
    id: 'device.meteringProfiles.loading',
    defaultMessage: 'Loading metering profiles',
  },
  empty: {
    id: 'device.meteringProfiles.empty',
    defaultMessage: 'No metering profiles found',
  },
  emptyReason: {
    id: 'device.meteringProfiles.emptyReason',
    defaultMessage: 'Meter {deviceId} has not reported metering profiles',
  },
});

const MeteringProfiles = ({
  loading,
  isSelected,
  meteringProfilesContext,
  clearSelection,
  select,
  isSorted,
  toggleSort,
  selectedMeteringProfile,
  params: { deviceId },
}) => (
  <Hbox>
    <SlideSecondary>
      <Primary loading={loading}>
        <CollectionWrapper
          loading={loading}
          context={meteringProfilesContext}
          messages={{
            loading: <FormattedMessage {...messages.loading} />,
            empty: <FormattedMessage {...messages.empty} />,
            summary: count => (
              <FormattedMessage
                values={{ count }}
                {...messages.summary}
              />
            ),
            emptyReasons: [
              <FormattedMessage
                values={{ deviceId }}
                {...messages.emptyReason}
              />,
            ],
          }}
        >{meteringProfiles => (
          <MeteringProfilesTable
            deviceId={deviceId}
            meteringProfiles={meteringProfiles}
            isSelected={isSelected}
            isSorted={isSorted}
            toggleSort={toggleSort}
            onSelectionChange={meteringProfile => (
              isSelected(meteringProfile)
                ? clearSelection()
                : select(meteringProfile)
            )}
          />
        )}
        </CollectionWrapper>
      </Primary>
      {S.maybeToNullable(S.map(meteringProfile => (
        <MeteringProfilesSecondary
          onClose={clearSelection}
          meteringProfile={meteringProfile}
        />
      ), selectedMeteringProfile))}
    </SlideSecondary>
  </Hbox>
);

MeteringProfiles.propTypes = {
  loading: bool.isRequired,
  isSelected: func.isRequired,
  clearSelection: func.isRequired,
  select: func.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  params: shape({
    deviceId: string.isRequired,
  }).isRequired,
};

export default R.compose(
  connect(
    state => ({
      loading: selectors.isLoading(state),
      meteringProfilesContext: selectors.getMeteringProfilesContext(state),
      isSelected: selectors.isSelected(state),
      isSorted: selectors.isSorted(state),
      selectedMeteringProfile: selectors.getSelected(state),
    }),
    ({
      clearSelection: actions.clearSelected,
      select: actions.setSelected,
      toggleSort: actions.toggleSort,
    }),
  ),
)(MeteringProfiles);
