import { combineEpics } from 'redux-observable';

import meteringProfilesEpic from './meteringProfile/epic';
import meteringProfileSamplesEpic from './meteringProfileSamples/epic';
import onDemandReadingEpic from './onDemandReading/epic';
import onDemandHistoryEpic from './onDemandHistory/epic';
import devices from './devices/epic';
import deviceEpic from './device/epic';
import relayConnectDisconnectEpic from './relayConnectDisconnect/epic';
import pingEpic from './ping/epic';
import meteringProfileDetails from './meteringProfileDetails/epic';
import search from './search/epic';
import alarms from './alarms/epic';
import events from './events/epic';

export default combineEpics(
  devices,
  meteringProfilesEpic,
  meteringProfileSamplesEpic,
  onDemandReadingEpic,
  onDemandHistoryEpic,
  deviceEpic,
  relayConnectDisconnectEpic,
  pingEpic,
  meteringProfileDetails,
  search,
  alarms,
  events,
);
