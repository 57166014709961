import { combineEpics } from 'redux-observable';

import groups from './groups/epic';
import group from './group/epic';
import create from './create/epic';
import devices from './devices/epic';
import remove from './actions/remove';

export default combineEpics(
  groups,
  group,
  devices,
  remove,
  create,
);
