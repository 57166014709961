import {
  resource,
} from 'common/store/actions';

export const NAMESPACE = 'GROUP/GROUP';

const resourceActions = resource.factory(NAMESPACE);
export const SAVE = `${NAMESPACE}/SAVE`;
export const SAVE_SUCCESS = `${NAMESPACE}/SAVE/SUCCESS`;
export const SAVE_ERROR = `${NAMESPACE}/SAVE/ERROR`;
export const EDIT_ERROR = `${NAMESPACE}/EDIT_ERROR`;
export const CLEAR_EDIT_ERROR = `${NAMESPACE}/CLEAR_EDIT_ERROR`;

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  CLEAR,
} = resourceActions.TYPES;

export const {
  loadSuccess,
  loadError,
  clear,
} = resourceActions;

export const load = groupId => ({
  ...resourceActions.load(),
  groupId,
});

export const save = group => ({
  type: SAVE,
  group,
});

export const saveSuccess = group => ({
  type: SAVE_SUCCESS,
  group,
});

export const saveError = err => ({
  type: SAVE_ERROR,
  error: err,
});

export const editError = err => ({
  type: EDIT_ERROR,
  editGroupError: err,
});

export const clearEditError = () => ({
  type: CLEAR_EDIT_ERROR,
});
