import collection from 'common/store/collection';

const getScope = ({ device: { onDemandHistory } }) => onDemandHistory;
const collectionFactory = collection.selectorsFactory(getScope);

const {
  isLoading,
  getCollectionMaybe,
  getCollectionContext,
  getStoreParams,
} = collectionFactory;

export {
  isLoading,
  getStoreParams,
  getCollectionMaybe as getSamplesMaybe,
  getCollectionContext as getSamplesContext,
};
