import {
  collection,
  selection,
  composeReducers,
} from 'common/store/reducer';

import {
  NAMESPACE,
  SET_SELECTED,
  CLEAR_SELECTED,
} from './actions';

const initial = {
  ...collection.defaultSort('created'),
};

const selectGroup = (state, { group }) => (group
  ? selection.setSelected(state, { selected: group })
  : selection.clearSelected(state)
);

const groupsReducer = (state = initial, action) => {
  const reducer = {
    [SET_SELECTED]: selectGroup,
    [CLEAR_SELECTED]: selection.clearSelected,
  }[action.type];

  return reducer ? reducer(state, action) : state;
};
export default composeReducers(
  collection.factory(NAMESPACE),
  groupsReducer,
);
