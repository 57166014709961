import { collection, selection } from 'common/store/actions';

export const NAMESPACE = 'GROUP/GROUPS';
export const SET_SELECTED = 'GROUP/GROUPS/SET_SELECT';
export const REMOVE_GROUP = 'GROUP/GROUPS/REMOVE_GROUP';

const collectionActions = collection.factory(NAMESPACE);
const selectionActions = selection.factory(NAMESPACE);

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  TOGGLE_SORT,
  PAGINATION_CHANGE,
  SEARCH,
} = collectionActions.TYPES;

export const {
  CLEAR_SELECTED,
} = selectionActions.TYPES;

export const {
  clearSelected,
} = selectionActions;

export const {
  loadSuccess,
  loadError,
  load,
  toggleSort,
  paginationChange,
  search,
} = collectionActions;

export const setSelected = group => ({
  type: SET_SELECTED,
  group,
});

export const remove = group => ({
  type: REMOVE_GROUP,
  group,
});
