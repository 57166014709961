import {
  resource,
  composeReducers,
} from 'common/store/reducer';

import {
  NAMESPACE,
  CLEAR,
  CLEAR_EDIT_ERROR,
  EDIT_ERROR,
  LOAD,
} from './actions';

const initial = {
  ...resource.defaultState,
  editGroupError: null,
};

const onEditError = (state, { editGroupError }) => ({
  ...state,
  editGroupError,
});

const clearEditError = state => ({
  ...state,
  editGroupError: null,
});

const groupReducer = (state = initial, action) => {
  const reducer = {
    [EDIT_ERROR]: onEditError,
    [CLEAR_EDIT_ERROR]: clearEditError,
    [LOAD]: clearEditError,
    [CLEAR]: clearEditError,
  }[action.type];

  return reducer ? reducer(state, action) : state;
};

export default composeReducers(
  resource.factory(NAMESPACE),
  groupReducer,
);
