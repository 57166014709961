import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { func } from 'prop-types';
import * as R from 'ramda';
import S from 'common/service/sanctuary';
import { bb as billboard } from 'billboard.js';
import * as d3 from "d3";

class FirmwareChart extends React.PureComponent {
  componentDidMount() {
    this.initChart();
  }

  initChart() {
    const {
      values,
      intl,
    } = this.props;

    const content = [];
    values.map(item => (content.push([item.label, item.value])));

    this.chart = billboard.generate({
      data: {
        columns: content,
        type: "pie",
      },
      pie: {
        padding: 1
      },
      legend: {
        show: true
      },
      tooltip: {
        show: true,
        format: {
          value: function(value, ratio, id) {
            return value + " meters (" + d3.format('0.1%')(ratio) + ")";
          }
        }
      },
      color: {
        pattern: [
            "#1f77b4",
            "#aec7e8",
            "#ff7f0e",
            "#ffbb78",
            "#2ca02c",
            "#98df8a",
            "#d62728",
            "#ff9896",
            "#9467bd",
            "#c5b0d5",
            "#8c564b",
            "#9edae5"
          ]
      },
      legend: {
        item: {
          onclick: function(id) {
            return false;
          }
        }
      },
      bindto: this.body,
    });
  }

  render() {
    return (
      <div className="node-content">
        <div
          className="legend"
          ref={(legend) => { this.legend = legend; }}
        />
        <div
          className="graphic"
          ref={(body) => { this.body = body; }}
        />
      </div>
    );
  }
}

FirmwareChart.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(FirmwareChart);
