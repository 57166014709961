import React from 'react';
import S from 'common/service/sanctuary';
import { FormattedMessage, defineMessages } from 'react-intl';
import CollectionWrapper from 'common/mdc/wrappers/collection';
import { shape, bool } from 'prop-types';
import { connect } from 'react-redux';

import {
  selectors as meteringProfileSelectors,
} from '@sma/store/device/meteringProfile';

import {
  selectors as onDemandReadingSelectors,
} from '@sma/store/device/onDemandReading';

import RegistersSelection from './selectionTable';

const messages = defineMessages({
  loading: {
    id: 'smas.devices.actions.on-demand-reading.loading',
    defaultMessage: 'Loading registers',
  },
  noRegisters: {
    id: 'smas.devices.actions.on-demand-reading.noRegisters',
    defaultMessage: 'No registers found',
  },
  noMeteringProfiles: {
    id: 'smas.devices.actions.on-demand-reading.noMeteringProfiles',
    defaultMessage: 'Meter does not have metering profiles',
  },
  noFeatureSupport: {
    id: 'smas.devices.actions.on-demand-reading.noFeatureSupport',
    defaultMessage: 'Meter\'s metering profiles do not support on demand reading',
  },
  summary: {
    id: 'smas.devices.actions.on-demand-reading.summary',
    defaultMessage: '{selected} of {count} {count, plural, one {register} other {registers}} selected',
  },
});

const ImmediateSamples = ({
  onDemandMeteringProfilesContext,
  profilesLoading,
  multiSelection,
  meteringProfileMaybe,
}) => (
  <CollectionWrapper
    loading={profilesLoading}
    context={onDemandMeteringProfilesContext}
    tools={multiSelection.getFilter()}
    messages={{
      loading: <FormattedMessage {...messages.loading} />,
      empty: <FormattedMessage {...messages.noRegisters} />,
      emptyReasons: [
        <FormattedMessage {...messages.noMeteringProfiles} />,
        <FormattedMessage {...messages.noFeatureSupport} />,
      ],
      summary: () => (
        <FormattedMessage
          values={{
            count: S.maybe(0, profile => profile.registers.length, meteringProfileMaybe),
            selected: multiSelection.getSelection().length,
          }}
          {...messages.summary}
        />
      ),
    }}
  >{S.maybeToNullable(S.map(
    meteringProfile => (
      <RegistersSelection
        registers={multiSelection.getItems(meteringProfile.registers).map(
          profile => ({
            ...profile,
            profileObisCode: meteringProfile.profileObisCode,
          }),
        )}
        checked={multiSelection.isAllSelected(meteringProfile.registers)}
        selected={multiSelection.selected}
        onSelect={multiSelection.handleItemSelect}
        onSelectionChange={multiSelection.handleSelectAll(meteringProfile.registers)}
      />
    ),
    meteringProfileMaybe,
  ))}
  </CollectionWrapper>
);

ImmediateSamples.propTypes = {
  onDemandMeteringProfilesContext: shape().isRequired,
  profilesLoading: bool.isRequired,
  multiSelection: shape().isRequired,
  meteringProfileMaybe: shape().isRequired,
};

export default connect(state => ({
  onDemandMeteringProfilesContext: onDemandReadingSelectors.getOnDemandProfilesContext(state),
  profilesLoading: meteringProfileSelectors.isLoading(state),
}))(ImmediateSamples);
