import React from 'react';
import { arrayOf, func } from 'prop-types';
import { LABEL_AFTER } from 'common/mdc/form/checkbox';
import Menu, { MenuItem, MenuWrapper } from 'common/mdc/menu';
import { CheckboxField } from 'common/mdc/form';
import Icon from 'common/mdc/icon';

import RegisterName from '../name';
import registerShape from '../shape';

const OnDemandHistoryMenu = ({ registers, isRegisterSelected, onSelectRegister }) => (
  <MenuWrapper
    floating
    className="on-demand-menu"
    wrappedElement={
      <Icon className="mdc-toolbar__icon">settings</Icon>
    }
  >
    <Menu>
      {registers.map(
        (register, index) => (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={index}
          >
            <CheckboxField
              label={<RegisterName register={register} />}
              id={`on-demand-register-${index}`}
              labelId={`on-demand-label-${index}`}
              labelPosition={LABEL_AFTER}
              checked={isRegisterSelected(register)}
              onChange={() => onSelectRegister(register)}
            />
          </MenuItem>
        ),
      )}
    </Menu>
  </MenuWrapper>
);

OnDemandHistoryMenu.propTypes = {
  registers: arrayOf(registerShape).isRequired,
  isRegisterSelected: func.isRequired,
  onSelectRegister: func.isRequired,
};

export default OnDemandHistoryMenu;
