import {
  collection,
  selection,
} from 'common/store/actions';

export const NAMESPACE = 'GROUP/DEVICES';

const collectionActions = collection.factory(NAMESPACE);
const selectionActions = selection.factory(NAMESPACE);

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  TOGGLE_SORT,
  PAGINATION_CHANGE,
  UPDATE,
  CLEAR,
  SEARCH,
} = collectionActions.TYPES;

export const {
  loadSuccess,
  loadError,
  toggleSort,
  paginationChange,
  update,
  clear,
  search,
} = collectionActions;

export const {
  SET_SELECTED,
  CLEAR_SELECTED,
} = selectionActions.TYPES;

export const setSelected = (device, reload = true) => ({
  ...selectionActions.setSelected(device),
  reload,
});

export const {
  clearSelected,
} = selectionActions;

export const load = (groupId, params) => ({
  ...collectionActions.load(params),
  groupId,
});
