import React from 'react';
import { reduce, keys, isEmpty } from 'ramda';
import {
  injectIntl,
  intlShape,
  FormattedTime,
  FormattedDate,
  FormattedMessage,
  defineMessages,
} from 'react-intl';
import DateTime from 'common/components/dateTime';


const messages = defineMessages({
  notSpecified: {
    id: 'sample.values.datetime.notSpecified',
    defaultMessage: 'Not specified',
  },
  errorParsing: {
    id: 'sample.values.datetime.errorParsing',
    defaultMessage: 'Error parsing',
  },
});
const DLMSparser = ({ date, time, deviation, intl }) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };

  const NUMBER = 'number';
  const NOT_SPECIFIED = 'not specified';
  const ERROR = 'error';

  const getDateTimeType = (dateTime, key) => {
    switch (key) {
      case 'year':
        if (dateTime[key] === 65535) {
          return NOT_SPECIFIED;
        } else if (dateTime[key] > 0) {
          return NUMBER;
        }
        return ERROR;
      case 'month':
        if (dateTime[key] === 255) {
          return NOT_SPECIFIED;
        } else if (dateTime[key] >= 1 && dateTime[key] <= 12) {
          return NUMBER;
        }
        return ERROR;
      case 'dayOfMonth':
        if (dateTime[key] === 255) {
          return NOT_SPECIFIED;
        } else if (dateTime[key] >= 1 && dateTime[key] <= 31) {
          return NUMBER;
        }
        return ERROR;
      case 'dayOfWeek':
        if (dateTime[key] === 255) {
          return NOT_SPECIFIED;
        } else if (dateTime[key] >= 1 && dateTime[key] <= 7) {
          return NUMBER;
        }
        return ERROR;
      case 'hours':
        if (dateTime[key] === 255) {
          return NOT_SPECIFIED;
        } else if (dateTime[key] >= 0 && dateTime[key] <= 23) {
          return NUMBER;
        }
        return ERROR;
      case 'minutes':
      case 'seconds':
        if (dateTime[key] === 255) {
          return NOT_SPECIFIED;
        } else if (dateTime[key] >= 0 && dateTime[key] <= 59) {
          return NUMBER;
        }
        return ERROR;
      case 'hundredths':
        if (dateTime[key] === 255) {
          return NOT_SPECIFIED;
        } else if (dateTime[key] >= 0 && dateTime[key] <= 99) {
          return NUMBER;
        }
        return ERROR;
      default:
        return dateTime[key];
    }
  };

  const dateTypes = reduce(
    (obj, key) => (getDateTimeType(date, key) === NUMBER ? { ...obj, [key]: date[key] } : obj),
    {},
    keys(date),
  );
  const timeTypes = reduce(
    (obj, key) => (getDateTimeType(time, key) === NUMBER ? { ...obj, [key]: time[key] } : obj),
    {},
    keys(time),
  );

  const { year, month, dayOfMonth } = dateTypes;
  const { hours, minutes, seconds, hundredths } = timeTypes;

  if (!isEmpty(dateTypes) && !isEmpty(timeTypes)) {
    const dateTime = hundredths ?
      new Date(
        Date.UTC(year, month - 1, dayOfMonth, hours, minutes, seconds, hundredths) - (deviation * 60000),
      ) :
      new Date(
        Date.UTC(year, month - 1, dayOfMonth, hours, minutes, seconds) - (deviation * 60000),
      );
    return <DateTime value={dateTime} title seconds />;
  } else if (!isEmpty(dateTypes)) {
    const dateTime = new Date(
      Date.UTC(year, month - 1, dayOfMonth) - (deviation * 60000),
    );
    return (
      <div title={`${intl.formatDate(dateTime)}`}>
        <FormattedDate value={dateTime} />
      </div>);
  } else if (!isEmpty(timeTypes)) {
    const dateTime = hundredths ?
      new Date(
        Date.UTC(70, 0, 1, hours, minutes, seconds, hundredths) - (deviation * 60000),
      ) :
      new Date(
        Date.UTC(70, 0, 1, hours, minutes, seconds) - (deviation * 60000),
      );
    return (
      <div title={`${intl.formatTime(dateTime, options)}`}>
        <FormattedTime value={dateTime} {...options} />
      </div>);
  }

  return <FormattedMessage {...messages.notSpecified} />;
};


DLMSparser.propTypes = {
  intl: intlShape.isRequired,
};


export default injectIntl(DLMSparser);
