import S from 'common/service/sanctuary';
import { createSelector } from 'reselect';

const getScope = S.props(['group', 'create']);

const isPending = createSelector(getScope, S.prop('pending'));

export {
  isPending,
};

export default {};
