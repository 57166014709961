import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, debounceTime, mergeMap } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';
import { of } from 'rxjs';

import {
  LOAD,
  TOGGLE_SORT,
  PAGINATION_CHANGE,
  loadSuccess,
  loadError,
  load,
  SEARCH,
  SET_SELECTED,
} from './actions';

import { getStoreParams } from './selectors';
import { actions } from '../group';
import { actions as devicesActions } from '../devices';

const loadAllEpic = (action, state, { groupService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => groupService
    .getAll(getStoreParams(state.value))
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

const update = (action, state) => action.pipe(
  ofType(TOGGLE_SORT, PAGINATION_CHANGE),
  map(() => load(getStoreParams(state.value))),
);

const search = (action, state) => action.pipe(
  ofType(SEARCH),
  debounceTime(150),
  map(() => load(getStoreParams(state.value))),
);

const loadSelected = action => action.pipe(
  ofType(SET_SELECTED),
  mergeMap(({ group }) => (group
    ? of(actions.load(group.id))
    : of(
      actions.clear(),
      devicesActions.clear(),
    ))),
);

export default combineEpics(
  loadAllEpic,
  update,
  search,
  loadSelected,
);
