import { CREATE, CREATE_SUCCESS, CREATE_ERROR } from './actions';

const initial = {
  pending: false,
  error: false,
};

const create = state => ({
  ...state,
  pending: true,
});

const createSuccess = state => ({
  ...state,
  pending: false,
});

const onError = (state, { error }) => ({
  ...state,
  error,
  pending: false,
});

export default (state = initial, action) => {
  const reducer = {
    [CREATE]: create,
    [CREATE_SUCCESS]: createSuccess,
    [CREATE_ERROR]: onError,
  }[action.type];

  return reducer ? reducer(state, action) : state;
};
