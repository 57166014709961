import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { func } from 'prop-types';
import { Actions as FarceActions } from 'farce';
import Dialog, { withConfirmationDialog } from 'common/mdc/dialog';
import Menu, { MenuItem } from 'common/mdc/menu';
import { actions } from '@sma/store/group/groups';
import groupShape from './groupShape';

const messages = defineMessages({
  removeGroup: {
    id: 'group.actions.removeGroup',
    defaultMessage: 'Remove group',
  },
  dialog: {
    id: 'group.actions.dialog',
    defaultMessage: 'Are you sure you want to remove {groupName}?',
  },
  editGroup: {
    id: 'groups.actions.edit-group',
    defaultMessage: 'Edit group',
  },
});

class GroupTableActions extends React.PureComponent {
  render() {
    const { push, group, remove, removeDialog, intl, ...props } = this.props;
    return (
      <Menu {...props}>
        <MenuItem
          key={messages.editGroup.id}
          onClick={() => push({
            pathname: `/groups/${group.id}/edit`,
          })}
        >
          <FormattedMessage {...messages.editGroup} />
        </MenuItem>
        <MenuItem
          key={messages.removeGroup.id}
          onClick={() => removeDialog({
            resolve: () => remove(group),
          })}
        >
          <FormattedMessage {...messages.removeGroup} />
        </MenuItem>
      </Menu>
    );
  }
}

const RemoveDialog = ({ intl, group, buttons, onClose }) => (
  <Dialog
    title={intl.formatMessage(messages.removeGroup)}
    buttons={buttons}
    onClose={onClose}
  >
    <FormattedMessage {...messages.dialog} values={{ groupName: group.name }} />
  </Dialog>
);

const propTypes = {
  intl: intlShape.isRequired,
  push: func.isRequired,
  group: groupShape.isRequired,
};

GroupTableActions.propTypes = {
  ...propTypes,
  remove: func.isRequired,
};

RemoveDialog.propTypes = propTypes;

export default compose(
  connect(
    null,
    ({
      remove: actions.remove,
      push: FarceActions.push,
    }),
  ),
  injectIntl,
  withConfirmationDialog(RemoveDialog, 'removeDialog'),
)(GroupTableActions);
