import React from 'react';
import { Link } from 'found';
import { FormattedMessage } from 'react-intl';

const GroupLink = ({ message, groupName, groupId, values }) => (
  <FormattedMessage
    {...message}
    values={{
      groupName: <b><Link to={`/groups/${groupId}`}>{groupName}</Link></b>,
      ...values,
    }}
  />
);

export default GroupLink;
