import {
  resource,
} from 'common/store/selectors';

const getScope = ({ device: { device } }) => device;

const {
  isLoading,
  getResourceContext,
  getResourceMaybe,
} = resource.factory(getScope);

export {
  isLoading,
  getResourceContext as getDeviceContext,
  getResourceMaybe as getDeviceMaybe,
};
