import {
  resource,
} from 'common/store/selectors';

const getScope = ({ device: { meteringProfileDetails } }) => meteringProfileDetails;

const {
  isLoading,
  getResourceContext,
  getResourceMaybe,
} = resource.factory(getScope);

export {
  isLoading,
  getResourceContext as getMeteringProfileContext,
  getResourceMaybe as getMeteringProfileMaybe,
};
