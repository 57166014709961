import {
  collection,
} from 'common/store/actions';

export const NAMESPACE = 'DEVICE/REGISTERS';

const collectionActions = collection.factory(NAMESPACE);

export const {
  LOAD,
  LOAD_SUCCESS,
  LOAD_ERROR,
  CLEAR,
} = collectionActions.TYPES;

export const {
  loadSuccess,
  loadError,
  clear,
} = collectionActions;

export const SELECT_PROFILE = 'DEVICE/ON_DEMAND_SAMPLING/SELECT_PROFILE';
export const ON_DEMAND_SAMPLING_CLOSE = 'DEVICE/ON_DEMAND_SAMPLING/CLOSE';

export const load = ({ deviceId, registers }) => ({
  ...collectionActions.load(),
  deviceId,
  registers,
});

export const selectMeteringProfile = meteringProfileId => ({
  type: SELECT_PROFILE,
  meteringProfileId,
});

export const onDemandSamplingClose = () => ({
  type: ON_DEMAND_SAMPLING_CLOSE,
});
