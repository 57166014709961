import Device from './device';
import MeteringProfiles from './meteringProfiles';
import Dashboard from './deviceDashboard';
import Samples from './samples';
import Alarms from './alarms';
import Events from './events';

export {
  Dashboard,
  MeteringProfiles,
  Samples,
  Alarms,
  Events,
};

export default Device;
