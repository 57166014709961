import React from 'react';
import Form, { DisplayField } from 'common/mdc/form';
import DateTime from 'common/components/dateTime';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import GroupType from './groupType';
import groupShape from './groupShape';

const messages = defineMessages({
  createdAt: {
    id: 'group.field.createdAt',
    defaultMessage: 'Created at',
  },
  type: {
    id: 'group.field.type',
    defaultMessage: 'Group type',
  },
});

const GroupDetails = ({ group, intl }) => (
  <div className="group-details-form">
    <Form>
      <DisplayField label={intl.formatMessage(messages.createdAt)}>
        <DateTime value={group.created} />
      </DisplayField>
      <DisplayField label={intl.formatMessage(messages.type)}>
        <GroupType type={group.type} />
      </DisplayField>
    </Form>
  </div>
);

GroupDetails.propTypes = {
  group: groupShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(GroupDetails);
