import React from 'react';
import S from 'common/service/sanctuary';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
// import { Link } from 'found';
import Form, { DisplayField } from 'common/mdc/form';
import SamplingPeriod from '@sma/components/meteringProfile/capturePeriod';
import meteringProfileShape from './shape';
import Features from './features';

const messages = defineMessages({
  name: {
    id: 'meteringProfile.field.name',
    defaultMessage: 'Name',
  },
  obisCode: {
    id: 'meteringProfile.field.obisCode',
    defaultMessage: 'OBIS code',
  },
  capturePeriod: {
    id: 'meteringProfile.field.capturePeriod',
    defaultMessage: 'Capture period',
  },
  devices: {
    id: 'meteringProfile.field.devices',
    defaultMessage: 'Meters',
  },
  registers: {
    id: 'meteringProfile.field.registers',
    defaultMessage: 'Registers',
  },
  features: {
    id: 'meteringProfile.field.features',
    defaultMessage: 'Features',
  },
});

const DetailsForm = ({ meteringProfile, intl }) => (
  <Form style={{ padding: 0 }}>
    <DisplayField label={intl.formatMessage(messages.name)}>
      {/* <Link to={'/devices/'}> */}
      {meteringProfile.name}
      {/* </Link> */}
    </DisplayField>
    <DisplayField label={intl.formatMessage(messages.obisCode)}>
      {meteringProfile.profileObisCode}
    </DisplayField>
    {S.maybeToNullable(S.map(
      capturePeriod => (
        <DisplayField label={intl.formatMessage(messages.capturePeriod)}>
          <SamplingPeriod period={capturePeriod} />
        </DisplayField>
      ),
      S.toMaybe(meteringProfile.capturePeriod),
    ))}
    {S.maybeToNullable(S.map(
      registers => (
        <DisplayField label={intl.formatMessage(messages.registers)}>
          {`${registers.length}`}
        </DisplayField>
      ),
      S.toMaybe(meteringProfile.registers),
    ))}
    {S.maybeToNullable(S.map(
      devices => (
        <DisplayField label={intl.formatMessage(messages.devices)}>
          {devices}
        </DisplayField>
      ),
      S.toMaybe(meteringProfile.devices),
    ))}
    <DisplayField label={intl.formatMessage(messages.features)} style={{ alignItems: 'start' }}>
      <Features features={meteringProfile.features} />
    </DisplayField>
  </Form>
);

DetailsForm.propTypes = {
  meteringProfile: meteringProfileShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(DetailsForm);
