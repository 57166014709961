import S from 'common/service/sanctuary';
import * as R from 'ramda';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap, debounceTime } from 'rxjs/operators';
import { createErrorStream } from 'common/store/error';
import { DEVICE_UPDATED } from '@sma/store/device/device/actions';
import { empty, of } from 'rxjs';
import { actions as deviceActions } from '@sma/store/device/device';

import {
  LOAD, load, loadSuccess, loadError,
  TOGGLE_SORT, PAGINATION_CHANGE, update,
  SEARCH, SET_SELECTED,
} from './actions';

import {
  selectors,
} from '../group';

import {
  getDevicesMaybe,
  getStoreParams,
} from './selectors';

const loadDevices = (action, state, { groupService }) => action.pipe(
  ofType(LOAD),
  switchMap(action$ => groupService
    .getDevices(
      action$.groupId,
      getStoreParams(state.value),
    )
    .pipe(
      map(loadSuccess),
      catchError(createErrorStream(action$, loadError)),
    )),
);

const updateEpic = (action, state) => action.pipe(
  ofType(TOGGLE_SORT, PAGINATION_CHANGE),
  map(() => load(S.maybeToNullable(selectors.getGroupId(state.value)))),
);

const search = (action, state) => action.pipe(
  ofType(SEARCH),
  debounceTime(150),
  map(() => load(
    S.maybeToNullable(selectors.getGroupId(state.value)),
  )),
);

const updateDevice = (action, state) => action.pipe(
  ofType(DEVICE_UPDATED),
  mergeMap(({ device }) => {
    const devices = S.fromMaybe([], getDevicesMaybe(state.value));

    return S.maybe(
      empty(),
      () => of(update(R.update(
        R.findIndex(R.propEq('deviceId', device.deviceId), devices),
        device,
        devices,
      ))),
      S.find(
        R.propEq('deviceId', device.deviceId),
        devices,
      ),
    );
  }),
);

const select = action => action.pipe(
  ofType(SET_SELECTED),
  map(({ selected, reload }) => (
    reload
      ? deviceActions.load(selected.deviceId)
      : deviceActions.loadSuccess(selected)
  )),
);

export default combineEpics(
  loadDevices,
  updateEpic,
  updateDevice,
  search,
  select,
);
