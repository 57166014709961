import React from 'react';
import { compose } from 'ramda';
import { connect } from 'react-redux';
import { withRouter, routerShape } from 'found';
import { defineMessages, FormattedMessage } from 'react-intl';

import Menu, { MenuItem } from 'common/mdc/menu';
import {
  selectors,
} from '@sma/store/device/search';

const messages = defineMessages({
  createGroup: {
    id: 'table.actions.createGroup',
    defaultMessage: 'Create a group',
  },
  configurationUpdate: {
    id: 'table.actions.configurationUpdate',
    defaultMessage: 'Configuration update',
  },
  exportCsvFile: {
    id: 'table.actions.exportCsvFile',
    defaultMessage: 'Export to csv',
  },
});

const DeviceJobMenu = ({ router, criteria, ...props }) => (
  <Menu {...props}>
    <MenuItem
        key={messages.exportCsvFile.id}
        onClick={() => fetch('/ui/devices/download?contentType=text%2fcsv&fileName=devices%2Ecsv')
                  .then((response) => {
                      const filename = response.headers.get('Content-Disposition').split('filename=')[1];
                      response.blob().then(blob => {
                          const downloadLink = document.createElement('a');
                          downloadLink.href = URL.createObjectURL(blob);
                          downloadLink.download = filename;
                          document.body.appendChild(downloadLink);
                          downloadLink.click();
                          document.body.removeChild(downloadLink);
                      });
        })}
    >
      <FormattedMessage {...messages.exportCsvFile} />
    </MenuItem>
  </Menu>
);

DeviceJobMenu.propTypes = {
  router: routerShape.isRequired,
};

export default compose(
  connect(
    state => ({
      criteria: selectors.getCriteria(state),
    }),
  ),
  withRouter,
)(DeviceJobMenu);
