import S from 'common/service/sanctuary';
import React from 'react';
import { connect } from 'react-redux';
import { bool, func, string, number, shape } from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Primary } from 'common/mdc/layout/index';
import Pagination from 'common/mdc/table/pagination';
import CollectionWrapper from 'common/mdc/wrappers/collection/index';
import { actions, selectors } from '@sma/store/device/events';
import EventsToolbar from './toolbar';
import EventTable from './table';

const messages = defineMessages({
  title: {
    id: 'title.events',
    defaultMessage: 'Events',
  },
  eventsPerPage: {
    id: 'table.pagination.events',
    defaultMessage: 'Events per page',
  },
  eventsDescription: {
    id: 'events.description',
    defaultMessage: '{count, number} {count, plural, one {event} other {events}} of {total} displayed',
  },
  noEvents: {
    id: 'event.noEvents',
    defaultMessage: 'No events found',
  },
  eventsLoading: {
    id: 'event.eventsLoading',
    defaultMessage: 'Events loading',
  },
  noEventsConfigured: {
    id: 'event.noEventsConfigured',
    defaultMessage: 'No events has been discovered yet',
  },
  noEventsMatches: {
    id: 'event.noEventsMatches',
    defaultMessage: 'Search result is too narrow, no events matches specified query',
  },
});

const Events = ({
  eventsContext,
  loading,
  total,
  paginator,
  onPaginationChange,
  isSorted,
  toggleSort,
  query,
}) => (
  <Primary loading={loading}>
    <CollectionWrapper
      loading={loading}
      context={eventsContext}
      tools={<EventsToolbar />}
      pagination={S.maybeToNullable(S.map(props => (
        <Pagination
          {...props}
          onChange={onPaginationChange}
          itemsPerPage={messages.eventsPerPage}
        />
      ), paginator))}
      messages={{
        empty: <FormattedMessage {...messages.noEvents} />,
        loading: <FormattedMessage {...messages.eventsLoading} />,
        summary: count => (
          <FormattedMessage
            values={{ count, total }}
            {...messages.eventsDescription}
          />
        ),
        emptyReasons: [
          <FormattedMessage {...messages.noEventsConfigured} />,
          query && <FormattedMessage {...messages.noEventsMatches} />,
        ],
      }}
    >{events => (
      <EventTable
        events={events}
        isSorted={isSorted}
        toggleSort={toggleSort}
      />
    )}
    </CollectionWrapper>
  </Primary>
);

Events.propTypes = {
  loading: bool.isRequired,
  query: string,
  eventsContext: shape().isRequired,
  total: number.isRequired,
  paginator: shape().isRequired,
  onPaginationChange: func.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
};

Events.defaultProps = {
  query: '',
};

export default connect(
  state => ({
    loading: selectors.isLoading(state),
    eventsContext: selectors.getEventsContext(state),
    total: S.fromMaybe(0, selectors.getTotal(state)),
    isSorted: selectors.isSorted(state),
    paginator: selectors.getPaginator(state),
    query: selectors.getQuery(state),
  }),
  ({
    load: actions.load,
    onPaginationChange: actions.paginationChange,
    toggleSort: actions.toggleSort,
  }),
)(Events);
